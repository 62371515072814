import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { HeroImage } from 'atoms';
import { DocumentTeaser, SecondaryTeaser } from 'organisms';
import { RoadmapArticle, Aside, Main } from 'templates';
import { Header, Heading } from 'Typography';
import { PAD_S } from 'breakpoints';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { images } from '../ClassesOverview/assets';

export const Background = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 9rem 1.34rem 1.134rem 1.34rem;
  @media (min-width: ${PAD_S}) {
    padding: 9rem 1.34rem 1.134rem 6rem;
    padding-top: 15.534rem;
  }
`;

export const AsideAds = styled.div`
  grid-area: 'ads';
  display: grid;
  grid-gap: 1rem;
  grid-auto-rows: min-content;
`;

export const RoadmapHeroImage = () => (
  <HeroImage image={images.backgroundMedium} imageBig={images.backgroundBig} height="26.267rem" />
);

export const CareerRoadmap = ({ isUserSignedIn }) => {
  const { t } = useTranslation();
  return (
    <Background>
      <Helmet>
        <title>Berufsorientierung in der Schule - Berufswahl-Fahrplan von Yousty</title>
        <meta
          name="description"
          content="Als Lehrer alle Phasen der Berufswahl kennenlernen. Mit nützlichen Vorlagen, Checklisten, Unterrichtsmaterialien, Arbeitsblättern und Videos."
        />
        <meta
          name="keywords"
          content="Berufswahl, Vorlagen, Videos, Arbeitsblätter, virtuelles Klassenzimmer, Phasen, Unterrichtsmaterial, Musterdokumente"
        />
      </Helmet>
      <RoadmapHeroImage />
      <Main>
        <Header withHeroImage>
          <Heading type="h1">{t('roadmap_header')}</Heading>
          <Heading type="h4">{t('roadmap_subtitle')}</Heading>
        </Header>
        <RoadmapArticle />
        <Aside>
          <DocumentTeaser area="content" newTab />
          <AsideAds>
            <SecondaryTeaser
              textContent={{
                header: t('roadmap_ad_teaser_1_header'),
                text: t('roadmap_ad_teaser_1_text'),
              }}
              link={{
                to: 'https://www.yousty.ch/broschuere-bestellen',
                text: t('roadmap_ad_teaser_1_button'),
              }}
              gaEvent="Clicks - Teaser - Broschüre"
              newTab
            />
            {!isUserSignedIn && (
              <SecondaryTeaser
                textContent={{
                  header: t('roadmap_ad_teaser_2_header'),
                  text: t('roadmap_ad_teaser_2_text'),
                }}
                link={{
                  to: 'https://berufswahlradar.yousty.ch/auth/login',
                  text: t('roadmap_ad_teaser_2_button'),
                }}
                gaEvent="Clicks - Teaser - Klasse"
              />
            )}
          </AsideAds>
        </Aside>
      </Main>
    </Background>
  );
};

const mapStateToProps = state => ({
  isUserSignedIn: state.userState.signedIn,
});

export default connect(mapStateToProps)(CareerRoadmap);
