import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleNotFound } from 'actions';
import { ErrorPage } from 'pages';

const NoMatch = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleNotFound());

    return () => {
      dispatch(toggleNotFound());
    };
  }, []);

  return <ErrorPage />;
};

export default NoMatch;
