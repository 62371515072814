/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HamburgerIcon, Link } from 'atoms';
import { toggleNavbar } from 'actions';
import { Paragraph } from 'Typography';
import { useTranslation } from 'react-i18next';
import * as bp from 'breakpoints';
import { getNavState } from 'selectors';
import youstyLogo from '../../../assets/images/youstyLogo.svg';

const Hamburger = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navState = useSelector(getNavState);
  const { pathname } = useLocation();
  const isEntry = pathname === '/';

  return (
    <div
      css={css`
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        height: 2.67rem;
        width: 4.67rem;
        display: flex;
        align-items: center;
        padding: 1.134rem 1.67rem;
        z-index: 4;

        @media (min-width: ${bp.PAD_S}) {
          position: absolute;
          top: 0;
          left: 0;
        }
      `}
    >
      <div
        css={`
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-tap-highlight-color: transparent;
          display: flex;
          justify-content: flex-start;
          cursor: pointer;
          height: 2.67rem;
          filter: drop-shadow(-0.5px 1px 2px rgba(0, 0, 0, 0.35));
        `}
        onClick={() => !isEntry && dispatch(toggleNavbar())}
      >
        {isEntry ? (
          <Link href="https://yousty.ch" target="_blank">
            <img
              src={youstyLogo}
              alt="logo for the future"
              css={`
                height: 2.67rem;
                width: 2.67rem;
              `}
            />
          </Link>
        ) : (
          <>
            <HamburgerIcon navVisible={navState} />
            <Paragraph.Menu hide={navState}>{t('menu')}</Paragraph.Menu>
          </>
        )}
      </div>
    </div>
  );
};

export default Hamburger;
