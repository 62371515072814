/* eslint-disable camelcase */
import * as React from 'react';
import * as api from 'api';

const useRegisterUser = () => {
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  React.useEffect(() => {
    const authorizeUser = async () => {
      setError('');
      setIsError(false);
      setIsSuccess(false);
      setIsLoading(true);
      try {
        const { email, first_name, last_name, gender, password, accept_agb } = user;
        const requestBody = {
          data: {
            attributes: {
              email,
              password,
              first_name,
              last_name,
              gender,
              passwordConfirmation: password,
              accept_agb,
              source: 'Teacher Sign-up CCR',
            },
          },
        };

        await api.userApi.post('/teachers/', requestBody);
        const tokenResponse = await api.postUser({ email: user.email, password: user.password });
        const { token } = tokenResponse.data.data;
        api.saveToken(token);
        setIsLoading(false);
        setIsSuccess(true);
      } catch (err) {
        setIsError(true);
        if (err.message === 'Request failed with status code 422') {
          setError(
            'Das Passwort muss mind. 8 Buchstaben, einen Grossbuchstaben, eine Zahl und ein Sonderzeichen (z.B. - , . !) beinhalten.',
          );
        } else {
          setError(err.message);
        }
        setIsLoading(false);
      }
    };
    if (user) authorizeUser();
  }, [user]);
  return [{ isLoading, isSuccess, isError, error }, setUser];
};

export default useRegisterUser;
