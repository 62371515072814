import React from 'react';
import styled from 'styled-components';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT } from 'breakpoints';
import Button from './Button';

const Container = styled(Button.Open)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  max-width: initial;
  max-height: initial;
  min-width: initial;
  border-radius: 50%;
  margin: 0;
  padding: 0 0.9rem;

  @media (min-width: ${DEFAULT}) {
    margin: 0;
  }
`;

const RoundButton = ({ ...args }) => (
  <Container {...args}>
    <FAIcon icon={faArrowRight} />
  </Container>
);

export default RoundButton;
