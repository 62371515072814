/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Input, Button, ToggleButton, Spinner } from 'atoms';
import { Form, InputField } from 'molecules';
import * as bp from 'breakpoints';
import uuid4 from 'uuid';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownHeader, DropdownOption, DropdownOptions } from 'organisms';
import { Link, withRouter } from 'react-router-dom';

import { schoolTypes, districts, capitalizeSentence } from './classFormUtils';
import { objectKeysIncludeString } from '../../../utils';
import { years } from '../../../utils/class';

const Wrapper = styled(Form)`
  @media (min-width: ${bp.DESKTOP}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.13rem;
    text-transform: capitalize;

    ${props =>
      props.expand &&
      css`
        grid-template-columns: auto;
      `};
  }
`;

const StatefulButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 9.34rem;
  height: 2.67rem;

  svg {
    color: var(--color-green);
  }
`;

const DynamicWrapper = ({ children, expand }) => {
  if (expand) {
    return (
      <div css="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1.13rem;">{children}</div>
    );
  }

  return <>{children}</>;
};

const ButtonsWrapper = ({ children, expand }) => {
  if (expand) {
    return (
      <div css="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10.7rem; align-items: center;">
        {children}
      </div>
    );
  }

  return <>{children}</>;
};

const StatefulButton = ({ sending, expand, children, success }) => {
  if (sending && expand) {
    return (
      <StatefulButtonWrapper>
        <Spinner />
      </StatefulButtonWrapper>
    );
  }

  if (success && expand) {
    return (
      <StatefulButtonWrapper>
        <FAIcon icon={faCheck} />
      </StatefulButtonWrapper>
    );
  }

  return <>{children}</>;
};

const ClassForm = ({ error, onClassSubmit, expand, classId, history, state = {}, ...args }) => {
  const { t } = useTranslation();
  const [className, changeClassName] = useState('');
  const [district, changeDistrict] = useState('');
  const [districtErr, districtValidation] = useState({ type: 'none' });
  const [schoolType, changeSchoolType] = useState('');
  const [schoolErr, schoolValidation] = useState({ type: 'none' });
  const [endYear, changeEndYear] = useState(null);
  const [endYearErr, endYearSelected] = useState({ type: 'none' });
  const [filteredSchools, setSchools] = useState([]);

  const { sending } = state;

  const submitReady =
    className &&
    district.length &&
    districtErr.type === 'none' &&
    schoolType.length &&
    endYear &&
    schoolErr.type === 'none' &&
    endYearErr.type === 'none';

  useEffect(() => endYearSelected({ type: 'none' }), [endYear]);

  useEffect(() => {
    setTimeout(() => {
      district.length === 0 || objectKeysIncludeString(districts, district)
        ? districtValidation({ type: 'none' })
        : districtValidation({
            type: 'warning',
            errors: [{ detail: 'err_not_a_district' }],
          });
    }, 300);

    const filteredSchoolTypes = Object.entries(schoolTypes)
      .filter(entry => entry[1].filter(statename => statename.includes(district)).length > 0)
      .sort();

    setSchools(filteredSchoolTypes);
    if (district.length > 0) {
      changeSchoolType(capitalizeSentence(filteredSchoolTypes[0][0]));
    }
  }, [district]);

  useEffect(() => {
    setTimeout(() => {
      schoolType.length === 0 || objectKeysIncludeString(schoolTypes, schoolType)
        ? schoolValidation({ type: 'none' })
        : schoolValidation({
            type: 'warning',
            errors: [{ detail: 'err_not_a_schooltype' }],
          });
    }, 300);
  }, [schoolType]);

  useEffect(() => {
    if (classId !== null && expand) {
      setTimeout(() => {
        history.push(`/${t('class')}/${classId}`);
        changeClassName('');
        changeDistrict('');
        changeEndYear(null);
        changeSchoolType('');
      }, 2000);
    }
  }, [classId]);

  return (
    <Wrapper
      {...args}
      grid
      expand={expand}
      gridGap="1rem"
      onSubmit={e => {
        e.preventDefault();
        if (!endYear) {
          endYearSelected({
            type: 'error',
            errors: [{ detail: 'err_select_an_endyear' }],
          });
        }
        if (submitReady) {
          const form = {
            name: className,
            state: districts[district],
            schoolLevel: schoolType,
            graduationYear: endYear,
          };

          onClassSubmit(form);

          if (!expand) {
            changeClassName('');
            changeDistrict('');
            changeEndYear(null);
            changeSchoolType('');
          }
        }
      }}
    >
      <DynamicWrapper expand={expand}>
        <InputField
          gridColumn="span 1"
          fullWidth
          required
          label={t('edit_class_label_1')}
          id="class-name"
          error={error}
          css={`
            ${expand && 'font-size: 1rem'};
            &&& {
              text-transform: none;
            }
          `}
        >
          <Input
            {...args}
            expand={expand}
            border={expand}
            css={`
              ${props => props.gray && 'background: var(--color-background)'};
              ${props => props.expand && 'font-size: 1.35rem; font-weight: bold;'};
            `}
            value={className}
            type="text"
            name="class-name"
            placeholder="2aE"
            required
            autoComplete="off"
            onChange={e => changeClassName(e.target.value)}
          />
        </InputField>
        <InputField
          expand={expand}
          gridColumn="span 1"
          fullWidth
          required
          label={t('edit_class_label_2')}
          id="district"
          type="datalist"
          error={error.type !== 'none' ? error : districtErr}
        >
          <Dropdown>
            <DropdownHeader
              css={`
                width: 100%;
                svg {
                  position: absolute;
                  right: 0;
                }
              `}
            >
              <Input
                {...args}
                expand={expand}
                border={expand}
                value={t(district)}
                placeholder={Object.keys(districts)[0]}
                name="district"
                onChange={() => null}
                autoComplete="off"
                required
                error={error.type !== 'none' ? error : districtErr}
                css={`
                  width: 100%;
                  text-transform: capitalize;
                  ${props => props.gray && 'background: var(--color-background)'};
                  ${props => props.expand && 'font-size: 1.35rem; font-weight: bold;'};
                  color: transparent;
                  text-shadow: 0 0 0 black;
                `}
              />
            </DropdownHeader>
            <DropdownOptions
              css={`
                margin-top: 1.86rem;
                max-height: 10rem;
                overflow: scroll;
                -webkit-overflow-scrolling: touch;
                text-transform: capitalize;
              `}
            >
              {Object.keys(districts).map(option => (
                <DropdownOption key={uuid4()} onOptionClick={() => changeDistrict(option)}>
                  {capitalizeSentence(option)}
                </DropdownOption>
              ))}
            </DropdownOptions>
          </Dropdown>
        </InputField>
      </DynamicWrapper>
      <InputField
        expand={expand}
        gridColumn="span 1"
        fullWidth
        label={t('edit_class_label_3')}
        required
        placeholder="2aE"
        id="schoolType"
        error={error.type !== 'none' ? error : schoolErr}
      >
        <Dropdown>
          <DropdownHeader
            css={`
              width: 100%;
              svg {
                position: absolute;
                right: 0;
              }
            `}
          >
            <Input
              {...args}
              expand={expand}
              border={expand}
              value={capitalizeSentence(schoolType)}
              placeholder={Object.keys(schoolTypes)[0]}
              name="school-type"
              onChange={() => null}
              autoComplete="off"
              error={error.type !== 'none' ? error : schoolErr}
              css={`
                width: 100%;
                text-transform: capitalize;
                ${props => props.gray && 'background: var(--color-background)'};
                ${props => props.expand && 'font-size: 1.35rem; font-weight: bold;'};
                color: transparent;
                text-shadow: 0 0 0 black;
              `}
            />
          </DropdownHeader>
          <DropdownOptions
            css={`
              margin-top: 1.86rem;
              max-height: 10rem;
              overflow: scroll;
              -webkit-overflow-scrolling: touch;
              text-transform: capitalize;
            `}
          >
            {filteredSchools.map(option => (
              <DropdownOption
                key={uuid4()}
                onOptionClick={() => changeSchoolType(capitalizeSentence(option[0]))}
              >
                {capitalizeSentence(option[0])}
              </DropdownOption>
            ))}
          </DropdownOptions>
        </Dropdown>
      </InputField>
      <InputField
        expand={expand}
        gridColumn="1 / -1"
        css="text-transform: none"
        fullWidth
        label={t('edit_class_label_4')}
        required
        id="finishYear"
        error={endYearErr}
        onChange={selectedEndYear => changeEndYear(selectedEndYear)}
      >
        <div
          expand={expand}
          css={`
            margin: 0.67rem 0;
            display: grid;
            grid-gap: 0.67rem;
            grid-template-columns: repeat(auto-fit, minmax(4rem, min-content));
            ${props =>
              props.expand &&
              css`
                grid-template-columns: repeat(4, 1fr);
              `};
          `}
        >
          {years.map(option => (
            <ToggleButton
              expand={expand}
              key={uuid4()}
              type="button"
              active={option.toString() === (endYear && endYear.toString())}
              color="primary"
              onClick={e => changeEndYear(e.target.innerText)}
              css={`
                  color: grey;
                  height: fit-content;
                  min-width: 0;
                  line-height: 1.67rem;
                  ${props => props.active && 'color: var(--font-color-secondary);'}
                  }}
                  ${props => props.expand && 'padding: 0.6rem 1rem;'};
                `}
            >
              {option}
            </ToggleButton>
          ))}
        </div>
      </InputField>
      <ButtonsWrapper expand={expand}>
        {expand && (
          <Link
            to={`/${t('classes')}`}
            css="font-size: 0.7rem; color: var(--font-color-primary); text-transform: none;"
          >
            {t('fill_later')}
          </Link>
        )}
        <StatefulButton expand={expand} sending={sending} success={classId !== null}>
          <Button
            elevation="1"
            primary={submitReady}
            css={
              !submitReady &&
              `
          cursor: default;
          background-color: var(--color-tertiary);
          border-color: var(--color-tertiary);
          color: rgba(255,255,255,0.8);
          box-shadow: var(--elevation-none);
        `
            }
            as="input"
            type="submit"
            value={expand ? 'Anmelden' : 'Klasse erstellen'}
          />
        </StatefulButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default withRouter(ClassForm);
