import styled from 'styled-components';

import { Paper } from 'atoms';

const Card = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1.6rem;
`;

export default Card;
