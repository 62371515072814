import React from 'react';
import styled, { css } from 'styled-components';
import tracker from 'tracker';
import { Card, CardContent, CardHeader, CardText, LinkButton } from 'molecules';
import { DESKTOP } from 'breakpoints';
import { RoundButton } from 'atoms';

const Buttons = styled.div`
  & > * {
    display: flex;
    align-items: center;

    &:first-child {
      display: none;
    }
  }

  @media (min-width: ${DESKTOP}) {
    & > * {
      display: none;

      &:first-child {
        display: flex;
      }
    }
  }
`;

const dynamic = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  @media (min-width: ${DESKTOP}) {
    flex-wrap: wrap;
  }
`;

const DynamicTeaser = ({ area, textContent, link, hideTextMobile, gaEvent, ...args }) => (
  <Card secondary area={area} {...args} css={dynamic}>
    <CardContent>
      <CardHeader color="secondary">{textContent.header}</CardHeader>
      <CardText hideTextMobile={hideTextMobile}>{textContent.text}</CardText>
    </CardContent>
    <Buttons>
      <LinkButton
        as="a"
        secondary
        mobileFullWidth
        marginTop="1rem"
        flexAlign="flex-end"
        justifyContent="center"
        onClick={() => tracker.trackEvent(gaEvent)}
        href={link.to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link.text}
      </LinkButton>
      <a
        href={link.to}
        onClick={() => tracker.trackEvent(gaEvent)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <RoundButton secondary area="button" />
      </a>
    </Buttons>
  </Card>
);

export default DynamicTeaser;
