export const userActions = {
  fetchRequest: '__USER_FETCH_REQUEST',
  fetchPending: '__USER_FETCH_PENDING',
  fetchSuccess: '__USER_FETCH_SUCCESS',
  logout: '__USER_LOGOUT',
};

export const roadmapActions = {
  setActiveField: '__ROADMAP_SET_ACTIVE_FIELD',
  fetchContentRequest: '__ROADMAP_FETCH_CONTENT_REQUEST',
  fetchContentPending: '__ROADMAP_FETCH_CONTENT_PENDING',
  fetchContentSuccess: '__ROADMAP_FETCH_CONTENT_SUCCESS',
};

export const navbarActions = {
  toggle: '__NAVBAR_TOGGLE',
  toggleNotFound: '__NAVBAR_TOGGLE_NOT_FOUND',
};

export const login = {
  request: '__LOGIN_REQUEST',
  pending: '__LOGIN_PENDING',
  receivedToken: '__LOGIN_TOKEN_RECEIVED',
  success: '__LOGIN_SUCCESS',
  failure: '__LOGIN_FAILURE',
  reset: '__LOGIN_RESET',
  setLoginData: '__LOGIN_DATA',
};

export const loginForm = {
  changeEmail: '__LOGIN_EMAIL_CHANGE',
  changePassword: '__LOGIN_PASSWORD_CHANGE',
  resetEmail: '__LOGIN_EMAIL_RESET',
  resetPassword: '__LOGIN__PASSWORD_RESET',
};

export const registerForm = {
  changeEmail: '__REGISTER_EMAIL_CHANGE',
  changePassword: '__REGISTER_PASSWORD_CHANGE',
  changeFirstName: '__REGISTER_FIRST_NAME_CHANGE',
  changeLastName: '__REGISTER_LAST_NAME_CHANGE',
  changeGender: '__REGISTER_GENDER_CHAN GE',
  changeTerms: '__REGISTER_TERMS_CHANGE',
  resetFirstName: '__REGISTER_FIRST_NAME_RESET',
  resetEmail: '__REGISTER_EMAIL_RESET',
  resetPassword: '__REGISTER__PASSWORD_RESET',
};

export const classesActions = {
  fetchRequest: '__CLASSES_FETCH_REQUEST',
  fetchPending: '__CLASSES_FETCH_PENDING',
  fetchSuccess: '__CLASSES_FETCH_SUCCESS',
  fetchError: '__CLASSES_FETCH_ERROR',
  addClassRequest: '__CLASSES_ADD_REQUEST',
  addClassPending: '__CLASSES_ADD_PENDING',
  addClassSuccess: '__CLASSES_ADD_SUCCESS',
  addClassError: '__CLASSES_ADD_ERROR',
};

export const classActions = {
  fetchPending: '__CLASS_FETCH_PENDING',
  fetchSuccess: '__CLASS_FETCH_SUCCESS',
  fetchFailure: '__CLASS_FETCH_FAILURE',
  addClassPending: '__CLASS_ADD_PENDING',
  addClassSuccess: '__CLASS_ADD_SUCCCESS',
  addClassError: '__CLASS_ADD_ERROR',
};

export const classroomActions = {
  fetchRequest: '__CLASSROOM_FETCH_REQUEST',
  fetchPending: '__CLASSROOM_FETCH_PENDING',
  fetchSuccess: '__CLASSROOM_FETCH_SUCCESS',
  fetchError: '__CLASSROOM_FETCH_ERROR',

  incrementConfirmed: '__CLASSROOM_CONFIRMED_INCREMENT',
  incrementUnconfirmed: '__CLASSROOM_UNCONFIRMED_INCREMENT',
  decrementConfirmed: '__CLASSROOM_CONFIRMED_DECREMENT',
  decrementUnconfirmed: '__CLASSROOM_UNCONFIRMED_DECREMENT',

  deleteRequest: '__CLASSROOM_DELETE_REQUEST',
  deletePending: '__CLASSROOM_DELETE_PENDING',
  deleteSuccess: '__CLASSROOM_DELETE_SUCCESS',
  deleteError: '__CLASSROOM_DELETE_ERROR',

  updateRequest: '__CLASSROOM_UPDATE_REQUEST',
  updatePending: '__CLASSROOM_UPDATE_PENDING',
  updateSuccess: '__CLASSROOM_UPDATE_SUCCESS',
  updateError: '__CLASSROOM_UPDATE_ERROR',

  addTeacherRequest: '__CLASSROOM_ADD_TEACHER_REQUEST',
  addTeacherPending: '__CLASSROOM_ADD_TEACHER_PENDING',
  addTeacherSuccess: '__CLASSROOM_ADD_TEACHER_SUCCESS',
  addTeacherError: '__CLASSROOM_ADD_TEACHER_ERROR',

  inviteClassroomRequest: '__CLASSROOM_INVITE_REQUEST',
  inviteClassroomPending: '__CLASSROOM_INVITE_PENDING',
  inviteClassroomSuccess: '__CLASSROOM_INVITE_SUCCESS',
  inviteClassroomError: '__CLASSROOM_INVITE_ERROR',

  inviteClassroomToBerufsCheckRequest: '__CLASSROOM_INVITE_BERUFS_CHECK_REQUEST',
  inviteClassroomToBerufsCheckPending: '__CLASSROOM_INVITE_BERUFS_CHECK_PENDING',
  inviteClassroomToBerufsCheckSuccess: '__CLASSROOM_INVITE_BERUFS_CHECK_SUCCESS',
  inviteClassroomToBerufsCheckError: '__CLASSROOM_INVITE_BERUFS_CHECK_ERROR',
};

export const classroomApplications = {
  fetchRequest: '__CLASSROOM_APPLICATIONS_FETCH_REQUEST',
  fetchPending: '__CLASSROOM_APPLICATIONS_FETCH_PENDING',
  fetchSuccess: '__CLASSROOM_APPLICATIONS_FETCH_SUCCESS',
  fetchError: '__CLASSROOM_APPLICATIONS_FETCH_ERROR',
  clear: '__CLASSROOM_APPLICATIONS_CLEAR',
};

export const classroomActivitiesActions = {
  fetchRequest: '__CLASSROOM_ACTIVITIES_FETCH_REQUEST',
  fetchPending: '__CLASSROOM_ACTIVITIES_FETCH_PENDING',
  fetchSuccess: '__CLASSROOM_ACTIVITIES_FETCH_SUCCESS',
  fetchError: '__CLASSROOM_ACTIVITIES_FETCH_ERROR',
};

export const classroomStudentsActions = {
  fetchAllRequest: '__CLASSROOM_ALL_STUDENTS_FETCH_REQUEST',
  fetchAllPending: '__CLASSROOM_ALL_STUDENTS_FETCH_PENDING',
  fetchAllSuccess: '__CLASSROOM_ALL_STUDENTS_FETCH_SUCCESS',
  fetchAllError: '__CLASSROOM_ALL_STUDENTS_FETCH_ERROR',
  clearAll: '__CLASSROOM_ALL_STUDENTS_CLEAR',

  fetchSingleRequest: '__CLASSROOM_SINGLE_STUDENT_FETCH_REQUEST',
  fetchSinglePending: '__CLASSROOM_SINGLE_STUDENT_FETCH_PENDING',
  fetchSingleSuccess: '__CLASSROOM_SINGLE_STUDENT_FETCH_SUCCESS',
  fetchSingleError: '__CLASSROOM_SINGLE_STUDENT_FETCH_ERROR',

  addStudentRequest: '__CLASSROOM_STUDENT_ADD_REQUEST',
  addStudentPending: '__CLASSROOM_STUDENT_ADD_PENDING',
  addStudentSuccess: '__CLASSROOM_STUDENT_ADD_SUCCESS',
  addStudentError: '__CLASSROOM_STUDENT_ADD_ERROR',

  removeStudentRequest: '__CLASSROOM_STUDENT_REMOVE_REQUEST',
  removeStudentPending: '__CLASSROOM_STUDENT_REMOVE_PENDING',
  removeStudentSuccess: '__CLASSROOM_STUDENT_REMOVE_SUCCESS',
  removeStudentError: '__CLASSROOM_STUDENT_REMOVE_ERROR',

  sendReminderRequest: '__CLASSROOM_SEND_REMINDER_REQUEST',
  sendReminderPending: '__CLASSROOM_SEND_REMINDER_PENDING',
  sendReminderSuccess: '__CLASSROOM_SEND_REMINDER_SUCCESS',
  sendReminderError: '__CLASSROOM_SEND_REMINDER_ERROR',
};

export const modalActions = {
  toggleModal: '__MODAL_TOGGLE',
  spawnModal: '__MODAL_SPAWN',
  initModalData: '__MODAL_INIT',
};

export const errorTypes = {
  api: '__ERROR_API_GENERAL',
  apiTimeoutError: '__ERROR_API_TIMEOUT',
};

export const studentActions = {
  fetchStudentRequest: '__STUDENT_FETCH_REQUEST',
  fetchStudentSuccess: '__STUDENT_FETCH_SUCCESS',
  fetchStudentFailure: '__STUDENT_FETCH_FAILURE',
  fetchStudentPending: '__STUDENT_FETCH_PENDING',
  fetchApplications: '__STUDENT_APPLICATIONS_FETCH_REQUEST',
  fetchApplicationsSuccess: '__STUDENT_APPLICATIONS_FETCH_SUCCESS',
  fetchApplicationsFailure: '__STUDENT_APPLICATIONS_FETCH_FAILURE',
  clearApplications: '__STUDENT_APPLICATIONS_CLEAR',
  removeStudent: '__STUDENT_REMOVE_REQUEST',
  removeStudentSuccess: '__STUDENT_REMOVE_SUCCESS',
  fetchJobFinder: '__STUDENT_JOBFINDER_FETCH',
  fetchJobFinderDone: '__STUDENT_JOBFINDER_FETCH_DONE',
};

export const photosActions = {
  fetchRequest: '__PHOTOS_FETCH_REQUEST',
  fetchSuccess: '__PHOTOS_FETCH_SUCCESS',
  fetchError: '__PHOTOS_FETCH_FAILURE',
  fetchPending: '__PHOTOS_FETCH_PENDING',

  fetchDownloadRequest: '__PHOTOS_FETCH_ALL_REQUEST',
  fetchDownloadSuccess: '__PHOTOS_FETCH_ALL_SUCCESS',
  fetchDownloadError: '__PHOTOS_FETCH_ALL_FAILURE',
  fetchDownloadPending: '__PHOTOS_FETCH_ALL_PENDING',

  fetchArchiveRequest: '__PHOTOS_ARCHIVE_REQUEST',
  fetchArchiveSuccess: '__PHOTOS_ARCHIVE_SUCCESS',
  fetchArchiveError: '__PHOTOS_ARCHIVE_FAILURE',
  fetchArchivePending: '__PHOTOS_ARCHIVE_PENDING',

  fetchEventsRequest: '__PHOTOS_EVENTS_REQUEST',
  fetchEventsSuccess: '__PHOTOS_EVENTS_SUCCESS',
  fetchEventsError: '__PHOTOS_EVENTS_FAILURE',
  fetchEventsPending: '__PHOTOS_EVENTS_PENDING',

  assignPhotoRequest: '__ASSIGN_PHOTO_REQUEST',
  assignPhotoSuccess: '__ASSIGN_PHOTO_SUCCESS',
  assignPhotoError: '__ASSIGN_PHOTO_FAILURE',
  assignPhotoPending: '__ASSIGN_PHOTO_PENDING',

  unassignPhotoRequest: '__UNASSIGN_PHOTO_REQUEST',
  unassignPhotoSuccess: '__UNASSIGN_PHOTO_SUCCESS',
  unassignPhotoError: '__UNASSIGN_PHOTO_FAILURE',
  unassignPhotoPending: '__UNASSIGN_PHOTO_PENDING',
};
