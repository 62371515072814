import React from 'react';
import { useDispatch } from 'react-redux';
import { deletePhoto } from 'redux/classroom/photos';
import * as api from 'api';

const useRemovePhoto = () => {
  const dispatch = useDispatch();
  const [photoId, setPhotoId] = React.useState(null);
  const [classId, setClassId] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const setVariables = (newClassId, newPhotoId) => {
    setClassId(newClassId);
    setPhotoId(newPhotoId);
  };

  React.useEffect(() => {
    const fetchRemovePhoto = async () => {
      setIsSuccess(false);
      setIsPending(true);
      setIsError(false);
      try {
        const token = api.getToken();
        await api.userApi.delete(`/classroom_photos/${classId}/${photoId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(deletePhoto(photoId));
        setIsSuccess(true);
      } catch (err) {
        setIsError(true);
      }
      setPhotoId(null);
      setIsPending(false);
    };
    if (classId && photoId) {
      fetchRemovePhoto();
    }
  }, [classId, photoId]);

  return [{ isError, isPending, isSuccess }, setVariables];
};
export default useRemovePhoto;
