import React from 'react';
import styled from 'styled-components';
import { Segment, Link, PlayButton, PdfButton } from 'atoms';
import { Heading } from 'Typography';

const StyledSegment = styled(Segment)`
  display: grid;
  grid-template-columns: 2.13rem 1fr;
  grid-template-rows: repeat(auto-fit, minmax(2.134rem, max-content));
  grid-gap: 0.67rem;
  h5 {
    grid-column: span 2;
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const TeaserSegment = ({ type, ...props }) => {
  switch (type) {
    case 'pdf': {
      const { content, ...args } = props;
      return (
        <StyledSegment {...args}>
          <Heading type="h5">{content.headerText}</Heading>
          {content.links.map(link => (
            <>
              <a href={link.to} target="_blank" rel="noopener noreferrer">
                <PdfButton />
              </a>
              <Link
                css={`
                  &&& {
                    line-height: normal;
                  }
                `}
                href={`${link.to}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.text}
              </Link>
            </>
          ))}
        </StyledSegment>
      );
    }
    case 'media': {
      const { content, ...args } = props;
      return (
        <StyledSegment {...args}>
          <Heading type="h5">{content.headerText}</Heading>
          {content.links.map(link => (
            <>
              <PlayButton href={link.to} target="_blank" rel="noopener noreferrer" />
              <Link
                css={`
                  &&& {
                    line-height: normal;
                  }
                `}
                href={link.to}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.text}
              </Link>
            </>
          ))}
        </StyledSegment>
      );
    }
    default:
      return <StyledSegment {...props} />;
  }
};

export default TeaserSegment;
