import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useRouteMatch } from 'react-router-dom';
import { PAD_S, DESKTOP } from 'breakpoints';
import uuid4 from 'uuid';
import { PhotoCard, PhotoSkeleton } from 'molecules';
import { useDispatch, useSelector } from 'react-redux';
import { Paragraph } from 'Typography';
import { useTranslation } from 'react-i18next';
import { useRemovePhoto } from 'utils';
import {
  getClassroomPhotos as getClassroomPhotosB,
  photosSelectors,
} from '../redux/classroom/photos';

const PhotosContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3.7rem;
  grid-row-gap: 4.5rem;

  @media (min-width: ${PAD_S}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${DESKTOP}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const PhotoGallery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading } = useSelector(state => state.classroomPhotos);
  const photos = useSelector(photosSelectors.selectAll);
  const {
    params: { id },
  } = useRouteMatch();

  useEffect(() => {
    dispatch(getClassroomPhotosB(id));
  }, [id]);

  const emptyArray = Array(7).fill(0);

  // eslint-disable-next-line no-unused-vars
  const [_, deletePhoto] = useRemovePhoto();

  return (
    <PhotosContainer>
      {loading && emptyArray.map(() => <PhotoSkeleton key={uuid4()} />)}
      {photos.map(photo => (
        <PhotoCard key={uuid4()} photo={photo} onDelete={photoId => deletePhoto(id, photoId)} />
      ))}
      {!photos.length && !loading && <Paragraph>{t('no_photos')}</Paragraph>}
    </PhotosContainer>
  );
};

export default PhotoGallery;
