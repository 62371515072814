import React from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import {
  CircularProgress as MuiCircularProgress,
  Fab,
  Grid,
  Snackbar,
  TextField,
} from '@material-ui/core';

import { useInviteStudent } from 'utils';

const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    color: ${green[500]};
    position: absolute;
    left: -4px;
    top: -4px;
  }
`;

const FabWrapper = styled.div`
  position: relative;
  width: min-content;
  margin-left: auto;
  ${({ success }) =>
    success &&
    css`
      & > .MuiFab-root {
        background-color: ${green[500]};
        &:hover {
          background-color: ${green[700]};
        }
      }
    `}
`;

const studentSchema = yup.object().shape({
  // eslint-disable-next-line prettier/prettier
  email: yup
    .string()
    .required()
    .email(),
  // eslint-disable-next-line prettier/prettier
  firstName: yup
    .string()
    .required()
    .min(1),
  // eslint-disable-next-line prettier/prettier
  lastName: yup
    .string()
    .required()
    .min(1),
});

const InviteStudentForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, formState, reset } = useForm({
    resolver: yupResolver(studentSchema),
  });

  const errorCaptions = {
    min: t('Password should be at least 8 characters long'),
    email: t('Provided email doesnt fulfill requirements'),
    matches: t('Password should contain at least one number'),
  };

  const { id: classId } = useParams();

  const [{ isLoading, isError, isSuccess }, inviteStudent] = useInviteStudent(classId);

  React.useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess]);

  return (
    <Grid container spacing={1} component="form" onSubmit={handleSubmit(inviteStudent)}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          type="text"
          inputRef={register}
          required
          name="firstName"
          label={t('invite_teacher_label_2')}
          error={errors.firstName}
          helperText={
            errors.firstName && (errorCaptions[errors.firstName.type] || errors.firstName.message)
          }
          css="width: 48%;"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          type="text"
          inputRef={register}
          required
          name="lastName"
          error={errors.lastName}
          label={t('invite_teacher_label_3')}
          helperText={
            errors.lastName && (errorCaptions[errors.lastName.type] || errors.lastName.message)
          }
          css="width: 48%;"
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          fullWidth
          label={t('invite_teacher_label_4')}
          type="email"
          name="email"
          inputRef={register}
          helperText={errors.email && (errorCaptions[errors.email.type] || errors.email.message)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FabWrapper success={isSuccess && !formState.isDirty}>
          <Fab
            aria-label="save"
            color="primary"
            as="input"
            type="submit"
            size="medium"
            disabled={isLoading}
          >
            {isSuccess && !formState.isDirty ? <CheckIcon /> : <SendIcon />}
          </Fab>
          {isLoading && <CircularProgress size={56} />}
        </FabWrapper>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(isError)}
        autoHideDuration={4000}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {isError && typeof isError === 'string' ? isError : t('invitation_error')}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default InviteStudentForm;
