export const districts = {
  aargau: 'AG',
  'appenzell-au.': 'AR',
  'appenzell-in.': 'AI',
  'basel-stadt': 'BS',
  baselland: 'BL',
  bern: 'BE',
  freiburg: 'FR',
  'fürstentum liechtenstein': 'LI',
  genf: 'GE',
  glarus: 'GL',
  graubünden: 'GR',
  jura: 'JU',
  luzern: 'LU',
  neuenburg: 'NE',
  nidwalden: 'NW',
  obwalden: 'OW',
  schaffhausen: 'SH',
  schwyz: 'SZ',
  solothurn: 'SO',
  'st. gallen': 'SG',
  tessin: 'TI',
  thurgau: 'TG',
  uri: 'UR',
  waadt: 'VD',
  wallis: 'VS',
  zug: 'ZG',
  zürich: 'ZH',
};

export const schoolTypes = {
  Anderes: [
    'graubünden',
    'uri',
    'zürich',
    'baselland',
    'basel-stadt',
    'jura',
    'luzern',
    'appenzell-au.',
    'appenzell-in.',
    'bern',
    'glarus',
    'st. gallen',
    'schaffhausen',
    'schwyz',
    'zug',
    'solothurn',
    'thurgau',
    'waadt',
    'genf',
    'neuenburg',
    'tessin',
    'nidwalden',
    'obwalden',
    'wallis',
    'aargau',
    'freiburg',
    'fürstentum liechtenstein',
  ],
  Gemischt: [
    'graubünden',
    'uri',
    'zürich',
    'baselland',
    'basel-stadt',
    'jura',
    'luzern',
    'appenzell-au.',
    'appenzell-in.',
    'bern',
    'glarus',
    'st. gallen',
    'schaffhausen',
    'schwyz',
    'zug',
    'solothurn',
    'thurgau',
    'waadt',
    'genf',
    'neuenburg',
    'tessin',
    'nidwalden',
    'obwalden',
    'wallis',
    'aargau',
    'freiburg',
    'fürstentum liechtenstein',
  ],
  Bezirksschule: ['aargau'],
  "Cycle D'orientation I": ['wallis'],
  "Cycle D'orientation Ii": ['wallis'],
  Gymnasium: ['appenzell-in.', 'glarus'],
  Oberschule: ['glarus'],
  'Orientierungsschule A': ['nidwalden', 'obwalden'],
  'Orientierungsschule B': ['nidwalden', 'obwalden'],
  Progymnasialklasse: ['freiburg'],
  Progymnasium: ['nidwalden', 'obwalden', 'waadt'],
  Realklasse: ['freiburg'],
  Realschule: [
    'aargau',
    'appenzell-in.',
    'bern',
    'glarus',
    'graubünden',
    'st. gallen',
    'schaffhausen',
    'schwyz',
    'uri',
    'zug',
  ],
  Realstufe: ['genf', 'neuenburg', 'tessin'],
  'Section Générale': ['bern'],
  'Section Moderne': ['bern'],
  'Section Préparant Aux Écoles De Maturité': ['bern'],
  'Sek B': ['solothurn'],
  'Sek E': ['solothurn', 'thurgau'],
  'Sek G': ['thurgau'],
  'Sek P': ['solothurn'],
  Sekstufe: ['waadt', 'genf', 'neuenburg', 'tessin'],
  Sekundarklasse: ['freiburg'],
  Sekundarschule: [
    'aargau',
    'appenzell-au.',
    'appenzell-in.',
    'bern',
    'glarus',
    'graubünden',
    'st. gallen',
    'schaffhausen',
    'schwyz',
    'zug',
  ],
  'Sekundarschule A': ['baselland', 'basel-stadt', 'jura', 'luzern', 'zürich'],
  'Sekundarschule B': ['jura', 'luzern', 'zürich'],
  'Sekundarschule C': ['jura', 'luzern', 'zürich'],
  'Sekundarschule E': ['baselland', 'basel-stadt'],
  'Sekundarschule P': ['baselland', 'basel-stadt'],
  Untergymnasium: ['graubünden', 'luzern', 'uri', 'zürich'],
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeSentence = sentence => {
  const splitSentence = sentence.split(' ');
  const newSentence = [];

  splitSentence.forEach(word => {
    newSentence.push(capitalize(word));
  });

  return newSentence.join(' ');
};
