/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import normalize from 'jsonapi-normalizer';
import * as api from '../api';

export const getClassroomStudents = createAsyncThunk(
  'classroomStudents/getStatus',
  async (classId, { rejectWithValue }) => {
    try {
      const token = api.getToken();
      const response = await api.userApi.get(`/classrooms/${classId}/members`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const normalizedData = normalize(response.data);
      const {
        entities: { classroomMembers, students },
      } = normalizedData;
      if (classroomMembers) {
        const fullStudentData = Object.values(classroomMembers).map(member => {
          const student = students && students[member.userUuid];
          return { ...student, ...member };
        });
        return fullStudentData;
      }

      return [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const studentsAdapter = createEntityAdapter({
  // we want to display unconfirmed students always first
  sortComparer: (studentA, studentB) => {
    if (studentA.confirmed === null && studentB.confirmed) return -1;
    if (studentA.confirmed === studentB.confirmed) return 0;
    return 1;
  },
});

const initialState = studentsAdapter.getInitialState({ loading: false, error: '' });

export const studentsSlice = createSlice({
  name: 'classroomStudents',
  initialState,
  reducers: {
    resetStudents: () => initialState,
    removeStudent: studentsAdapter.removeOne,
    addStudent: studentsAdapter.addOne,
  },
  extraReducers: builder => {
    builder
      .addCase(getClassroomStudents.pending, state => {
        studentsAdapter.setAll(state, {});
        state.loading = true;
      })
      .addCase(getClassroomStudents.fulfilled, (state, action) => {
        studentsAdapter.setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getClassroomStudents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  actions: { resetStudents, removeStudent, addStudent },
} = studentsSlice;

export const studentsSelectors = studentsAdapter.getSelectors(state => state.students);

export default studentsSlice.reducer;
