/* eslint-disable camelcase */
import { put, takeLatest, delay } from 'redux-saga/effects';
import tracker from 'tracker';
import { classroomActions, errorTypes, modalActions } from '../rootTypes';
import * as api from '../api';

export function* classroomFetcher(action) {
  try {
    yield put({ type: classroomActions.fetchPending });

    const { classId } = action;
    const token = api.getToken();
    const classRoom = yield api.userApi.get(`/classrooms/${classId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const {
      data: {
        attributes: { name, graduationYear },
      },
    } = classRoom.data;
    tracker.trackEvent('OpenClass', `${name} ${graduationYear}`);
    yield put({
      type: classroomActions.fetchSuccess,
      payload: classRoom.data,
    });
  } catch (err) {
    const error =
      err.response.data.errors[0].status === 403 || err.response.data.errors[0].status === 404
        ? 'unauthorized'
        : err.message;
    yield put({ type: classroomActions.fetchError, payload: error });
    yield put({ type: errorTypes.api, payload: err.response });
  }
}

export function* removeClassroom(action) {
  try {
    yield put({ type: classroomActions.deletePending });

    const { classId } = action;
    const token = api.getToken();
    yield api.userApi.delete(`/classrooms/${classId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: classroomActions.deleteSuccess,
      payload: classId,
    });
  } catch (err) {
    yield put({ type: errorTypes.api, payload: err.message });
    yield put({ type: classroomActions.deleteError });
  }
}

export function* updateClassroom(action) {
  try {
    yield put({ type: classroomActions.updatePending });

    const { payload, classId } = action;
    const token = api.getToken();
    const updateData = {
      data: {
        type: 'classrooms',
        id: classId,
        attributes: {
          ...payload,
        },
      },
    };

    const classRoomUpdate = yield api.userApi.patch(`/classrooms/${classId}`, updateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put({
      type: classroomActions.updateSuccess,
      payload: classRoomUpdate.data,
    });
  } catch (err) {
    yield put({ type: errorTypes.api, payload: err.message });
    yield put({ type: classroomActions.updateError });
  }
}

export function* addTeacher(action) {
  try {
    yield put({ type: classroomActions.addTeacherPending });

    const { email } = action;
    const { classId } = action;
    const token = api.getToken();
    const updateData = {
      data: {
        type: 'classrooms',
        id: classId,
        attributes: {
          email,
        },
      },
    };

    const addTeacherPost = yield api.userApi.post(
      `/classrooms/${classId}/add_teacher`,
      updateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    yield put({
      type: classroomActions.addTeacherSuccess,
      payload: {
        included: { ...addTeacherPost.data.data },
        data: { id: addTeacherPost.data.data.id, type: 'teachers' },
      },
    });

    yield put({
      type: modalActions.spawnModal,
      payload: 'Info',
    });

    yield put({
      type: modalActions.initModalData,
      key: 'Info',
      payload: { textKey: 'add_teacher_success', primary: false },
    });

    yield put({
      type: modalActions.toggleModal,
      payload: true,
    });

    yield delay(2000);

    yield put({
      type: modalActions.toggleModal,
      payload: false,
    });
  } catch (err) {
    yield put({ type: errorTypes.api, payload: err.response });

    const errorMessage =
      (Array.isArray(err.response.data.errors) &&
        err.response.data.errors.length &&
        err.response.data.errors[0].detail) ||
      err.message;

    yield put({ type: classroomActions.addTeacherError, payload: errorMessage });

    yield put({
      type: modalActions.spawnModal,
      payload: 'Info',
    });

    if (errorMessage === 'Teacher has been assigned already.') {
      yield put({
        type: modalActions.initModalData,
        key: 'Info',
        payload: { textKey: 'add_teacher_already_added', primary: true },
      });
    } else {
      yield put({
        type: modalActions.initModalData,
        key: 'Info',
        payload: { textKey: 'add_teacher_failure', primary: true },
      });
    }

    yield put({
      type: modalActions.toggleModal,
      payload: true,
    });

    yield delay(4000);

    yield put({
      type: modalActions.toggleModal,
      payload: false,
    });
  }
}

export function* inviteClassroom(action) {
  try {
    tracker.trackEvent('Clicks - P 5.0 - Einladung zum Berufs-Finder');
    yield put({ type: classroomActions.inviteClassroomPending });

    const { classId } = action;
    const token = api.getToken();

    yield api.userApi.post(`/classrooms/${classId}/invite_to_job_finder`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put({
      type: classroomActions.inviteClassroomSuccess,
    });
  } catch (err) {
    yield put({ type: errorTypes.api, payload: err.response });
    yield put({ type: classroomActions.inviteClassroomError, payload: err.message });

    yield put({
      type: modalActions.spawnModal,
      payload: 'Info',
    });

    if (err.response !== undefined) {
      yield put({
        type: modalActions.initModalData,
        key: 'Info',
        payload: { textKey: err.response.data.errors[0].detail, primary: true },
      });
    } else {
      yield put({
        type: modalActions.initModalData,
        key: 'Info',
        payload: { textKey: 'internal_error', primary: true },
      });
    }

    yield put({
      type: modalActions.toggleModal,
      payload: true,
    });

    yield delay(2000);

    yield put({
      type: modalActions.toggleModal,
      payload: false,
    });
  }
}

export function* inviteClassroomToBerufscheck(action) {
  try {
    tracker.trackEvent('Clicks - P 5.0 - Einladung zum Berufs-Check');
    yield put({ type: classroomActions.inviteClassroomToBerufsCheckPending });

    const { classId } = action;
    const token = api.getToken();

    yield api.userApi.post(`/classrooms/${classId}/invite_to_job_check`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put({
      type: classroomActions.inviteClassroomToBerufsCheckSuccess,
    });
  } catch (err) {
    yield put({ type: errorTypes.api, payload: err.response });
    yield put({ type: classroomActions.inviteClassroomToBerufsCheckError, payload: err.response });

    yield put({
      type: modalActions.spawnModal,
      payload: 'Info',
    });

    if (err.response !== undefined) {
      yield put({
        type: modalActions.initModalData,
        key: 'Info',
        payload: { textKey: err.response.data.errors[0].detail, primary: true },
      });
    } else {
      yield put({
        type: modalActions.initModalData,
        key: 'Info',
        payload: { textKey: 'internal_error', primary: true },
      });
    }

    yield put({
      type: modalActions.toggleModal,
      payload: true,
    });

    yield delay(2000);

    yield put({
      type: modalActions.toggleModal,
      payload: false,
    });
  }
}

export function* classroomSaga() {
  yield takeLatest(classroomActions.fetchRequest, classroomFetcher);
  yield takeLatest(classroomActions.deleteRequest, removeClassroom);
  yield takeLatest(classroomActions.updateRequest, updateClassroom);
  yield takeLatest(classroomActions.addTeacherRequest, addTeacher);
  yield takeLatest(classroomActions.inviteClassroomRequest, inviteClassroom);
  yield takeLatest(
    classroomActions.inviteClassroomToBerufsCheckRequest,
    inviteClassroomToBerufscheck,
  );
}
