/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { compareApplications, turnResponseIntoApplications } from './utils';
import * as api from '../api';

export const fetchStudentDraftApplications = createAsyncThunk(
  'studentDraftApplications/fetchStatus',
  async (memberId, { rejectWithValue }) => {
    const token = api.getToken();
    try {
      const response = await api.userApi.get(
        `/classroom_members/${memberId}/applications?drafts=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return turnResponseIntoApplications(response);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const draftsAdapter = createEntityAdapter({
  sortComparer: compareApplications,
});

const initialState = draftsAdapter.getInitialState({ loading: false, error: '' });
export const draftApplicationsSlice = createSlice({
  name: 'studentDraftApplications',
  initialState,
  reducers: { resetDraftApplications: () => initialState },
  extraReducers: builder => {
    builder
      .addCase(fetchStudentDraftApplications.pending, state => {
        draftsAdapter.setAll(state, {});
        state.loading = true;
      })
      .addCase(fetchStudentDraftApplications.fulfilled, (state, action) => {
        draftsAdapter.setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchStudentDraftApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  actions: { resetDraftApplications },
} = draftApplicationsSlice;

export const getAllStudentDraftApplications = () => draftsAdapter.getSelectors().selectAll();

export default draftApplicationsSlice.reducer;
