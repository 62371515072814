/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { compareApplications, turnResponseIntoApplications } from './utils';
import * as api from '../api';

export const fetchStudentApplications = createAsyncThunk(
  'studentApplications/fetchStatus',
  async (memberId, { rejectWithValue }) => {
    const token = api.getToken();
    try {
      const response = await api.userApi.get(`/classroom_members/${memberId}/applications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return turnResponseIntoApplications(response);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const applicationsAdapter = createEntityAdapter({
  sortComparer: compareApplications,
});

const initialState = applicationsAdapter.getInitialState({ loading: false, error: '' });

export const applicationsSlice = createSlice({
  name: 'studentApplications',
  initialState,
  reducers: {
    resetApplications: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStudentApplications.pending, state => {
        applicationsAdapter.setAll(state, {});
        state.loading = true;
      })
      .addCase(fetchStudentApplications.fulfilled, (state, action) => {
        applicationsAdapter.setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchStudentApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  actions: { resetApplications },
} = applicationsSlice;

export const studentApplicationsSelectors = applicationsAdapter.getSelectors(
  state => state.studentApplications,
);

export default applicationsSlice.reducer;
