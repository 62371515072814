import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'molecules';
import { Heading } from 'Typography';
import { Button } from 'atoms';
import { useTranslation } from 'react-i18next';
import { userLogout } from 'actions';
import { getTokenStatus } from 'selectors';
import { useLocation } from 'react-router-dom';

const TimeoutWarning = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isTokenTimedOut } = useSelector(getTokenStatus);
  const isLogin = pathname === '/auth/login';

  const { t } = useTranslation();

  if (isLogin) {
    return <> </>;
  }

  const buttonClickHandler = () => {
    window.location.reload();
    dispatch(userLogout());
  };

  return (
    <div
      css={`
        display: ${isTokenTimedOut ? 'flex' : 'none'};
        position: fixed;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.5);
      `}
    >
      <Card
        secondary
        css={`
          display: grid;
          grid-template-rows: min-content;
          grid-gap: 1rem;
        `}
      >
        <Heading type="h4" color="secondary" margin="0">
          {t('timeout_error_header')}
        </Heading>
        {t('timeout_error_text')}
        <Button secondary css="width: min-content;" onClick={() => buttonClickHandler()}>
          OK
        </Button>
      </Card>
    </div>
  );
};

export default TimeoutWarning;
