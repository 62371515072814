import React from 'react';
import useFetch from 'use-http';
import { useRouteMatch } from 'react-router-dom';
import uuid4 from 'uuid';
import { headers, getToken, getUri } from 'api';
import EventPhotosLink from './EventPhotosLink';

const EventLinks = () => {
  const {
    params: { id },
  } = useRouteMatch();
  const { data = { data: [] } } = useFetch(
    `${getUri()}/classroom_photos/${id}/events`,
    {
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()}`,
      },
    },
    [],
  );
  return data.data.map(event => <EventPhotosLink key={uuid4()} event={event} />);
};
export default EventLinks;
