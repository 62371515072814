import React from 'react';
import styled from 'styled-components';
import { Link } from 'atoms';
import { EntryContent } from 'templates';

import { useTranslation } from 'react-i18next';
import * as bp from 'breakpoints';
import { images } from './assets';

const Background = styled.div`
  background: url(${images.backgroundMedium}) no-repeat;
  background-size: cover;
  background-position-x: 50%;
  width: 100vw;
  box-sizing: border-box;
  padding: 220px 1.34rem 0 1.34rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @media (min-width: ${bp.MOBILE_S}) {
    padding-top: 260px;
  }
  @media (min-width: ${bp.PAD_S}) {
    overflow: hidden;
    height: 100vh;
    padding: 0 1.34rem 0 6rem;
    background-position: center;
  }
  @media (min-width: 1024px) {
    background-image: url(${images.backgroundBig});
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin: 1.7rem 0;
  width: 100%;
  @media (min-width: ${bp.PAD_S}) {
    margin: 1.467rem 0;
  }
  @media (min-width: ${bp.PAD_L}) {
    margin: auto 0 1.467rem 0;
  }
`;

const Entry = () => {
  const { t } = useTranslation();
  return (
    <Background>
      <EntryContent images={images} />
      <Footer>
        <Link
          href="https://www.yousty.ch/"
          css={`
            text-decoration: none;
            color: rgba(255, 255, 255, 0.85);
            line-height: 1.334rem;
            font-weight: lighter;
          `}
        >
          {t('back_to_Yousty')}
        </Link>
      </Footer>
    </Background>
  );
};

export default Entry;
