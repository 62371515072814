import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import uuidv4 from 'uuid';
import tracker from 'tracker';
import { Paper, ToggleButton } from 'atoms';
import { TimetableGrid } from 'molecules';
import { Heading } from 'Typography';
import { DESKTOP } from 'breakpoints';

import { getPhaseData } from 'selectors';
import { setActivePhase } from 'actions';

const ButtonGrid = styled.div`
  position: absolute;
  display: grid;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(6, 2.8rem);
  grid-template-columns: repeat(24, 1fr);
  grid-gap: 0.5rem 0;
  align-items: center;
  padding-bottom: 1rem;
  button {
    min-width: 0;
    min-height: 0;
    margin: 0 0.5rem;
    padding: 0;
    text-align: center;
    @media (min-width: ${DESKTOP}) {
      margin: 0 1rem;
    }
  }
`;

const RoadmapTimetable = ({ onButtonClick, data, ...args }) => {
  return (
    <Paper relative hideMobile elevation="2" primary {...args}>
      <TimetableGrid />
      <ButtonGrid>
        <Heading
          type="h5"
          css={`
            visibility: hidden;
          `}
        />
        <ToggleButton
          key={uuidv4()}
          elevation="default"
          active={data.picking.active}
          color="primary"
          css={`
            grid-row: 3 / span 1;
            grid-column: 5 / span 8;
          `}
          onClick={() => {
            tracker.trackEvent(data.picking.text);
            onButtonClick('picking');
          }}
        >
          Berufswahl
        </ToggleButton>
        <ToggleButton
          key={uuidv4()}
          elevation="default"
          active={data.preview.active}
          color="primary"
          css={`
            grid-row: 4 / span 1;
            grid-column: 9 / span 8;
          `}
          onClick={() => {
            tracker.trackEvent(`Clicks - P3.0 - ${data.preview.text}`);
            onButtonClick('preview');
          }}
        >
          Schnupperlehren
        </ToggleButton>
        <ToggleButton
          key={uuidv4()}
          elevation="default"
          active={data.recruitment.active}
          color="primary"
          css={`
            grid-row: 5 / span 1;
            grid-column: 17 / -1;
            margin-left: 30px !important;
          `}
          onClick={() => {
            tracker.trackEvent(`Clicks - P3.0 - ${data.recruitment.text}`);
            onButtonClick('recruitment');
          }}
        >
          Bewerbungsphase
        </ToggleButton>
        <ToggleButton
          key={uuidv4()}
          elevation="default"
          active={data.interview.active}
          color="primary"
          css={`
            grid-row: 6 / span 1;
            grid-column: 18 / -1;
          `}
          onClick={() => {
            tracker.trackEvent(`Clicks - P3.0 - ${data.interview.text}`);
            onButtonClick('interview');
          }}
        >
          Vorstellungsgespräch
        </ToggleButton>
        <ToggleButton
          key={uuidv4()}
          elevation="default"
          active={data.SwissSkills.active}
          color="orange"
          css={`
            grid-row: 6 / span 1;
            grid-column: 1 / span 4;
          `}
          onClick={() => {
            tracker.trackEvent(`Clicks - P3.0 - ${data.SwissSkills.text}`);
            onButtonClick('SwissSkills');
          }}
        >
          Swiss-Skills
        </ToggleButton>
        <ToggleButton
          key={uuidv4()}
          elevation="default"
          active={data.jobfair.active}
          color="orange"
          css={`
            grid-row: 6 / span 1;
            grid-column: 9 / span 4;
          `}
          onClick={() => {
            tracker.trackEvent(`Clicks - P3.0 - ${data.jobfair.text}`);
            onButtonClick('jobfair');
          }}
        >
          Berufsmessen
        </ToggleButton>
      </ButtonGrid>
    </Paper>
  );
};

const mapDispatchToProps = dispatch => ({
  onButtonClick: phaseName => {
    dispatch(setActivePhase(phaseName));
  },
});

const mapStateToProps = state => ({
  data: getPhaseData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoadmapTimetable);
