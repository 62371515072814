import { modalActions } from '../rootTypes';

const INITIAL_STATE = {
  show: false,
  action: null,
  EditClass: {
    name: '',
    state: '',
    schoolLevel: '',
    graduationYear: null,
    error: {
      type: 'none',
    },
  },
  InviteStudent: {
    firstName: '',
    lastName: '',
  },
  Info: {
    textKey: '',
    primary: false,
    heading: '',
  },
};

const modalState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalActions.spawnModal:
      return {
        ...state,
        action: action.payload,
      };
    case modalActions.toggleModal:
      return {
        ...state,
        show: action.payload,
      };
    case modalActions.initModalData:
      return {
        ...state,
        [action.key]: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

export default modalState;
