/* eslint-disable react/jsx-wrap-multilines */
import * as React from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  Link as MuiLink,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import tracker from 'tracker';
import { classSchema } from 'utils';
import { districts, years, year } from 'utils/class';
import useAddClass from 'utils/useAddClass';

const ConfirmButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
  type: 'submit',
})`
  &.MuiButton-root {
    width: 100%;
  }
`;

const ToggleButton = styled(Button).attrs({ type: 'button', color: 'primary', size: 'large' })`
  &.MuiButton-root {
    background-color: ${({ theme, variant }) =>
      variant === 'outlined' ? theme.palette.common.white : theme.palette.primary.main};
  }
`;

const ButtonLabel = styled(InputLabel).attrs({ htmlFor: 'year-select' })`
  &.MuiInputLabel-root {
    position: initial;
    transform: none;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const ConfirmRow = styled(Grid).attrs({ item: true, xs: 12, spacing: 2 })`
  &.MuiGrid-root {
    display: flex;
    justify-content: space-between;
  }
`;

const Divider = styled(MuiDivider)`
  &.MuiDivider-root {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const ClassForm = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [{ isLoading, isSuccess, isError, error, data: classData }, addClass] = useAddClass();
  const { register, handleSubmit, errors, reset, control, setValue, watch } = useForm({
    resolver: yupResolver(classSchema),
    defaultValues: {
      graduationYear: year,
    },
  });
  const watchYear = watch('graduationYear');
  const watchDistrict = watch('state');
  const onSubmit = data => {
    addClass(data);
  };

  React.useEffect(() => {
    if (isError) {
      reset();
      setAlertOpen(true);
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      tracker.trackEvent('Clicks - New Class After Register');
      push(`/${t('class')}/${classData.id}`);
    }
  }, [isSuccess]);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid item container spacing={2}>
        <Grid item xs={7}>
          <TextField
            required
            error={!!errors.name}
            helperText={(errors.name && errors.name.message) || ' '}
            inputRef={register}
            label={t('edit_class_label_1')}
            name="name"
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="district-select">{t('edit_class_label_2')}</InputLabel>
            <Controller
              control={control}
              name="state"
              as={
                <Select id="district-select">
                  {Object.values(districts).map(({ name, shortcut }) => (
                    <MenuItem key={shortcut} value={shortcut}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth disabled={!watchDistrict} required>
          <InputLabel htmlFor="school-select">{t('edit_class_label_3')}</InputLabel>
          <Controller
            control={control}
            name="schoolLevel"
            as={
              <Select id="school-select">
                {watchDistrict &&
                  districts[watchDistrict].schools.map(school => (
                    <MenuItem key={v4()} value={school}>
                      {school}
                    </MenuItem>
                  ))}
              </Select>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <ButtonLabel>{t('edit_class_label_4')}</ButtonLabel>
          <Controller
            control={control}
            name="graduationYear"
            as={
              <ButtonGroup size="large">
                {years.map(graduationYear => (
                  <ToggleButton
                    type="button"
                    color="primary"
                    size="large"
                    variant={graduationYear === watchYear ? 'contained' : 'outlined'}
                    onClick={() => setValue('graduationYear', graduationYear)}
                  >
                    {graduationYear}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            }
          />
        </FormControl>
      </Grid>
      <Divider />
      <ConfirmRow>
        <Grid item xs={8}>
          <Link component={MuiLink} to={`/${t('classes')}`}>
            {t('fill_later')}
          </Link>
        </Grid>
        <Grid item xs={4}>
          <ConfirmButton disabled={isLoading}>Anmelden</ConfirmButton>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          autoHideDuration={4000}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => {
              setAlertOpen(false);
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      </ConfirmRow>
    </Grid>
  );
};

export default ClassForm;
