import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import { DEFAULT } from 'breakpoints';
import ContentLoader from 'react-content-loader';

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Skeleton = ({ ...args }) => (
  <SkeletonContainer
    primary
    elevation="default"
    {...args}
    css={`
      min-height: 5rem;
      display: flex;
      svg {
        width: 100%;
        height: 100%;
      }
    `}
  >
    <Media query={`(max-width: ${DEFAULT})`}>
      <ContentLoader
        css={`
          height: 8rem;
          width: 100%;
        `}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="36" rx="4" ry="4" width="236" height="12" />
        <rect x="0" y="60" rx="3" ry="3" width="270" height="6" />
        <rect x="0" y="74" rx="3" ry="3" width="280" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="154" height="6" />
        <rect x="358" y="-10" rx="0" ry="0" width="43" height="360" />
      </ContentLoader>
    </Media>
    <Media query={`(min-width: ${DEFAULT})`}>
      <ContentLoader height={95} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="0" y="14" rx="4" ry="4" width="236" height="12" />
        <rect x="0" y="54" rx="3" ry="3" width="350" height="6" />
        <rect x="0" y="66" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="78" rx="3" ry="3" width="201" height="5" />
        <rect x="355" y="0" rx="4" ry="4" width="45" height="45" />
      </ContentLoader>
    </Media>
  </SkeletonContainer>
);

export default Skeleton;
