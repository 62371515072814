import { ActionsCard } from 'organisms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { inviteClassroomToJobFinder, inviteClassToBerufsCheck } from 'actions';

const InviteCard = ({
  classId,
  inviteClassroom,
  inviteState,
  inviteToBfState,
  inviteClassToBCheck,
  ...args
}) => {
  const { t } = useTranslation();
  const actions = [
    {
      text: t('action_card_link_1'),
      onClick: id => {
        inviteClassToBCheck(id);
      },
      state: inviteToBfState,
    },
    {
      text: t('action_card_link_2'),
      onClick: id => {
        inviteClassroom(id);
      },
      state: inviteState,
    },
  ];

  return (
    <ActionsCard
      actions={actions}
      title={t('action_card_title')}
      subtitle={t('action_card_subtitle')}
      classId={classId}
      {...args}
    />
  );
};

const mapStateToProps = state => ({
  classId: state.classroomState.classroomData.id,
  inviteState: state.classroomState.invite,
  inviteToBfState: state.classroomState.inviteClassToBerufsCheck,
});

const mapDispatchToProps = dispatch => ({
  inviteClassroom: id => dispatch(inviteClassroomToJobFinder(id)),
  inviteClassToBCheck: classId => dispatch(inviteClassToBerufsCheck(classId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteCard);
