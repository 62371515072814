import { fork } from 'redux-saga/effects';

import { classesSaga } from './classes/saga';
import { roadmapSaga } from './roadmap/saga';
import { userSaga } from './user/saga';
import { classroomSaga } from './classroom/saga';
import { watchErrors } from './apiErrors/saga';

export * from './classes/saga';
export * from './roadmap/saga';
export * from './user/saga';
export * from './classroom/saga';
export * from './apiErrors/saga';
export default function* rootSaga() {
  yield fork(roadmapSaga);
  yield fork(classesSaga);
  yield fork(userSaga);
  yield fork(classroomSaga);
  yield fork(watchErrors);
}
