import { classesActions } from '../rootTypes';

const INITIAL_STATE = {
  fetching: false,
  sending: false,
  error: {},
  classes: [],
  createdClassId: null,
  newClass: {
    error: {
      type: 'none',
    },
  },
};

const classesState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case classesActions.addClassRequest:
      return {
        ...state,
      };
    case classesActions.addClassPending:
      return {
        ...state,
        sending: true,
      };
    case classesActions.addClassSuccess:
      return {
        ...state,
        sending: false,
        newClass: INITIAL_STATE.newClass,
        classes: [action.payload, ...state.classes],
        createdClassId: action.payload.id,
      };
    case classesActions.addClassError:
      return {
        ...state,
        createdClassId: null,
        newClass: {
          error: { type: 'error', errors: [...action.payload.errors] },
        },
      };
    case classesActions.fetchRequest:
      return {
        ...state,
        fetching: true,
      };
    case classesActions.fetchSuccess:
      return {
        ...state,
        fetching: false,
        error: false,
        classes: action.payload,
      };
    case classesActions.fetchError:
      return {
        ...state,
        fetching: false,
        error: true,
        classes: [],
      };
    default:
      return { ...state };
  }
};

export default classesState;
