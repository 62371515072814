import styled from 'styled-components';
import { PAD_S } from 'breakpoints';

const HamburgerIcon = styled.span`
  border-radius: 3px;
  height: 3px;
  width: 1.067rem;
  background: ${props =>
    props.navVisible ? 'var(--font-color-secondary)' : 'var(--font-color-primary)'};
  position: relative;
  align-self: center;
  content: '';
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  :before {
    top: -0.467rem;
    border-radius: 1px;
    height: 3px;
    width: 1.6rem;
    background: ${props =>
      props.navVisible ? 'var(--font-color-secondary)' : 'var(--font-color-primary)'};
    position: absolute;
    display: block;
    content: '';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  :after {
    bottom: -0.467rem;
    border-radius: 1px;
    height: 3px;
    width: 0.534rem;
    background: ${props =>
      props.navVisible ? 'var(--font-color-secondary)' : 'var(--font-color-primary)'};
    position: absolute;
    display: block;
    content: '';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  @media (min-width: ${PAD_S}) {
    background: var(--font-color-secondary);
    :before,
    :after {
      background: var(--font-color-secondary);
    }
  }
`;

export default HamburgerIcon;
