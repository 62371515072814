import styled from 'styled-components';

const Spinner = styled.div`
  border-radius: 50%;
  width: ${props => (props.flex ? '100%' : '1.5em')};
  height: 1.5em;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid rgba(255, 255, 255, 0.2);
  border-right: 0.3em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
  border-left: 0.3em solid ${props => (props.white ? 'white' : 'var(--color-primary)')};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`;

export default Spinner;
