import { navbarActions } from '../rootTypes';

/* eslint-disable import/prefer-default-export */
export const toggleNavbar = () => ({
  type: navbarActions.toggle,
});

export const toggleNotFound = () => ({
  type: navbarActions.toggleNotFound,
});
