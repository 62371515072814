/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-wrap-multilines */
import * as React from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Link,
  MenuItem,
  TextField,
  Button,
  Snackbar,
  Typography,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useRegisterUser from 'utils/useRegisterUser';
import { genders, registrationSchema } from 'utils';

const ConfirmButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
  type: 'submit',
})`
  &.MuiButton-root {
    width: 100%;
  }
`;

export const RegistrationForm = ({ onRegister }) => {
  const [{ isLoading, isSuccess, isError, error }, loginUser] = useRegisterUser();
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(registrationSchema),
  });
  const errorCaptions = {
    min: t('Password should be at least 8 characters long'),
    email: t('Provided email doesnt fulfill requirements'),
    matches: t('Password should contain at least one number'),
  };

  const onSubmit = handleSubmit(data => loginUser(data));

  React.useEffect(() => {
    isError && reset();
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) onRegister();
  }, [isSuccess]);

  return (
    <Grid
      container
      direction="column"
      component="form"
      spacing={1}
      onSubmit={onSubmit}
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="gender">Geschlecht</InputLabel>
            <Controller
              control={control}
              name="gender"
              as={
                <Select required id="gender">
                  {genders.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {t(text)}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <TextField
            required
            error={!!errors.first_name}
            helperText={
              errors.first_name &&
              (errorCaptions[errors.first_name.type] || errors.first_name.message)
            }
            inputRef={register}
            label="Vorname"
            name="first_name"
            fullWidth
            placeholder={t('first_name_placeholder')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            error={!!errors.last_name}
            helperText={
              errors.last_name && (errorCaptions[errors.last_name.type] || errors.last_name.message)
            }
            inputRef={register}
            label="Nachname"
            name="last_name"
            fullWidth
            placeholder={t('last_name_placeholder')}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          error={!!errors.email}
          helperText={errors.email && (errorCaptions[errors.email.type] || errors.email.message)}
          inputRef={register}
          label="E-mail"
          type="email"
          name="email"
          fullWidth
          placeholder={t('mail_placeholder')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          inputRef={register}
          label="Passwort"
          type="password"
          name="password"
          fullWidth
          error={!!errors.password}
          helperText={
            errors.password && (errorCaptions[errors.password.type] || errors.password.message)
          }
          placeholder="********"
        />
      </Grid>
      <Grid
        container
        spacing={2}
        item
        component={props => <Box display="flex" alignItems="center" {...props} />}
      >
        <Grid item xs={8}>
          <FormControlLabel
            control={
              <MuiCheckbox
                required
                value
                color="primary"
                inputProps={{ 'aria-labelledby': 'accept_agb' }}
              />
            }
            name="accept_agb"
            label=""
            inputRef={register}
            // @ts-ignore
            css={`
              &.MuiFormControlLabel-root {
                margin-right: 0;
              }
            `}
          />
          <Typography component="span" id="accept_agb">
            <Link underline="always" href="https://www.yousty.ch/de-CH/nutzungsbedingungen">
              Nutzungsbedingungen
            </Link>{' '}
            akzeptieren*
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <ConfirmButton disabled={isLoading}>{t('login')}</ConfirmButton>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isError}
        autoHideDuration={4000}
      >
        <Alert elevation={6} variant="filled" severity="error" style={{ maxWidth: 300 }}>
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default RegistrationForm;
