import React from 'react';
import ContentLoader from 'react-content-loader';

const LineSkeleton = ({ ...args }) => (
  <ContentLoader
    height={2}
    width={60}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...args}
  >
    <rect x="0" y="1" rx="0" ry="0" width="281" height="11" />
    <rect x="0" y="1" rx="0" ry="0" width="264" height="11" />
  </ContentLoader>
);

export default LineSkeleton;
