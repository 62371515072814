// eslint-disable-next-line import/prefer-default-export
import * as yup from 'yup';

import { districtShortcuts, schoolTypes, years } from './class';

export const objectKeysIncludeString = (object, string) =>
  Object.keys(object).includes(string.trim());

export const statusColorMap = {
  Unbeantwortet: 'var(--color-orange, #E6A54B)',
  'In Bearbeitung': 'var(--color-orange, #E6A54B)',
  Einladung: 'var(--color-green, #1FD581)',
  Zusage: 'var(--color-green, #1FD581)',
  Absage: 'var(--color-red, #D03D59)',
  'Absage durch Bewerber': 'var(--color-red, #D03D59)',
  'Unterlagen fehlen': 'var(--color-red, #D03D59)',
};

export { default as useRemoveStudent } from './useRemoveStudent';
export { default as useInviteStudent } from './useInviteStudent';
export { default as useRemindStudent } from './useRemindStudent';
export { default as useFetchClassActivities } from './useFetchClassActivities';
export { default as useFetchClassApplications } from './useFetchClassApplications';
export { default as useFetchPhotosLink } from './useFetchPhotosLink';
export { default as useRemovePhoto } from './useRemovePhoto';
export { default as compareDates } from './compareDates';

export const genders = [
  { text: 'gender_male', value: 0 },
  { text: 'gender_female', value: 1 },
];

export const passwordRegexp = new RegExp('.*[0-9].*');

export const registrationSchema = yup
  .object()
  .shape({
    // eslint-disable-next-line prettier/prettier
    email: yup.string().required().defined().email(),
    // eslint-disable-next-line prettier/prettier
    password: yup.string().required().defined().matches(passwordRegexp),
    // eslint-disable-next-line prettier/prettier
    gender: yup.number().defined().oneOf([0, 1]),
    // eslint-disable-next-line prettier/prettier
    first_name: yup.string().required().defined(),
    // eslint-disable-next-line prettier/prettier
    last_name: yup.string().required().defined(),
    // eslint-disable-next-line prettier/prettier
    accept_agb: yup.boolean().defined().required(),
  })
  .defined();

export const loginSchema = yup
  .object({
    // eslint-disable-next-line prettier/prettier
    email: yup.string().defined().email(),
    // eslint-disable-next-line prettier/prettier
    password: yup.string().defined().min(8).matches(passwordRegexp),
  })
  .defined();

export const classSchema = yup
  .object({
    // eslint-disable-next-line prettier/prettier
    name: yup.string().defined().required(),
    // eslint-disable-next-line prettier/prettier
    state: yup.string().defined().required().oneOf(districtShortcuts),
    // eslint-disable-next-line prettier/prettier
    schoolLevel: yup.string().defined().required().oneOf(schoolTypes),
    // eslint-disable-next-line prettier/prettier
    graduationYear: yup.number().required().defined().oneOf(years),
  })
  .defined();
