/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';

import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Heading } from 'Typography';

const SimpleApplicationRow = styled.div`
  padding: 1em 1.6em;
  background-color: var(--color-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid var(--color-lighter); */
  margin: 8px 8px 0;
  ${({ $expanded }) =>
    $expanded
      ? `
    border-radius: 10px 10px 0 0;
  `
      : `
    border-radius: 10px;
  `}
`;
const DocumentsNumber = styled.div`
  position: absolute;
  top: 13%;
  left: 13%;
  border-radius: 50%;
  background-color: var(--color-orange);
  width: 1.117rem;
  height: 1.117rem;
  font-size: 0.67em;
`;

export const ApplicationSkeleton = () => {
  return (
    <SimpleApplicationRow>
      <Skeleton variant="text" width="60%" height={40} />
      <Skeleton
        variant="rect"
        css="margin-left: auto; margin-right: 20px;"
        height={30}
        width={30}
      />
      <Skeleton variant="rect" width={16.5} height={16.5} />
    </SimpleApplicationRow>
  );
};

const ApplicationSimple = ({ onDocumentsClick, application, expanded }) => {
  const { jobName, companyName, documentsCount } = application;
  return (
    <SimpleApplicationRow $expanded={expanded}>
      <div>
        <Heading type="h5">{jobName}</Heading>
        <div>{companyName}</div>
      </div>
      <IconButton
        css={`
          &&& {
            margin-left: auto;
          }
        `}
        color="primary"
        onClick={() => onDocumentsClick()}
      >
        <DocumentsNumber>{documentsCount}</DocumentsNumber>
        <DescriptionIcon />
      </IconButton>
      <IconButton onClick={() => onDocumentsClick()} color="primary">
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </SimpleApplicationRow>
  );
};

export default ApplicationSimple;
