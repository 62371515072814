import React from 'react';
import * as api from 'api';

const useConfirmRegistration = () => {
  const [userId, setUserId] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  React.useEffect(() => {
    const remindUser = async () => {
      setIsSuccess(false);
      setIsPending(true);
      setIsError(false);
      try {
        const token = api.getToken();

        await api.userApi.post(
          `/teachers/${userId}/resend_confirmation`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setIsSuccess(true);
      } catch (err) {
        setIsError(true);
      }
      setIsPending(false);
      setUserId(null);
    };
    if (userId) {
      remindUser();
    }
  }, [userId]);

  return [{ isError, isPending, isSuccess }, setUserId];
};

export default useConfirmRegistration;
