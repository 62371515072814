import styled from 'styled-components';
import { Paper } from 'atoms';

const Teaser = styled(Paper)`
  padding: 1rem 0;
  display: grid;
  grid-gap: 1.05rem;
`;

export default Teaser;
