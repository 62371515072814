import styled from 'styled-components';
import * as bp from 'breakpoints';

const Table = styled.div`
  width: 100%;
`;

export const TableContent = styled.div`
  border-top: 0.3px solid var(--color-lighter, #e8e8e8);
`;

export const TableRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  ${props =>
    props.hideMobile &&
    `
  display: none;
  @media (min-width: ${bp.PAD_S}) {
    display: grid;
  }
`}
  border-bottom: 0.3px solid var(--color-lighter, #e8e8e8);
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 5px;
  }
`;

export const TableHeader = styled(TableRow)`
  border-top: none;
`;

export default Table;
