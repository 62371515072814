import { userActions } from '../rootTypes';

const INITIAL_STATE = {
  user: {
    attributes: {},
  },
  signedIn: false,
};

const userState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActions.fetchRequest:
      return { ...state, fetching: true };
    case userActions.fetchPending:
      return { ...state, user: { ...state.user, ...action.payload } };
    case userActions.fetchSuccess:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        signedIn: true,
        fetching: false,
      };
    case userActions.fetchFailure:
      return {
        ...state,
        fetching: false,
        user: { ...INITIAL_STATE.user },
        signedIn: false,
      };
    case userActions.logout:
      return { ...INITIAL_STATE };
    default:
      return { ...state };
  }
};

export default userState;
