import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'atoms';
import { connect } from 'react-redux';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faPlus as plus } from '@fortawesome/free-solid-svg-icons';
import { RoundedLoader } from '../StatefulButton/StatefulButton';

const PlusIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.7rem;
  width: 2.7rem;
  border-radius: 50%;
  border: 1px solid var(--font-color-primary);
  cursor: pointer;
`;

const Profile = styled.img`
  border-radius: 50%;
  height: 2.7rem;
`;

const ProfileList = styled.div`
  height: 100%;
`;

const ProfileHolder = styled.div`
  display: grid;
  grid-template-columns: 7fr 1fr;
  grid-column-gap: 1rem;
  width: 100%;
  align-items: center;

  div {
    display: grid;
    grid-template-columns: repeat(auto-fill, 2.7rem);
    grid-column-gap: 0.5rem;
    align-items: end;
  }
`;

const TeachersList = ({ showInviteModal, teachers, addTeacherState, toggleModal }) => {
  const handleAddClick = () => {
    toggleModal(false);
    showInviteModal();
  };

  const { fetching } = addTeacherState;

  const ActionIcon = () => {
    if (fetching) {
      return (
        <RoundedLoader>
          <Spinner />
        </RoundedLoader>
      );
    }

    return (
      <PlusIcon onClick={handleAddClick}>
        <FAIcon icon={plus} />
      </PlusIcon>
    );
  };
  return (
    <ProfileHolder>
      <ProfileList>
        {teachers.map(teacher => {
          return <Profile src={teacher?.attributes?.avatarSrc} key={teacher.id} />;
        })}
      </ProfileList>
      <ActionIcon />
    </ProfileHolder>
  );
};

const mapStateToProps = state => ({
  addTeacherState: state.classroomState.addTeacher,
});

export default connect(mapStateToProps)(TeachersList);
