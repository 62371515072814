import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { Card as MuiCard, CardHeader, IconButton, CardContent, Grid } from '@material-ui/core';
import InviteStudentForm from './InviteStudentForm';

const Card = styled(MuiCard)`
  ${({ hidden }) => hidden && `display: none`}
`;

export const InviteCard = () => {
  const { t } = useTranslation();
  const [hidden, hideCard] = React.useState(false);

  return (
    <Grid item xs={12} sm={6}>
      <Card hidden={hidden}>
        <CardHeader
          action={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <IconButton
              size="small"
              aria-label="don't display this card"
              onClick={() => hideCard(true)}
            >
              <CloseIcon />
            </IconButton>
          }
          title={t('invite_card_heading')}
          disableTypography
        />
        <CardContent
          css={`
            &&& {
              padding-top: 0;
            }
          `}
        >
          <InviteStudentForm />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default InviteCard;
