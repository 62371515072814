import { combineReducers } from 'redux';
import navState from './navbar/reducers';
import roadmapState from './roadmap/reducers';
import classesState from './classes/reducers';
import userState from './user/reducers';
import classroomState from './classroom/reducers';
import modalState from './modal/reducers';
import student from './student/student';
import students from './classroom/students';
import errorState from './apiErrors/reducers';
import classroomPhotos from './classroom/photos';
import studentApplications from './student/applications';
import studentDraftApplications from './student/draftApplications';

const appReducer = combineReducers({
  classroomPhotos,
  navState,
  roadmapState,
  classesState,
  userState,
  classroomState,
  modalState,
  student,
  students,
  errorState,
  studentApplications,
  studentDraftApplications,
});

const rootReducer = (state, action) => {
  if (action.type === '__USER_LOGOUT') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
