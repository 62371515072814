import React from 'react';
import { Skeleton, Card } from 'molecules';

const SkeletonCard = ({ children, ...args }) => (
  <Card {...args}>
    <Skeleton>{children}</Skeleton>
  </Card>
);

export default SkeletonCard;
