import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';
import { RoundButton, Spinner } from 'atoms';

export const RoundedLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 2rem;
  max-width: initial;
  max-height: initial;
  min-width: initial;
  border-radius: 50%;

  ${props =>
    props.check &&
    css`
      && {
        svg {
          width: 100%;
          height: 70%;
        }
      }
    `};

  ${props =>
    props.success &&
    css`
      && {
        svg {
          color: var(--color-green);
        }
      }
    `};

  ${props =>
    props.error &&
    css`
      && {
        svg {
          color: var(--color-red);
        }
      }
    `};
`;

const ActionRoundButton = styled(RoundButton)`
  margin: 0;
`;

const StatefulButton = ({ state, actions, tooltip = '', ...args }) => {
  const { sending, sent, isError } = state;

  if (!sending && !sent && !isError) {
    return (
      <Tooltip title={tooltip} aria-label={tooltip}>
        <span>
          <ActionRoundButton primary area="button" {...args} />
        </span>
      </Tooltip>
    );
  }

  if (sending) {
    return (
      <RoundedLoader {...args} onClick={null}>
        <Spinner />
      </RoundedLoader>
    );
  }

  if (sent) {
    return (
      <RoundedLoader check success {...args} onClick={null}>
        <FAIcon icon={faCheck} />
      </RoundedLoader>
    );
  }

  return (
    <RoundedLoader check error>
      <FAIcon icon={faTimes} />
    </RoundedLoader>
  );
};

export default StatefulButton;
