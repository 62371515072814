import React from 'react';
import { useTranslation } from 'react-i18next';
import Template from './Template';

const Presentations = () => {
  const { t } = useTranslation();
  return (
    <Template
      t={t}
      header={t('ads_presentations_header')}
      subheader={t('ads_presentations_subheader')}
      primaryTeaser={{
        header: null,
        text: t('ads_presentations_text'),
        link: {
          text: t('ads_presentations_button_text'),
          href: 'https://www.yousty.ch/de-CH/vorlagen',
          gaEvent: 'Vorlagen',
        },
      }}
    />
  );
};

export default Presentations;
