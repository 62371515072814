/* eslint-disable no-console */
import GATracker from 'react-ga';

class Tracker {
  constructor() {
    GATracker.initialize('UA-7109444-5', {
      debug: process.env.NODE_ENV === 'development',
    });
  }

  trackEvent = (action, label, value) => {
    GATracker.event({
      category: 'CareerChoiceRadar',
      action,
      label,
      value,
    });
  };

  trackPageView = (route, action) => {
    GATracker.event({
      category: 'CareerChoiceRadar',
      action,
    });
    GATracker.pageview(`/berufswahlradar${route}`);
  };
}

class DevTracker {
  constructor() {
    console.log('Spinning up dev tracker');
  }

  trackEvent = (action, label) => {
    console.log('tracking action', action, 'with label', label);
  };

  trackPageView = (route, action) => {
    console.log('tracking pageview on route', route, 'with action', action);
  };
}

const radarTracker = process.env.NODE_ENV === 'production' ? new Tracker() : new DevTracker();

export default radarTracker;
