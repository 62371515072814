import styled from 'styled-components';

const UserAvatar = styled.img`
  border-radius: 50%;
  height: 2.67rem;
  width: 2.67rem;
  cursor: pointer;
  filter: drop-shadow(-0.5px 1px 2px rgba(0, 0, 0, 0.35));
`;

export default UserAvatar;
