import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import tracker from 'tracker';
import { useParams } from 'react-router-dom';
import { Table, TableHeader, TableContent, TableRow } from 'atoms';
import { Skeleton } from 'molecules';
import { useFetchClassApplications, statusColorMap } from 'utils';
import * as bp from 'breakpoints';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import {
  faIndustry,
  faSmileBeam,
  faCircle,
  faExclamationCircle,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons';
import { Paper, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import uuid4 from 'uuid';

const ApprenticeshipRow = ({ application }) => {
  const { jobName, companyName, applicationType, status, sentAt, user } = application;

  const studentName = `${user.firstName} ${user.lastName}`;
  const [year, month, rest] = sentAt.split('-');
  const day = rest.substring(0, 2);
  const date = [day, month, year].join('.');
  return (
    <TableRow
      css={`
        border-left: 0.334rem solid ${statusColorMap[status]};
        box-sizing: border-box;
        padding: 1.6rem;
        padding-left: 1.266rem;
        grid-column-gap: 5px;
        grid-template-columns: 1fr 1fr;
        @media (min-width: ${bp.PAD_L}) {
          grid-template-columns: repeat(6, 1fr);
        }
      `}
    >
      <div css="font-weight: bold;">{studentName}</div>
      <div
        css={`
          color: var(--font-color-faded, #bbbec1);
          display: inline-grid;
          grid-gap: 0.334rem;
        `}
      >
        {jobName}
      </div>
      <div
        css={`
          color: var(--font-color-faded, #bbbec1);
          display: inline-grid;
          grid-template-columns: min-content min-content;
          grid-gap: 0.334rem;
        `}
      >
        <FAIcon
          icon={faIndustry}
          css={`
            filter: brightness(0.8);
            @media (min-width: ${bp.PAD_L}) {
              display: none;
            }
          `}
        />
        {companyName}
      </div>
      <div
        css={`
          color: var(--font-color-faded, #bbbec1);
          display: inline-grid;
          grid-template-columns: min-content min-content;
          grid-gap: 0.334rem;
        `}
      >
        <FAIcon
          icon={faSmileBeam}
          css={`
            filter: brightness(0.8);
            @media (min-width: ${bp.PAD_L}) {
              display: none;
            }
          `}
        />
        {applicationType}
      </div>
      <div
        css={`
          color: var(--font-color-faded, #bbbec1);
        `}
      >
        {date}
      </div>
      <div
        css={`
          align-self: end;
          display: flex;
          flex-direction: row;
          @media (min-width: ${bp.PAD_L}) {
            align-self: start;
          }
        `}
      >
        <FAIcon
          icon={faCircle}
          css={`
            &&& {
              color: ${statusColorMap[status]};
              height: 0.4rem;
              width: 0.4rem;
              align-self: center;
              padding-right: 0.4rem;
            }
          `}
        />
        {status}
      </div>
    </TableRow>
  );
};

const ApplicationsContent = styled(TableContent)`
  ${props =>
    props.error &&
    css`
      background-color: var(--color-lighter, #e8e8e8);
      min-height: 21rem;
      display: grid;
      grid-template-rows: 4fr 3fr;
      align-items: start;
      justify-items: center;
      border-radius: 0 0 5px 5px;
      grid-gap: 0.5rem;
      color: var(--color-darker, #616161);
      box-sizing: border-box;
    `}
`;

const ApprenticeshipSkeleton = styled(Skeleton)`
  height: 7rem;
  width: 96%;
  margin: 1rem auto;
  min-height: 0;
  & > svg {
    height: 7rem;
    :last-child {
      border-bottom-right-radius: 5px;
    }
  }
`;

export const ClassApplications = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    {
      isError,
      isPending,
      applications: { ids, entities },
    },
    setClassId,
  ] = useFetchClassApplications();
  useEffect(() => {
    setClassId(id);
    tracker.trackPageView('/klasse/apps', 'Visits - P5.2 - Bewerbungsübersicht');
  }, [id]);

  return (
    <Paper>
      <Typography type="h5" css="padding: 1.6rem 1.6rem 0.8rem ">
        {t('applications')}
      </Typography>
      <Table>
        <TableHeader
          css={`
            display: none;
            @media (min-width: ${bp.PAD_L}) {
              display: ${isError ? 'none' : 'grid'};
              padding: 0.6rem 1.6rem;
              box-sizing: border-box;
              font-weight: bold;
            }
          `}
        >
          <div>{t('studentName')}</div>
          <div>{t('jobName')}</div>
          <div>{t('companyName')}</div>
          <div>{t('applicationType')}</div>
          <div>{t('sentAt')}</div>
          <div css="padding-left: 0.3px;">{t('status')}</div>
        </TableHeader>
        <ApplicationsContent error={isError}>
          {isPending && (
            <>
              <ApprenticeshipSkeleton />
              <ApprenticeshipSkeleton />
              <ApprenticeshipSkeleton />
              <ApprenticeshipSkeleton />
            </>
          )}
          {isError && (
            <FAIcon
              icon={faExclamationCircle}
              css={`
                &&& {
                  height: 50%;
                  width: 50%;
                  align-self: end;
                }
              `}
            />
          )}

          {!isPending && ids.length === 0 && (
            <>
              <FAIcon
                icon={faChalkboardTeacher}
                css={`
                  &&& {
                    height: 4rem;
                    width: 4rem;
                    align-self: end;
                  }
                `}
              />
              {t('err_no_apprenticeships_yet')}
            </>
          )}
          {ids.map(applicationId => {
            return <ApprenticeshipRow application={entities[applicationId]} key={uuid4()} />;
          })}
        </ApplicationsContent>
      </Table>
    </Paper>
  );
};

export default ClassApplications;
