import styled from 'styled-components';
import * as bp from 'breakpoints';

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.334em;
  font-weight: 300;
  margin: 0;
  ${props => props.hide && 'display: none;'}
  ${props => props.area && `grid-area: ${props.area};`}
  ${props => props.padding && `padding: ${props.padding};`}
`;

Paragraph.Menu = styled.p`
  padding-left: 16.5px;
  font-size: 0.67rem;
  line-height: inherit;
  text-transform: uppercase;
  align-self: center;
  color: var(--font-color-primary, #333333);
  @media (min-width: ${bp.PAD_S}) {
    color: var(--font-color-secondary, #ffffff);
    display: none;
  }
  ${props => props.hide && 'display: none;'}
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;

export default Paragraph;
