import React from 'react';
import tracker from 'tracker';
import { Button } from 'atoms';
import { Teaser } from 'molecules';
import { Aside, Main } from 'templates';
import { Header, Heading, Paragraph } from 'Typography';
import { Background, RoadmapHeroImage } from '../CareerRoadmap/CareerRoadmap';
import AdArticle from './AdArticle';

const Template = ({
  t,
  header = 'hi',
  subheader = 'baby',
  primaryTeaser = {
    header: 'primaryteaesrheader',
    text: 'primaryteasertext',
    link: {
      text: 'buttontext',
      href: 'wp.pl',
    },
  },
}) => (
  <Background>
    <RoadmapHeroImage />
    <Main>
      <Header withHeroImage>
        <Heading css="max-height: unset; margin-bottom: 0.5rem !important;" type="h1">
          {header}
        </Heading>
        <Heading type="h4">{subheader}</Heading>
      </Header>
      <AdArticle>
        <Teaser primary elevation="2" css="padding: 1rem;">
          <Paragraph>{primaryTeaser.text}</Paragraph>
          <Button
            primary
            as="a"
            target="_blank"
            href={primaryTeaser.link.href}
            css={`
              display: grid;
              width: max-content;
              align-content: center;
            `}
            onClick={() => tracker.trackEvent(`Clicks - P 3.1 - ${primaryTeaser.gaEvent}`)}
            elevation="2"
          >
            <Paragraph>{primaryTeaser.link.text}</Paragraph>
          </Button>
        </Teaser>
      </AdArticle>
      <Aside>
        <Teaser secondary elevation="1" css="padding: 1rem;">
          <Heading type="h5" css="color: white;">
            {t('ads_secondaryteaser_header')}
          </Heading>
          <Paragraph>{t('ads_secondaryteaser_text')}</Paragraph>
          <Button
            secondary
            as="a"
            target="_blank"
            href="https://www.yousty.ch/de-CH/broschuere-bestellen"
            css={`
              display: grid;
              width: max-content;
              align-content: center;
            `}
            elevation="2"
          >
            {t('ads_secondaryteaser_button_text')}
          </Button>
        </Teaser>
      </Aside>
    </Main>
  </Background>
);

export default Template;
