import React, { useRef } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { PAD_S } from 'breakpoints';
import { EditClassModal, DeleteClassModal, InviteTeacherModal, InfoModal } from 'templates';
import { connect } from 'react-redux';
import { toggleModal } from 'actions';

const GlobalBody = createGlobalStyle`
  ${props =>
    props.show &&
    css`
      body {
        overflow: hidden;
      }
    `}
`;

const Backdrop = css`
  content: '';
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: var(--color-lighter);
  filter: opacity(50%);
  z-index: 5;
`;

const Container = styled.div`
  display: ${props => (props.show ? 'initial' : 'none')};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  &:after {
    ${Backdrop}
  }
`;

const Content = styled.div`
  position: fixed;
  top: 14%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  max-width: 100%;
  max-height: 100%;
  z-index: 6;

  @media (min-width: ${PAD_S}) {
    width: 24.7rem;
  }
`;

export const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  grid-column-gap: 0.5rem;
  width: 100%;
  justify-items: center;
`;

export const GridRows = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1.33rem;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
`;

const Modal = ({ modal, toggleGlobalModal, ...args }) => {
  const { show, action } = modal;
  const node = useRef();

  const toggleLocalModal = e => {
    if (!node.current.contains(e.target)) {
      toggleGlobalModal(!show);
    }
  };

  const passedProps = {
    show,
    toggleLocalModal,
    node,
  };

  const modalData = {
    DeleteClass: {
      content: <DeleteClassModal {...passedProps} />,
    },
    EditClass: {
      content: <EditClassModal {...passedProps} />,
    },
    InviteTeacher: {
      content: <InviteTeacherModal {...passedProps} />,
    },
    Info: {
      content: <InfoModal {...passedProps} />,
    },
  };

  return (
    <Container show={show} onClick={toggleLocalModal} {...args}>
      <GlobalBody show={show} />
      <Content ref={node}>{action && modalData[action] && modalData[action].content}</Content>
    </Container>
  );
};

const mapStateToProps = state => ({
  modal: state.modalState,
});

const mapDispatchToProps = dispatch => ({
  toggleGlobalModal: newModalState => dispatch(toggleModal(newModalState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
