import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';

import tracker from 'tracker';

import { HeroImage, Section, Paper } from 'atoms';
import { SecondaryTeaser, ClassesDisplay } from 'organisms';

import { Main, ClassForm } from 'templates';
import { Heading, Header } from 'Typography';
import * as bp from 'breakpoints';

import { useTranslation } from 'react-i18next';
import { fetchClasses, addNewClass } from 'actions';
import { getUserData, getClassFormErrors } from 'selectors';
import { images } from './assets';

const Background = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 9rem 1.34rem 1.134rem 1.34rem;
  @media (min-width: ${bp.PAD_S}) {
    padding: 9rem 1.34rem 1.134rem 6rem;
  }
  @media (min-width: ${bp.PAD_L}) {
    padding-top: 19.8rem;
  }
`;

const ClassesMain = styled(Main)`
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-auto-flow: dense;
  @media (min-width: ${bp.PAD_L}) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
  }
  @media (min-width: ${bp.DESKTOP}) {
    grid-gap: 2rem;
    grid-template-columns: 2fr auto;
  }
`;

const Aside = styled.aside`
  grid-column: 1 / -1;
  @media (min-width: ${bp.PAD_L}) {
    grid-column: span 1 / -1;
  }
  @media (min-width: ${bp.DESKTOP}) {
    max-width: 23.07rem;
    width: 23.07rem;
    grid-row: 2 / span 2;
  }
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
`;

const ProfilePic = styled.img`
  border-radius: 50%;
  height: 100%;
  align-self: center;
  cursor: pointer;
  filter: drop-shadow(-0.5px 1px 2px rgba(0, 0, 0, 0.35));
`;

const FormWrapper = styled(Paper)`
  padding: 1.33rem 1.65rem;
  display: grid;
  grid-gap: 1.65rem;
`;

export const ClassesOverview = ({ addClass, loadClasses, classes, formErrors, user, ...args }) => {
  useEffect(() => {
    tracker.trackPageView('/klassen', 'Visits - P 4.0 - Klassen');
  }, []);

  useEffect(() => {
    loadClasses();
  }, []);
  const { t } = useTranslation();
  const { avatarSrc, firstName, lastName } = user.data.attributes;
  return (
    <Background {...args}>
      <HeroImage
        image={images.backgroundMedium}
        imageBig={images.backgroundBig}
        height="26.134rem"
      />
      <ClassesMain>
        <Header
          withHeroImage
          css={`
            letter-spacing: 0.02rem;
            white-space: pre-wrap;
            text-transform: capitalize;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 1rem;
            @media (min-width: ${bp.DEFAULT}) {
              margin-bottom: 2rem;
            }
          `}
        >
          <ProfilePic css="height: 4rem;" src={avatarSrc || 'https://picsum.photos/50/50'} />
          <Heading type="h1">{`${t('classes_header')}${firstName} ${lastName}`}</Heading>
        </Header>
        <ClassesDisplay
          css={`
            grid-column: 1 / -1;
            @media (min-width: ${bp.DESKTOP}) {
              grid-column: span 1;
            }
          `}
        />
        <Section
          grid
          css={`
            grid-column: 1 / -1;
            grid-auto-rows: min-content;
            @media (min-width: ${bp.PAD_L}) {
              grid-column: 1 / span 1;
            }
          `}
        >
          <FormWrapper primary elevation="1" {...args}>
            <Heading type="h5">{t('add_class')}</Heading>
            <ClassForm error={formErrors} onClassSubmit={addClass} />
          </FormWrapper>
        </Section>
        <Aside>
          <SecondaryTeaser
            css={`
              height: min-content;
            `}
            textContent={{
              header: t('classes_teaser_header'),
              text: t('classes_teaser_text'),
            }}
            link={{
              to: 'https://youtu.be/MzuV6M3rqJk',
              text: t('classes_teaser_button'),
            }}
            gaEvent="Clicks - Teaser - Bewerbungsfoto"
          />
        </Aside>
      </ClassesMain>
    </Background>
  );
};

const mapStateToProps = state => ({
  user: getUserData(state),
  formErrors: getClassFormErrors(state),
  classes: state.classesState.classes,
});

const mapDispatchToProps = dispatch => ({
  loadClasses: () => dispatch(fetchClasses()),
  addClass: classData => dispatch(addNewClass(classData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassesOverview);
