import React from 'react';
import styled from 'styled-components';

import {
  Card as MuiCard,
  Typography,
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
} from '@material-ui/core';

const Card = styled(MuiCard).attrs()`
  &.MuiCard-root {
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    border-left: 6px solid var(--color-primary);
    padding: 1.3rem;
    display: flex;
  }
`;

const CardHeader = styled(MuiCardHeader).attrs({
  titleTypographyProps: {
    variant: 'body1',
  },
  subheaderTypographyProps: {
    variant: 'h3',
  },
})`
  &.MuiCardHeader-root {
    padding: 0;
  }
  & .MuiCardHeader-content {
    text-align: center;
    padding: 0;
  }
  & .MuiCardHeader-subheader {
    font-size: 2em;
    padding: 0;
    line-height: 1.08em;
    font-weight: 800;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
`;

const CardContent = styled(MuiCardContent)`
  &.MuiCardContent-root {
    &:last-child {
      padding-bottom: 16px;
    }
  }
`;

const TypeText = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 800;
  }
`;

const Activity = ({ activity: { day, month, studentName, type } }) => {
  return (
    <Card>
      <CardHeader title={month} subheader={day} />
      <CardContent>
        <Typography>{studentName}</Typography>
        <TypeText>{type}</TypeText>
      </CardContent>
    </Card>
  );
};

export default Activity;
