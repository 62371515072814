import * as bp from 'breakpoints';
import { Card } from 'molecules';
import { Action } from 'organisms';
import React from 'react';
import styled from 'styled-components';
import { Heading } from 'Typography';

const CardGrid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 0.5rem;
  width: 100%;

  @media (min-width: ${bp.DESKTOP}) {
    grid-row-gap: 1rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  color: var(--font-color-primary);

  span {
    border-bottom: 1px solid var(--font-color--primary);
  }
`;

const ActionsCard = ({ title, subtitle, classId, actions = [], ...args }) => {
  return (
    <Card primary {...args}>
      <CardGrid>
        <div>
          <Heading type="h5">{title}</Heading>
          {subtitle && (
            <Heading type="h6" css="margin: 0; color: var(--font-color-faded)">
              {subtitle}
            </Heading>
          )}
        </div>
        <Content>
          {actions.map(action => (
            <Action
              key={action.text}
              text={action.text}
              onClick={() => action.onClick(classId)}
              state={action.state}
            />
          ))}
        </Content>
      </CardGrid>
    </Card>
  );
};

export default ActionsCard;
