/* eslint-disable prettier/prettier */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { css } from 'styled-components';
import { DropdownHeader, DropdownOptions, DropdownOption, Dropdown } from 'organisms';
import { Link } from 'atoms';
import { UserAvatar } from 'molecules';
import * as bp from 'breakpoints';

export const UserMenu = ({ user, onLogout }) => {
  const { t } = useTranslation();
  const isMatch = useRouteMatch({ path: `/${t('class')}/:id`, exact: false });
  const isLoginDark = !!isMatch;

  return (
    <Dropdown
      css={`
        text-transform: capitalize;
      `}
    >
      <DropdownHeader
        css={css`
          color: var(--font-color-primary, #333333);
          ${!isLoginDark &&
          css`
            @media (min-width: ${bp.PAD_S}) {
              color: var(--font-color-secondary, #ffffff);
              text-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
            }
          `}
        `}
      >
        <UserAvatar src={user.data.attributes.avatarSrc || 'https://picsum.photos/50/50'} />
        {`${user.data.attributes.firstName} ${user.data.attributes.lastName[0]}.`}
      </DropdownHeader>
      <DropdownOptions css="margin-top: 3rem;">
        <DropdownOption>
          <Link href="https://yousty.ch/mein_profil">{t('my_profile')}</Link>
        </DropdownOption>
        <DropdownOption>
          <Link href="https://yousty.ch/einstellungen">{t('settings')}</Link>
        </DropdownOption>
        <span
          css={`
            content: '';
            display: block;
            margin: 0.3rem 1rem 0.34rem 1rem;
            box-sizing: border-box;
            border-bottom: 1px solid var(--font-color-faded, #bbbec1);
          `}
        />
        <DropdownOption onClick={() => onLogout()}>{t('logout')}</DropdownOption>
      </DropdownOptions>
    </Dropdown>
  );
};

export default UserMenu;
