import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { DeleteStudentModal } from 'templates';
import { TrashIconButton } from 'atoms';
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  CardHeader as MuiCardHeader,
  Link as MuiLink,
} from '@material-ui/core';
import { useRemindStudent } from 'utils';

const Card = styled(MuiCard)`
  &&& {
    height: 100%;
    color: #fff;
    background-color: var(--color-primary);
  }
`;

const CardHeader = styled(MuiCardHeader)`
  & .MuiCardHeader-title {
    font-weight: 800;
  }
`;

const CardContent = styled(MuiCardContent)`
  &.MuiCardContent-root {
    margin-top: auto;
  }
`;

const CardActions = styled(MuiCardActions)`
  &.MuiCardActions-root {
    margin-top: 0;
  }
`;

const Link = styled(MuiLink).attrs({ color: 'inherit' })`
  word-break: break-all;
`;

const Button = styled(MuiButton).attrs({ variant: 'contained' })`
  ${({ theme }) => css`
    &.MuiButton-contained {
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.common.white};
    }
  `}
`;

export const PendingStudentCard = ({ student }) => {
  const { confirmed, firstName, lastName, email, id } = student;
  const [{ isSuccess, isError }, remindStudent] = useRemindStudent();
  const { t } = useTranslation();
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [alertState, setAlertState] = React.useState('');

  React.useEffect(() => {
    if (isSuccess) {
      setAlertState('success');
    }
    return () => {
      setAlertState('');
    };
  }, [isSuccess]);
  React.useEffect(() => {
    if (isError) {
      setAlertState('error');
    }
    return () => {
      setAlertState('');
    };
  }, [isError]);

  const handleSnackbarClose = () => {
    setAlertState('');
  };

  return (
    <Card>
      <CardHeader
        action={<TrashIconButton color="inherit" onClick={() => setDeleteModalOpen(true)} />}
        title={`${firstName} ${lastName}`}
        titleTypographyProps={{ gutterBottom: false, color: 'inherit', variant: 'body1' }}
        subheader={!confirmed && t('pending_student_status')}
        subheaderTypographyProps={{ color: 'inherit', variant: 'body2' }}
      />
      <CardContent>
        <Link color="inherit" href={`mailto:${email}`}>
          {email}
        </Link>
      </CardContent>
      <CardActions>
        <Button onClick={() => remindStudent(student.id)}>{t('pending_student_button')}</Button>
      </CardActions>
      <DeleteStudentModal
        isOpen={isDeleteModalOpen}
        memberId={id}
        handleClose={() => setDeleteModalOpen(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(alertState)}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={alertState}>
          {t('invite_student_reminder_email', { name: `${student.firstName} ${student.lastName}` })}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default PendingStudentCard;
