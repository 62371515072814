import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import * as bp from 'breakpoints';
import { useTranslation } from 'react-i18next';
import { Heading, Paragraph } from 'Typography';
import { CloseIcon } from 'atoms';

import logo from '../assets/images/youstyLogoWithText.svg';
import image from './pages/Entry/assets/images/backgroundBig.jpg';

const SetStaticSize = createGlobalStyle`
  html {
    @media (min-width: ${bp.XL}) {
      font-size: 15px !important;
    }
  }
`;

const LocalHeroImage = styled.div`
  display: block;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  height: 22.25rem;
  width: 100%;
  z-index: 1;
  @media (min-width: ${bp.PAD_L}) {
    width: 383px;
    height: 100%;
  }
  @media (min-width: ${bp.DESKTOP}) {
    width: 348px;
  }
  &::before {
    background-color: var(--color-primary);
    content: '';
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.7;
  }
`;

const Background = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  @media (min-width: ${bp.DESKTOP}) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsla(0, 100%, 100%, 1);
  }
`;

const Main = styled.main`
  position: relative;
  display: grid;
  box-sizing: border-box;
  grid-template-rows: 22.25rem 1fr;
  grid-gap: 2.27rem 0;
  @media (min-width: ${bp.PAD_L}) {
    padding-top: 3.2rem;
    height: 100vh;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    grid-template-columns: 383px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 2.67rem 0;
  }
  @media (min-width: ${bp.DESKTOP}) {
    background-color: var(--color-background);
    grid-template-columns: 348px 1fr;
    width: 953px;
    height: 600px;
    box-shadow: var(--elevation-1);
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  padding: 4.5rem 4.5rem 3.75rem 4.5rem;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
  z-index: 2;
  & > * {
    max-width: 360px;
    align-self: center;
  }
  @media (min-width: ${bp.PAD_L}) {
    padding: 0 1.9rem 0 1.9rem;
    text-align: left;
    white-space: pre-line;
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    & > * {
      max-width: none;
      align-self: start;
    }
  }
`;

const Article = styled.article`
  display: grid;
  grid-auto-rows: min-content;
  padding: 0 1.67rem;
  grid-gap: 2.2rem;
  @media (min-width: ${bp.PAD_L}) {
    padding: 1.5rem 5.8rem;
    grid-column: 2/ -1;
    grid-row: 2/ -1;
  }
`;

const AuthLayout = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Background>
      <SetStaticSize />
      <Main>
        <LocalHeroImage />
        <Header>
          <Heading type="h1" css="color: white; font-weight: bold;">
            {t('login_header_title')}
          </Heading>
          <Paragraph>{t('login_header_text')}</Paragraph>
        </Header>
        <div
          css={`
            display: flex;
            justify-content: space-between;
            align-items: center;
            grid-column: 1 / -1;
            padding: 0 1.67rem;
            @media (min-width: ${bp.PAD_L}) {
              grid-column-start: -2;
              grid-row: 1 / 2;
              padding: 0 5.8rem;
            }
          `}
        >
          <a href="https://yousty.ch">
            <img src={logo} alt="Yousty - here with you" css="height: 2.67rem" />
          </a>
          <a href="/">
            <CloseIcon />
          </a>
        </div>
        <Article>{children}</Article>
      </Main>
    </Background>
  );
};

export default AuthLayout;
