import styled from 'styled-components';
import { PAD_S } from 'breakpoints';

const Form = styled.form`
  ${props => props.area && `grid-area: ${props.area};`};
  ${props =>
    props.hideMobile &&
    `
  @media (max-width: ${PAD_S}) {
      display: none;
    }
  `}
  ${props =>
    props.elevation && `box-shadow: var(--elevation-${props.elevation}, var(--elevation-default));`}
  ${props => props.grid && 'display:grid;'}
  ${props => props.gridColumns && `grid-template-columns: ${props.gridColumns};`}
  ${props => props.gridRows && `grid-template-rows: ${props.gridRows};`}
  ${props => props.gridColumn && `grid-column: ${props.gridColumn};`}
  ${props => props.gridRow && `grid-row: ${props.gridRow};`}
  ${props => props.gridGap && `grid-gap: ${props.gridGap};`}
  ${props => props.padding && `padding: ${props.padding};`}
`;

export default Form;
