import React from 'react';
import styled from 'styled-components';
import { MainTeaser, SecondaryTeaser } from 'organisms';
import { Heading } from 'Typography';
import * as bp from 'breakpoints';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getUserLoginStatus } from 'selectors';

const Main = styled.main`
  display: grid;
  grid-template-areas:
    'header'
    'main-teaser'
    'login-teaser'
    'teaser'
    'teaser2';
  grid-gap: 1rem;
  max-width: 1068px;
  margin: 0 auto;
  @media (min-width: ${bp.DEFAULT}) {
    margin-top: auto;
  }
  @media (min-width: ${bp.PAD_S}) and (max-width: ${bp.PAD_L}) {
    grid-template-areas:
      'header header'
      'main-teaser login-teaser'
      'teaser teaser2';
  }
  @media (min-width: ${bp.PAD_L}) {
    align-self: center;
    grid-template-areas:
      'header header header'
      'main-teaser login-teaser teaser'
      'main-teaser login-teaser teaser2';
  }
  @media (min-width: ${bp.XL}) {
    max-width: 1240px;
  }
`;

const EntryHeader = styled(Heading).attrs({ type: 'h1' })`
  white-space: pre-wrap;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--font-color-secondary);
  @media (min-width: ${bp.DEFAULT}) {
    font-size: 3.37rem;
    max-height: 3.37em;
  }
`;

const EntryContent = ({ images, loggedIn, ...args }) => {
  const { t } = useTranslation();
  return (
    <Main {...args}>
      <EntryHeader area="header">{t('entry_header')}</EntryHeader>
      <MainTeaser
        area="main-teaser"
        image={{ src: images.roadmap, alt: 'Your classroom' }}
        textContent={{
          header: t('entry_teaser1_header'),
          inner: t('entry_teaser1_content'),
        }}
        link={{ to: t('roadmap'), text: t('entry_teaser1_button') }}
        gaEvent="Clicks - P 2.0 - Berufswahl-Fahrplan"
      />
      <MainTeaser
        area="login-teaser"
        image={{ src: images.classroom, alt: 'Your classroom' }}
        textContent={{
          header: t('entry_teaser2_header'),
          inner: t('entry_teaser2_content'),
        }}
        link={{
          to: t('classes'),
          text: loggedIn ? t('entry_teaser2_button_alt') : t('entry_teaser2_button'),
        }}
        gaEvent="Clicks - P 2.0 - Virtuelles Klassenzimmer"
      />
      <SecondaryTeaser
        area="teaser"
        textContent={{
          header: t('entry_sideTeaser1_header'),
          text: t('entry_sideTeaser1_content'),
        }}
        link={{
          to: 'https://www.yousty.ch/de-CH/broschuere-bestellen',
          text: t('entry_sideTeaser1_button'),
        }}
        gaEvent="Clicks - P 2.0 - Broschüre"
        newTab
      />
      <SecondaryTeaser
        area="teaser2"
        textContent={{
          header: t('entry_sideTeaser2_header'),
          text: t('entry_sideTeaser2_content'),
        }}
        link={{
          to: 'https://www.yousty.ch/de-CH/bewerbungsfoto',
          text: t('entry_sideTeaser2_button'),
        }}
        gaEvent="Clicks - P 2.0 - Bewerbungsfoto"
        newTab
      />
    </Main>
  );
};

const mapStateToProps = state => ({
  loggedIn: getUserLoginStatus(state),
});

export default connect(mapStateToProps)(EntryContent);
