/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DeleteStudentModal } from 'templates';
import { TrashIconButton } from 'atoms';
import { useTranslation } from 'react-i18next';
import {
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardHeader,
  CardMedia as MuiCardMedia,
  IconButton as MuiIconButton,
  Tooltip,
} from '@material-ui/core';
import applied from './assets/applied.svg';
import confirmed from './assets/confirmed.svg';
import jobCheck from './assets/jobCheck.svg';
import jobFinder from './assets/jobFinder.svg';

const borderColors = {
  success: 'var(--color-green)',
  warning: 'var(--color-orange)',
  danger: 'var(--font-color-error)',
};

const getBorderColor = completion => {
  if (completion > 0 && completion <= 39) return borderColors.danger;
  if (completion > 39 && completion <= 79) return borderColors.warning;
  if (completion > 79 && completion <= 100) return borderColors.success;
  return borderColors.danger;
};

const PercentageLabel = styled.div`
  position: absolute;
  top: 0.667rem;
  left: calc(0.667rem - 2px);
  ${({ completion }) => css`
    font-size: ${completion === 100 ? '1.667rem' : '1rem'};
    background-color: ${getBorderColor(completion)};
  `}
  font-weight: bold;
  text-align: center;
  line-height: 2.667rem;
  width: 2.667rem;
  color: var(--color-secondary);
  border-radius: 50%;
`;

const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    padding: 6px;
  }
`;

const RouterLinkNoUnderline = styled(RouterLink)`
  text-decoration: none;
`;

const IconDiv = styled.div`
  ${({ theme, active }) => css`
    border-radius: 50%;
    box-shadow: var(--elevation-1);
    padding: 0.7rem;
    width: 1.8rem;
    height: 1.8rem;
    background-color: ${active ? 'var(--color-primary)' : 'var(--color-lighter)'};

    ${theme.breakpoints.only('sm')} {
      padding: 0.5rem;
    }
  `}
`;

const ActionIcon = ({
  image,
  tooltip,
  tooltipDisabled = '',
  active = false,
  iconButtonProps = {},
}) => {
  return (
    <Tooltip
      title={active ? tooltip : tooltipDisabled}
      aria-label={tooltip}
      disableFocusListener={!active && !tooltipDisabled}
      disableHoverListener={!active && !tooltipDisabled}
      disableTouchListener={!active && !tooltipDisabled}
    >
      <IconButton disabled={!active} {...iconButtonProps}>
        <IconDiv active={active}>
          <img
            css={`
              height: 100%;
              width: 100%;
            `}
            src={image}
            alt="Status confirmation"
          />
        </IconDiv>
      </IconButton>
    </Tooltip>
  );
};

const Card = styled(MuiCard)`
  &.MuiCard-root {
    display: flex;
    height: 100%;
    min-height: 200px;
  }
`;

const ContentWrapper = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
`;

const CardMedia = styled(MuiCardMedia)`
  &.MuiCardMedia-root {
    width: 35%;
    position: relative;
  }
`;

const CardActions = styled(MuiCardActions).attrs({ disableSpacing: true })`
  &.MuiCardActions-root {
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    justify-content: space-between;
  }
`;

export const StudentCard = ({ student }) => {
  const {
    id: memberId,
    firstName,
    lastName,
    profileCompletion,
    avatarSrc,
    jobFinderDone,
    jobCheckDone,
    firstApplicationSent,
    confirmedJob,
    jobCheckCertificateUrl,
    jobFinderPdfUrl,
  } = student;
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const borderColor = getBorderColor(profileCompletion);
  return (
    <Card>
      <CardMedia
        image={avatarSrc}
        title={firstName}
        css={`
          border-left: 3px solid ${borderColor};
        `}
      >
        <Tooltip title={t('tooltipstatus')} aria-label={t('tooltipstatus')}>
          <PercentageLabel completion={profileCompletion}>
            {profileCompletion === 100 ? '\uD83D\uDCAF' : `${profileCompletion}%`}
          </PercentageLabel>
        </Tooltip>
      </CardMedia>
      <ContentWrapper>
        <CardHeader
          title={`${firstName} ${lastName}`}
          titleTypographyProps={{
            component: RouterLinkNoUnderline,
            to: `/${t('class')}/${id}?studentId=${memberId}`,
            color: 'inherit',
          }}
          subheader={t('view_applications')}
          subheaderTypographyProps={{
            component: RouterLink,
            to: `/${t('class')}/${id}?studentId=${memberId}`,
            color: 'inherit',
          }}
          action={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <TrashIconButton
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            />
          }
        />
        <CardActions>
          <ActionIcon
            tooltip={t('tooltipjobFinder')}
            tooltipDisabled={t('tooltipjobFinder_disabled')}
            active={jobFinderDone}
            image={jobFinder}
            iconButtonProps={{
              as: jobFinderPdfUrl ? 'a' : 'div',
              href: `${jobFinderPdfUrl}`,
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
          <ActionIcon
            tooltip={t('tooltipjobCheck')}
            tooltipDisabled={t('tooltipjobCheck_disabled')}
            active={jobCheckDone}
            image={jobCheck}
            iconButtonProps={{
              as: jobCheckCertificateUrl ? 'a' : 'div',
              href: `${jobCheckCertificateUrl}`,
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
          <ActionIcon
            tooltip={t('tooltipapplied')}
            tooltipDisabled={t('tooltipapplied_disabled')}
            active={firstApplicationSent}
            image={applied}
            iconButtonProps={{ as: 'div' }}
          />
          <ActionIcon
            tooltip={t('tooltipconfirmed')}
            tooltipDisabled={t('tooltipconfirmed_disabled')}
            active={confirmedJob}
            image={confirmed}
            iconButtonProps={{ as: 'div' }}
          />
        </CardActions>
      </ContentWrapper>

      <DeleteStudentModal
        isOpen={isDeleteModalOpen}
        memberId={memberId}
        handleClose={() => setDeleteModalOpen(false)}
      />
    </Card>
  );
};

export default StudentCard;
