/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import { fetchUser, userLogout } from 'actions';
import { getToken, saveToken } from 'api';
import ConfirmRegistration from 'components/ConfirmRegistration';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { getConfirmationBarStatus, getUserData } from 'selectors';
import { Modal, Navigation, TimeoutWarning, Routes } from 'templates';
import { GlobalStyle } from './ui/yousty';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserData);
  const { signedIn } = user;
  const showConfirmationBar = useSelector(getConfirmationBarStatus);

  const [cookies] = useCookies(['yousty_token']);
  useEffect(() => {
    if (!signedIn) {
      if (cookies.yousty_token) {
        saveToken(cookies.yousty_token);
      }
      dispatch(fetchUser());
    }
    if (signedIn && !cookies.yousty_token) {
      dispatch(userLogout());
    }
    if (signedIn && cookies.yousty_token !== getToken()) {
      saveToken(cookies.yousty_token);
      dispatch(fetchUser());
    }
  }, []);
  return (
    <Router>
      <Helmet>
        <title>Berufsorientierung in der Schule - Berufswahl-Radar von Yousty</title>
        <meta
          name="description"
          content="Als Lehrer die Schulklasse bei der Berufswahl optimal begleiten. Helfen Sie Ihren Schüler/innen, die passende Schnupperlehre und Lehrstelle zu finden."
        />
        <meta
          name="keywords"
          content="Berufswahl, Berufswahl-Radar, Berufswahl, Schnupperlehren, Bewerbungsphase, Vorstellungsgespräch, Berufsmessen, Lehrstellen"
        />
      </Helmet>
      <GlobalStyle />
      {showConfirmationBar && <ConfirmRegistration />}
      <Navigation />
      <Routes />
      <Modal />
      <TimeoutWarning />
    </Router>
  );
};

export default App;
