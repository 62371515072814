import React, { useMemo } from 'react';
import { Card } from 'molecules';
import { Paragraph } from 'Typography';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GridColumns, GridRows } from './Modal';

const InfoModal = ({ modalFields }) => {
  const { t, i18n } = useTranslation();
  const { primary = false, heading, textKey = '' } = modalFields;
  const text = useMemo(() => (i18n.exists(textKey) ? t(textKey) : textKey), [t, i18n, textKey]);

  return (
    <Card secondary={!primary} primary={primary}>
      <GridColumns>
        <GridRows>
          {heading && <strong>{heading}</strong>}
          <Paragraph>{text}</Paragraph>
        </GridRows>
      </GridColumns>
    </Card>
  );
};

const mapStateToProps = state => ({
  modalFields: state.modalState.Info,
});

export default connect(mapStateToProps)(InfoModal);
