/* eslint-disable camelcase */
import { put, takeLatest, select } from 'redux-saga/effects';
import tracker from 'tracker';
import { classesActions, errorTypes } from '../rootTypes';
import { getUserId } from '../selectors';
import * as api from '../api';

export function* classFetcher() {
  try {
    yield put({ type: classesActions.fetchPending });
    const userId = yield select(getUserId);
    const token = api.getToken();
    const classes = yield api.userApi.get(`/teachers/${userId}/classrooms`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: classesActions.fetchSuccess,
      payload: classes.data.data,
    });
  } catch (err) {
    if (err.response.status === 404 || err.response.status === 403) {
      yield put({ type: classesActions.fetchError });
    } else {
      yield put({ type: errorTypes.api, payload: err.response });
    }
  }
}

export function* addClassSaga({ payload }) {
  try {
    tracker.trackEvent('Clicks - Klasse erstellen');
    yield put({
      type: classesActions.addClassPending,
    });
    const userId = yield select(getUserId);
    const token = api.getToken();
    const { name, state, schoolLevel, graduationYear } = payload;
    const requestData = {
      data: {
        type: 'classrooms',
        attributes: {
          name,
          state,
          schoolLevel,
          graduationYear,
        },
      },
    };
    const postRequest = yield api.userApi.post(`/teachers/${userId}/classrooms`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put({
      type: classesActions.addClassSuccess,
      payload: postRequest.data.data,
    });
  } catch (err) {
    yield put({
      type: classesActions.addClassError,
      payload: err.response.data,
    });
  }
}
// eslint-disable-next-line import/prefer-default-export
export function* classesSaga() {
  yield takeLatest(classesActions.fetchRequest, classFetcher);
  yield takeLatest(classesActions.addClassRequest, addClassSaga);
}
