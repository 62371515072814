import { navbarActions } from '../rootTypes';

export const DEFAULT_STATE = {
  navbar_open: false,
  notFound: false,
};

const navState = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case navbarActions.toggle:
      return { ...state, navbar_open: !state.navbar_open };
    case navbarActions.toggleNotFound:
      return { ...state, notFound: !state.notFound };
    default:
      return state;
  }
};

export default navState;
