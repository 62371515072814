import React from 'react';
import normalize from 'jsonapi-normalizer';
import * as api from 'api';
import { createEntityAdapter } from '@reduxjs/toolkit';
import compareDates from './compareDates';

const activitiesAdapter = createEntityAdapter({
  sortComparer: compareDates,
});

const useFetchClassActivities = () => {
  const [classId, setClassId] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [activities, setApplications] = React.useState(activitiesAdapter.getInitialState());
  React.useEffect(() => {
    const fetchAPplications = async () => {
      setIsSuccess(false);
      setIsPending(true);
      setIsError(false);
      try {
        const token = api.getToken();
        const activitiesResponse = await api.userApi.get(`/classrooms/${classId}/activities`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const {
          entities: { classroomActivities },
        } = normalize(activitiesResponse.data);

        setApplications(activitiesAdapter.setAll(activities, classroomActivities));
        setIsSuccess(true);
      } catch (err) {
        setIsError(true);
      }
      setIsPending(false);
    };
    if (classId) {
      fetchAPplications();
    }
  }, [classId]);

  return [
    {
      isError,
      isPending,
      isSuccess,
      activities,
    },
    setClassId,
  ];
};
export default useFetchClassActivities;
