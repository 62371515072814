/* eslint-disable import/prefer-default-export */
import { cookie, domain } from 'api';
import { userActions } from '../rootTypes';

export const fetchUser = () => ({
  type: userActions.fetchRequest,
});

export const userLogout = () => {
  cookie.remove('yousty_token', { domain });
  localStorage.removeItem('yousty_api_token');
  localStorage.removeItem('youstycareerchoiceradarstate');
  return {
    type: userActions.logout,
  };
};
