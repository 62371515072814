import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { Segment } from 'atoms';
import { DESKTOP } from 'breakpoints';
import styled, { css } from 'styled-components';

const ColoredIcon = styled(FAIcon)`
  color: var(--color-primary);
  cursor: pointer;
`;

const InfoSegment = styled(Segment)`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 1.35rem;
  height: 100%;

  &:first-child,
  &:last-child {
    * {
      grid-column: initial;
    }
  }

  &:last-child {
    ${props =>
      !props.hidden
        ? css`
            padding-top: 1.15rem;
          `
        : css`
            padding-top: 0;
          `};

    border-top: none;
  }

  &:first-child {
    padding-bottom: 0.5rem;

    ${props =>
      !props.hidden
        ? css`
            padding-bottom: 0.5rem;
          `
        : css`
            padding-bottom: 0;
            border-bottom: none;
          `};
  }

  @media (min-width: ${DESKTOP}) {
    grid-row-gap: 1rem;
  }
`;

export { ColoredIcon, InfoSegment };
