import React from 'react';
import normalize from 'jsonapi-normalizer';
import * as api from 'api';
import { createEntityAdapter } from '@reduxjs/toolkit';
import compareDates from './compareDates';

const apprenticeshipsAdapter = createEntityAdapter({
  sortComparer: compareDates,
});

const useFetchClassApplications = () => {
  const [classId, setClassId] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [applications, setApplications] = React.useState(apprenticeshipsAdapter.getInitialState());
  React.useEffect(() => {
    const fetchAPplications = async () => {
      setIsSuccess(false);
      setIsPending(true);
      setIsError(false);
      try {
        const token = api.getToken();
        const applicationsResponse = await api.userApi.get(`/classrooms/${classId}/applications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const {
          entities: { applications: responseApplications, students },
        } = normalize(applicationsResponse.data);
        if (responseApplications) {
          const denormalised = Object.values(responseApplications).map(application => {
            const studentUser = students && students[application.user.id];
            return {
              ...application,
              user: { ...studentUser },
            };
          });
          setApplications(apprenticeshipsAdapter.setAll(applications, denormalised));
        } else {
          setApplications(apprenticeshipsAdapter.setAll(applications, {}));
        }
        setIsSuccess(true);
      } catch (err) {
        setIsError(true);
      }
      setIsPending(false);
    };
    if (classId) {
      fetchAPplications();
    }
  }, [classId]);

  return [
    {
      isError,
      isPending,
      isSuccess,
      applications,
    },
    setClassId,
  ];
};
export default useFetchClassApplications;
