import styled from 'styled-components';
import { Article } from 'atoms';
import { DESKTOP } from 'breakpoints';

const AdArticle = styled(Article)`
  grid-column: 1/-1;
  display: grid;
  grid-gap: 1.34rem;
  @media (min-width: ${DESKTOP}) {
    grid-column: span 2;
    grid-auto-rows: min-content;
  }
`;

export default AdArticle;
