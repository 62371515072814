import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import tracker from 'tracker';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'Typography';
import { PAD_S } from 'breakpoints';
import { toggleNavbar } from 'actions';
import { Hamburger } from 'molecules';
import { getNavState } from 'selectors';
import * as img from './assets/images';
import SidebarLink from './SidebarLink';

const WhiteParagraph = styled(Paragraph)`
  color: var(--font-color-secondary);
`;

export const Sidebar = styled.div`
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  ${props => props.unconfirmed && 'top: 50px;'}
  left: 0;
  background-color: var(--color-primary);
  transition: 150ms ease-out;
  width: 0;
  box-shadow: 3px 0 6px var(--color-tertiary, black);

  @media (min-width: ${PAD_S}) {
    width: 4.67rem;
  }

  ${props =>
    props.expand &&
    css`
      @media (max-width: ${PAD_S}) {
        width: 20rem;
      }

      @media (min-width: ${PAD_S}) {
        width: 20rem;
      }
    `};

  ${props =>
    props.textVisible &&
    css`
      ${WhiteParagraph} {
        display: none;
      }
    `};

  svg {
    line-height: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 auto;
  }
`;

Sidebar.Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  padding-top: 9rem;
  overflow-x: hidden;

  @media (min-height: 600px) {
    height: calc(100vh - 6rem);
  }
`;

Sidebar.Link = styled.div`
  width: 100%;

  a {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: flex-start;
    grid-gap: 2rem;
    align-items: center;
    margin: 1rem 0;
    padding: 0 1.5rem;
    color: $var(--font-color-secondary);
    text-decoration: none;
    width: 100%;
    line-height: 1.6rem;
    white-space: nowrap;

    img {
      width: 1.6rem;
      height: 1.6rem;
    }

    &.active {
      border-right: 2px solid white;
      box-sizing: border-box;
    }
  }
`;

const HamburgerContainer = styled.div`
  display: inherit;

  ${props => props.hidden && 'visibility: hidden;'}
`;

const AuthLink = ({ link }) => {
  const { t } = useTranslation();
  const isUserSignedIn = useSelector(state => state.userState.signedIn);
  const isException = !isUserSignedIn && (link === 'classes' || link === 'roadmap');

  const signedInLinks = {
    roadmap: `/${t('roadmap')}`,
    classes: `/${t('classes')}`,
    presentations: `/${t('presentations')}`,
    conventions: `/${t('conventions')}`,
    finder: `/${t('finder')}`,
    events: `/${t('events')}`,
  };

  const signedOutLinks = {
    roadmap: `/${t('roadmap')}`,
    classes: '/auth/login',
    presentations: 'https://www.yousty.ch/de-CH/broschuere-bestellen',
    conventions: 'https://www.yousty.ch/de-CH/bewerbungsfoto',
    finder: 'https://berufs-finder.yousty.ch/',
    events: 'https://www.yousty.ch/de-CH/berufswahl-anlaesse',
  };

  const linksData = {
    roadmap: {
      alt: "Your students' roadmap.",
      img: 'roadmap',
      text: t('sidebar_roadmap_link'),
    },
    classes: {
      alt: 'Go to your classes',
      img: 'classes',
      text: t('sidebar_classes_link'),
    },
    presentations: {
      alt: 'Go to your presentations',
      img: 'tipps',
      text: t('sidebar_presentations_link'),
    },
    conventions: {
      alt: 'Go to your classes',
      img: 'convention',
      text: t('sidebar_conventions_link'),
    },
    finder: {
      alt: 'Go to your classes',
      img: 'user',
      text: t('sidebar_finder_link'),
    },
    events: {
      alt: 'Go to your classes',
      img: 'calendar',
      text: t('sidebar_choice_link'),
    },
  };

  if (isException) {
    return (
      <SidebarLink
        url={signedOutLinks[link]}
        alt={linksData[link].alt}
        img={img[linksData[link].img]}
        text={linksData[link].text}
      />
    );
  }

  if (isUserSignedIn) {
    return (
      <SidebarLink
        url={signedInLinks[link]}
        alt={linksData[link].alt}
        img={img[linksData[link].img]}
        text={linksData[link].text}
      />
    );
  }

  return (
    <a href={signedOutLinks[link]} target="_blank" rel="noopener noreferrer">
      <img src={img[linksData[link].img]} alt={linksData[link].alt} />
      <WhiteParagraph>{linksData[link].text}</WhiteParagraph>
    </a>
  );
};

const Navigation = ({ ...args }) => {
  const navbarVisible = useSelector(getNavState);
  const dispatch = useDispatch();
  const handleLinkClick = () => {
    if (navbarVisible) dispatch(toggleNavbar());
  };

  return (
    <Sidebar expand={navbarVisible} {...args}>
      <div
        css={css`
          @media (max-width: ${PAD_S}) {
            display: initial;
          }
          @media (min-width: ${PAD_S}) {
            display: none;
          }
        `}
      >
        <HamburgerContainer hidden={!navbarVisible}>
          <Hamburger />
        </HamburgerContainer>
      </div>
      <div
        css={css`
          @media (max-width: ${PAD_S}) {
            display: none;
          }
          @media (min-width: ${PAD_S}) {
            display: initial;
          }
        `}
      >
        <Hamburger />
      </div>
      <Sidebar.Links>
        <Sidebar.Link
          onClick={() => {
            tracker.trackEvent('Clicks - Nav - Berufswahl-Fahrplan');
            handleLinkClick();
          }}
        >
          <AuthLink link="roadmap" />
        </Sidebar.Link>
        <Sidebar.Link
          onClick={() => {
            tracker.trackEvent('Clicks - Nav - Klassenübersicht');
            handleLinkClick();
          }}
        >
          <AuthLink link="classes" />
        </Sidebar.Link>
        <Sidebar.Link
          onClick={() => {
            tracker.trackEvent('Clicks - Nav - Videos & Vorlagen');
            handleLinkClick();
          }}
        >
          <AuthLink link="presentations" />
        </Sidebar.Link>
        <Sidebar.Link
          onClick={() => {
            tracker.trackEvent('Clicks - Nav - Berufsmessen');
            handleLinkClick();
          }}
        >
          <AuthLink link="conventions" />
        </Sidebar.Link>
        <Sidebar.Link
          onClick={() => {
            tracker.trackEvent('Clicks - Nav - Berufs-finder');
            handleLinkClick();
          }}
        >
          <AuthLink link="finder" />
        </Sidebar.Link>
        <Sidebar.Link
          onClick={() => {
            tracker.trackEvent('Clicks - Nav - Berufswahl-Anlässe');
            handleLinkClick();
          }}
        >
          <AuthLink link="events" />
        </Sidebar.Link>
        <Sidebar.Link
          css={`
            margin-top: auto;
          `}
        >
          <a href="https://www.yousty.ch/de-CH/">
            <img src={img.logo} alt="Go back to yousty.ch" />
          </a>
        </Sidebar.Link>
      </Sidebar.Links>
    </Sidebar>
  );
};

export default Navigation;
