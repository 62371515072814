import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Link } from '@material-ui/core';
import { getStudentState } from 'selectors';

const StyledFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-secondary);
  box-shadow: var(--elevation-1);
`;

export const Footer = ({ student }) => {
  const { data } = student;
  const { t } = useTranslation();
  if (data) {
    const { profileUrl } = data;
    return (
      <StyledFooter>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          css={`
            &&& {
              color: var(--font-color-secondary);
              width: 100%;
            }
          `}
          href={profileUrl}
        >
          {t('check_recruitment_profile')}
        </Button>
      </StyledFooter>
    );
  }
  return null;
};

const mapStateToProps = state => ({ student: getStudentState(state) });

export default connect(mapStateToProps, null)(Footer);
