import React from 'react';
import styled, { css } from 'styled-components';
import { Paragraph } from 'Typography';

const Option = styled(Paragraph)`
  padding: 0 1rem;
  padding: ${props => props.small && '.5rem .25rem'};
  line-height: 1.67rem;
  cursor: pointer;
  user-select: none;
  ${props => props.active && 'font-weight: bold'};
  :hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
  ${props =>
    props.white &&
    css`
      background: var(--color-secondary);
    `}
`;

const DropdownOption = ({ turnInvisible, onOptionClick, active, visible, ...args }) => {
  const optionClick = (e = null) => {
    if (onOptionClick) onOptionClick(e);
    turnInvisible();
  };

  return <Option onClick={e => optionClick(e)} active={active} {...args} />;
};

export default DropdownOption;
