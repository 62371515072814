import React from 'react';
import ContentLoader from 'react-content-loader';

const detectBrowser = () => {
  let browser = '';
  const ua = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
  if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
    browser = 'msie';
  } else {
    browser = ua[1].toLowerCase();
  }

  return browser;
};

const PhotoSkeleton = () => {
  const browser = detectBrowser();

  return (
    <>
      {browser === 'safari' ? (
        <div
          css={`
            min-height: 5rem;
            display: flex;
            svg {
              width: 100%;
              height: 100%;
            }
          `}
        >
          <ContentLoader
            height={230}
            width={127}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="4" y="3" rx="0" ry="0" width="122" height="176" />
            <rect x="76" y="187" rx="0" ry="0" width="0" height="0" />
            <rect x="4" y="184" rx="0" ry="0" width="121" height="6" />
            <rect x="4" y="195" rx="0" ry="0" width="121" height="7" />
          </ContentLoader>
        </div>
      ) : (
        <ContentLoader
          height={230}
          width={127}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="4" y="3" rx="0" ry="0" width="122" height="176" />
          <rect x="76" y="187" rx="0" ry="0" width="0" height="0" />
          <rect x="4" y="184" rx="0" ry="0" width="121" height="6" />
          <rect x="4" y="195" rx="0" ry="0" width="121" height="7" />
        </ContentLoader>
      )}
    </>
  );
};

export default PhotoSkeleton;
