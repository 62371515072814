import styled from 'styled-components';

import { DESKTOP, PAD_S, PAD_L } from 'breakpoints';

const Aside = styled.aside`
  grid-column: 1 / -1;
  @media (min-width: ${DESKTOP}) {
    max-width: 23.07rem;
    width: 23.07rem;
    grid-column: 3 / -1;
  }
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    'content'
    'ads';
  grid-auto-rows: min-content;
  @media (min-width: ${PAD_S}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${PAD_L}) and (max-width: ${DESKTOP}) {
    grid-template-columns: 3fr 2fr;
  }

  @media (min-width: ${DESKTOP}) {
    grid-template-columns: 1fr;
  }
`;

export default Aside;
