import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Applications } from 'organisms';
import { StudentActions } from 'templates';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { fetchStudent } from 'actions';
import { getStudentState } from 'selectors';
import { DEFAULT } from 'breakpoints';
import { Skeleton } from '@material-ui/lab';
import { Heading } from 'Typography';
import { Accordion, AccordionSummary, SwipeableDrawer } from '@material-ui/core';
import Header, { StudentHeader } from './Header';
import StudentToolboxFooter from './StudentToolboxFooter';

const ToolboxContent = styled.div`
  padding-top: 4rem;
  box-sizing: border-box;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 4.67rem;

  & > * {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem 1.6rem;
  }
`;

const Summary = styled(AccordionSummary).attrs({ expandIcon: <Skeleton width={18} /> })`
  &&& {
    padding: 0 1.6em;
    font-weight: bold;
  }
`;

const CustomAccordion = styled(Accordion)`
  &&& {
    padding: 0;
  }
`;

const ActionsSkeletonWrapper = styled.div`
  & > div {
    margin-bottom: 0.4em;
    line-height: 2em;
    display: flex;
    justify-content: space-between;
  }
`;

const LoadingState = () => {
  return (
    <>
      <StudentHeader>
        <Skeleton variant="circle" width={90} height={90} />
        <Skeleton variant="text" width={120} />
        <Skeleton width={45} height={90} style={{ margin: '0.334em' }} />
      </StudentHeader>
      <ActionsSkeletonWrapper>
        <Heading type="h5" css="margin-bottom: 0.8rem;">
          <Skeleton width={100} />
        </Heading>
        <div>
          <Skeleton width={200} />
          <Skeleton variant="circle" width={36} height={36} />
        </div>
        <div>
          <Skeleton width={200} />
          <Skeleton variant="circle" width={36} height={36} />
        </div>
        <div>
          <Skeleton width={200} />
          <Skeleton variant="circle" width={36} height={36} />
        </div>
      </ActionsSkeletonWrapper>
      <CustomAccordion square expanded={false}>
        <Summary>
          <Skeleton width={100} />
        </Summary>
      </CustomAccordion>
      <CustomAccordion square expanded={false}>
        <Summary>
          <Skeleton width={160} />
        </Summary>
      </CustomAccordion>
    </>
  );
};

const TopBar = styled.div`
  position: absolute;
  background-color: var(--color-secondary);
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
`;

const StudentToolbox = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(getStudentState);
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const [isOpen, setOpen] = React.useState(false);
  const { push } = useHistory();
  const onToolboxClose = () => {
    push(pathname);
  };
  React.useEffect(() => {
    const isStudent = search && search.includes('studentId');
    const fetchStudentPromise = isStudent && dispatch(fetchStudent(search.split('=').pop()));
    setOpen(Boolean(isStudent));
    return () => {
      if (fetchStudentPromise) fetchStudentPromise.abort();
    };
  }, [search]);
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={() => {}}
      onClose={() => onToolboxClose()}
      css={`
        .MuiDrawer-paper {
          width: 100%;
          max-width: ${DEFAULT};
        }
      `}
    >
      <ToolboxContent>
        <TopBar>
          {t('student oversight')}
          <FAIcon
            css={`
              &&& {
                height: 1.667rem;
                width: 1.667rem;
                cursor: pointer;
              }
            `}
            icon={faTimes}
            onClick={() => onToolboxClose()}
          />
        </TopBar>
        {loading && <LoadingState />}
        {data && !loading && (
          <>
            <Header />
            <StudentActions />
            <Applications type="drafts" />
            <Applications />
            <StudentToolboxFooter />
          </>
        )}
      </ToolboxContent>
    </SwipeableDrawer>
  );
};

export default StudentToolbox;
