import normalize from 'jsonapi-normalizer';
import { compareDates } from 'utils';

export const compareApplications = (draftA, draftB) => compareDates(draftA.sentAt, draftB.sentAt);

export const turnResponseIntoApplications = response => {
  const {
    entities: { applications = {}, document = {} },
  } = normalize(response.data);
  if (applications) {
    // eslint-disable-next-line array-callback-return
    Object.values(applications).map(application => {
      const appDocuments = application.documents;
      // eslint-disable-next-line no-param-reassign
      application.documents = appDocuments.map(doc => {
        const appDocument = document && document[doc.id];
        return { ...doc, ...appDocument };
      });
    });
    return applications;
  }

  return {};
};
