import React from 'react';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const TrashIconButton = args => (
  <IconButton {...args}>
    <Delete />
  </IconButton>
);

export default TrashIconButton;
