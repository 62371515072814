/* eslint-disable import/prefer-default-export */
import { classroomActions } from '../rootTypes';

export const getClassroom = classId => ({
  type: classroomActions.fetchRequest,
  classId,
});

export const deleteClassroom = classId => ({
  type: classroomActions.deleteRequest,
  classId,
});

export const updateClassroom = (data, classId) => ({
  type: classroomActions.updateRequest,
  payload: data,
  classId,
});

export const addTeacherToClassroom = (email, classId) => ({
  type: classroomActions.addTeacherRequest,
  email,
  classId,
});

export const incrementConfirmedMembers = () => ({
  type: classroomActions.incrementConfirmed,
});

export const decrementConfirmedMembers = () => ({
  type: classroomActions.decrementConfirmed,
});

export const incrementUnconfirmedMembers = () => ({
  type: classroomActions.incrementUnconfirmed,
});

export const decrementUnconfirmedMembers = () => ({
  type: classroomActions.decrementUnconfirmed,
});

export const inviteClassroomToJobFinder = classId => ({
  type: classroomActions.inviteClassroomRequest,
  classId,
});

export const inviteClassToBerufsCheck = classId => ({
  type: classroomActions.inviteClassroomToBerufsCheckRequest,
  classId,
});
