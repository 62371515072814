/* eslint-disable no-useless-escape */
import { isElement } from 'lodash';

export const emailIsValid = mail => {
  const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return expression.test(String(mail).toLowerCase())
    ? { type: 'none' }
    : {
        type: 'warning',
        errors: [{ detail: 'Provided email doesnt fulfill requirements' }],
      };
};

export const passwordIsValid = password => {
  if (password) {
    const isLongEnough = password.length >= 8 ? true : { detail: 'error_password_too_short' };
    // const containsOneSpecialCharacter = password.split('').filter((letter) => {
    //   const charCode = letter.charCodeAt(0);
    //   return (
    //     (charCode > 32 && charCode < 48) || (charCode > 57 && charCode < 65)
    //   );
    // }).length > 0
    //   ? true
    //   : 'error_password_no_special_character';
    // const containsAtLeastOneNumber = password.split('').filter((letter) => {
    //   const charCode = letter.charCodeAt(0);
    //   return charCode > 47 && charCode < 58;
    // }).length > 0
    //   ? true
    //   : 'error_password_no_number';
    const arr = [
      isLongEnough,
      // containsOneSpecialCharacter,
      // containsAtLeastOneNumber,
    ].filter(value => value !== true);
    return arr.length > 0 ? { type: 'warning', errors: arr } : { type: 'none', errors: [] };
  }
  return { type: 'warning' };
};

export const requiredIsValid = field => {
  if (field) {
    const isLongEnough = field.length >= 0 ? true : { detail: 'error_field_required' };
    const arr = [
      isLongEnough,
      // containsOneSpecialCharacter,
      // containsAtLeastOneNumber,
    ].filter(value => value !== true);
    return arr.length > 0 ? { type: 'warning', errors: arr } : { type: 'none', errors: [] };
  }
  return { type: 'warning' };
};

export const termsIsValid = terms => {
  if (!terms) {
    return {
      type: 'warning',
      errors: [{ detail: 'must_accept_terms' }],
    };
  }

  return { type: 'none' };
};

export const splitArrayOnCondition = (array, test) => {
  const truthy = [];
  const falsy = [];
  array.forEach(element => {
    if (test(element)) truthy.push(element);
    else {
      falsy.push(element);
    }
  });
  return [truthy, falsy];
};
export const upsert = (element, array) => {
  const foundElementIndex = array.findIndex(el => el.id === isElement.id);

  if (foundElementIndex !== -1) {
    const newArray = [...array];
    newArray[foundElementIndex] = element;
    return newArray;
  }

  return [...array, element];
};

export const setRegistrationBody = ({
  state,
  schoolLevel,
  email,
  firstName,
  lastName,
  gender = 1,
  graduationYear,
}) => ({
  type: 'classroom_member',
  user: {
    source: 'Teacher-Invite in CCR',
    register_without_password: '',
    dont_send_confirmation_intructions: true,
    accept_agb: '1',
    accept_news: 1,
    first_name: firstName,
    last_name: lastName,
    gender,
    email,
    address_attributes: {
      state,
    },
    profile_attributes: {
      looks_for_job: 1,
      profile_type: 1,
      school_level: schoolLevel,
      desired_job_start_year: graduationYear,
    },
  },
});

export const formatErrors = errors => {
  const newErrors = {};

  errors.forEach(error => {
    const splitErrorTarget = error.source.pointer.split('/attributes/');
    const errorTarget = splitErrorTarget[splitErrorTarget.length - 1];

    newErrors[errorTarget] = { type: 'error', errors: [{ detail: error.detail }] };
  });

  return newErrors;
};

export const getId = responseData => {
  if (responseData.id) return responseData.id;
  throw new Error('Id missing from response. This shouldnt happen');
};

export const getConfirmedStatus = responseData => {
  return responseData.attributes && responseData.attributes.confirmed;
};

export const getUuidFromData = studentData => studentData.relationships.user.data.id;

export const getUuidFromIncluded = included => included.id;

export const constructStudentFromResponse = (studentResponseData, studentResponseIncluded) => {
  const id = getId(studentResponseData);
  const confirmed = getConfirmedStatus(studentResponseData);
  const studentIncludedAttributes = studentResponseIncluded.attributes;
  return {
    uuid: getUuidFromIncluded(studentResponseIncluded),
    memberId: id,
    confirmed,
    ...studentIncludedAttributes,
    ...studentResponseData.attributes,
  };
};
