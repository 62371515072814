import styled from 'styled-components';
import * as bp from 'breakpoints';

const CardMedia = styled.div`
  @media (max-width: ${bp.DEFAULT}) {
    display: none;
  }
  display: block;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 50%;
  width: 100%;
`;

export default CardMedia;
