import React from 'react';
import * as api from 'api';

const useRemindStudent = () => {
  const [studentId, setStudentId] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  React.useEffect(() => {
    const remindStudent = async () => {
      setIsSuccess(false);
      setIsPending(true);
      setIsError(false);
      try {
        const token = api.getToken();
        await api.userApi.post(
          `/classroom_members/${studentId}/send_member_notification`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setIsSuccess(true);
      } catch (err) {
        setIsError(true);
      }
      setIsPending(false);
      setStudentId(null);
    };
    if (studentId) {
      remindStudent();
    }
  }, [studentId]);

  return [{ isError, isPending, isSuccess }, setStudentId];
};

export default useRemindStudent;
