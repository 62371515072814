/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import uuid4 from 'uuid';
import { PAD_S } from 'breakpoints';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownHeader, DropdownOptions, DropdownOption } from 'organisms';

const tabFonts = {
  active: css`
    font-weight: bold;
    border-bottom: ${props => (props.status ? '3px solid var(--color-primary)' : 'none')};
  `,
  normal: css`
    font-weight: normal;
  `,
  disabled: css`
    color: var(--font-color-faded);
    cursor: not-allowed;
  `,
};

const TabContainer = styled.ul`
  display: none;
  list-style-type: none;
  padding-inline-start: 0;
  align-items: flex-start;
  margin: 0;

  li:not(:first-child) {
    margin-left: 2rem;
  }

  @media (min-width: ${PAD_S}) {
    display: flex;
    align-items: center;
  }
`;

const DropdownContainer = styled.div`
  display: initial;
  margin: 1.67rem 0;

  @media (min-width: ${PAD_S}) {
    display: none;
    margin: 0;
  }
`;

const Tab = styled.span`
  ${props => tabFonts[props.status]};
  padding: 0.87rem 0;
  cursor: pointer;
`;

const DropdownText = styled.p`
  font-size: 1.67rem;
  margin: 0;

  strong {
    text-decoration: underline;
  }
`;

const getTabStatus = ({ index, activeTabIndex }) => {
  if (index === activeTabIndex) {
    return 'active';
  }
  return 'normal';
};

const getCurrentTabIndex = tabs => {
  const urlPathArray = window.location.pathname.split('/');
  const tabParam = urlPathArray[urlPathArray.length - 1];

  return tabs.findIndex(tab => tab.route === tabParam);
};

const Tabs = () => {
  const [activeTabIndex, setActiveTab] = useState(0);
  const match = useRouteMatch();
  const { t } = useTranslation();
  const options = [
    { text: t('tab_1'), route: t('tab_1_route') },
    { text: t('tab_2'), route: t('tab_2_route') },
    { text: t('tab_3'), route: t('tab_3_route') },
  ];

  useEffect(() => {
    const currentTab = !match.isExact ? getCurrentTabIndex(options) : 0;
    setActiveTab(currentTab);
  });

  const tabs = (
    <TabContainer>
      {options.map((tab, index) => {
        const tabStatus = getTabStatus({ index, activeTabIndex });
        return (
          <li key={uuid4()}>
            <Link
              to={`/${t('class')}/${match.params.id}/${tab.route}`}
              css="text-decoration: none; color: initial;"
            >
              <Tab status={tabStatus} onClick={() => setActiveTab(index)}>
                {tab.text}
              </Tab>
            </Link>
          </li>
        );
      })}
    </TabContainer>
  );

  const activeTabText = options[activeTabIndex].text;

  const dropdownTabs = (
    <DropdownContainer>
      <Dropdown>
        <DropdownHeader>
          <DropdownText>
            {`${t('filter_by')} `}
            <strong>{activeTabText}</strong>
          </DropdownText>
        </DropdownHeader>
        <DropdownOptions>
          {options
            .filter((tab, index) => index !== activeTabIndex)
            .map(tab => (
              <DropdownOption key={uuid4()}>
                <Link
                  to={`/${t('class')}/${match.params.id}/${tab.route}`}
                  css={`
                    display: flex;
                    text-decoration: none;
                    padding: 0.75rem 1rem;
                    color: initial;
                    width: 100%;
                    height: 100%;
                  `}
                >
                  {tab.text}
                </Link>
              </DropdownOption>
            ))}
        </DropdownOptions>
      </Dropdown>
    </DropdownContainer>
  );

  return (
    <>
      {tabs}
      {dropdownTabs}
    </>
  );
};

export default Tabs;
