import React from 'react';
import styled from 'styled-components';
import { Button } from 'atoms';
import { Tabs } from 'organisms';
import { DESKTOP, PAD_S } from 'breakpoints';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ClassApplications } from 'templates';
import Photos from './Photos';
import BackButton from './templates/Buttons/BackButton';
import Students from './templates/Students/Students';

const Navigation = styled.div`
  display: grid;
  grid-template-columns: initial;
  grid-column-gap: 1rem;
  padding: 0;

  ${Button} {
    display: none;
  }

  @media (min-width: ${DESKTOP}) {
    padding: 0;
    grid-template-columns: 4fr 1fr;

    ${Button} {
      display: initial;
    }
  }
`;
const Line = styled.hr`
  display: none;
  border-color: #e8e8e830;
  border-width: 1px;
  width: 100%;
  margin: 1rem 0 1rem 0;

  @media (min-width: ${PAD_S}) {
    display: initial;
  }
`;
const Background = styled.section`
  display: grid;
  grid-auto-rows: min-content;
  align-items: start;
  order: 2;

  @media (min-width: ${DESKTOP}) {
    order: 1;
  }
`;

const LinkHolder = styled.div`
  display: none;

  @media (min-width: ${PAD_S}) {
    display: initial;
    position: absolute;
    top: 7rem;
  }

  @media (min-width: ${DESKTOP}) {
    position: static;
    top: 0;
    display: flex;
  }
`;

const BButton = styled(BackButton)`
  width: max-content;
  margin-bottom: 1.45rem;
  padding: 0 0.5rem;
`;

const CardDisplay = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  return (
    <Background>
      <LinkHolder>
        <BButton to={`/${t('classes')}`}>{t('back_to_classoverview')}</BButton>
      </LinkHolder>
      <Navigation>
        <Tabs />
      </Navigation>
      <Line />
      <div>
        <Switch>
          <Route path={`${match.path}/apps`} component={ClassApplications} />
          <Route path={`${match.path}/fotos`} exact component={Photos} />
          <Route path={`${match.path}`} render={() => <Students />} />
        </Switch>
      </div>
    </Background>
  );
};

export default CardDisplay;
