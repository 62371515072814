import React from 'react';
import styled from 'styled-components';
import { Heading } from 'Typography';
import { Delete } from '@material-ui/icons';

const PhotoImg = styled.img`
  height: 11.3rem;
  width: 8.47rem;
  object-fit: cover;
`;

const PhotoContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0.35rem;
  position: relative;
`;

const DeleteButton = styled(Delete)`
  position: absolute;
  color: var(--color-red);
  &:hover {
    color: var(--color-faded);
  }
`;

const PhotoCard = ({ photo, onDelete }) => {
  const { url, firstName, lastName, id } = photo;

  return (
    <PhotoContainer>
      <PhotoImg src={url} />
      <DeleteButton onClick={() => onDelete(id)} />
      <Heading type="h5">{`${firstName} ${lastName}`}</Heading>
    </PhotoContainer>
  );
};

export default PhotoCard;
