import styled from 'styled-components';

import { PAD_S } from '../breakpoints';

const Header = styled.header`
  grid-column: 1 / -1;
  * {
    text-shadow: none;
    @media (min-width: ${PAD_S}) {
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    }
  }
  ${props =>
    props.withHeroImage &&
    `
      color: var(--font-color-primary);
      text-shadow: none;
      & h1 {
        color: var(--font-color-primary);
        text-shadow: none;
        margin: 0;
      }
      & h4 {
        color: var(--font-color-primary);
        margin-top: 0;
        margin-bottom: 4rem;
        font-weight: lighter;
        }
      @media (min-width: ${PAD_S}) {
        & h4 {
            color: var(--font-color-secondary);
            margin-bottom: 0;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
            font-weight: lighter;
          }
        & h1 {
            color: var(--font-color-secondary);
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
          }
      }
  `}
`;

export default Header;
