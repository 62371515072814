/* eslint-disable import/prefer-default-export */
import { classesActions } from '../rootTypes';

export const addNewClass = classData => ({
  type: classesActions.addClassRequest,
  payload: classData,
});

export const fetchClasses = () => ({
  type: classesActions.fetchRequest,
});
