import React from 'react';
import styled from 'styled-components';
import { statusColorMap } from 'utils';
import uuid from 'uuid';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Button, Link } from '@material-ui/core';

const DetailedApplicationRow = styled.div`
  margin: 0 8px;
  padding: 1.6em;
  background-color: var(--color-secondary);
  border: 2px solid var(--color-background);
  border-radius: 0 0 10px 10px;
`;

const CompanyInfoColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StatusIcon = styled(FAIcon).attrs({ icon: faCircle })`
  &&& {
    color: ${props => statusColorMap[props.status]};
    height: 0.4rem;
    width: 0.4rem;
    align-self: center;
    padding-right: 0.4rem;
  }
`;

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Status = ({ status }) => (
  <StatusRow>
    <StatusIcon status={status} />
    {status}
  </StatusRow>
);

const DocumentsColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 1.2em;
`;

const DocumentsHeader = styled.div`
  box-sizing: border-box;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const DocumentsListWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DocumentLink = styled.a`
  color: black;
`;

const StyledUL = styled.ul`
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 0;
`;

const DocumentsList = ({ documents }) => {
  return (
    <DocumentsListWrapper>
      <StyledUL>
        {documents.map(document => {
          const { url, types } = document;
          return (
            <li>
              <DocumentLink key={uuid()} href={url} target="_blank">
                {types.map(type => type.name).join(', ')}
              </DocumentLink>
            </li>
          );
        })}
      </StyledUL>
    </DocumentsListWrapper>
  );
};

export default ({ application }) => {
  const { t } = useTranslation();
  const { documents, status, zipFileUrl } = application;
  return (
    <DetailedApplicationRow>
      <CompanyInfoColumn>
        <Status status={status} />
      </CompanyInfoColumn>
      {!!documents.length && (
        <DocumentsColumn>
          <DocumentsHeader>{t('documents')}</DocumentsHeader>
          <DocumentsList documents={documents} />
          {zipFileUrl && (
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              css={`
                &&& {
                  margin: 0.5em auto 0 0;
                }
              `}
              href={zipFileUrl}
            >
              {t('download dossier')}
            </Button>
          )}
        </DocumentsColumn>
      )}
    </DetailedApplicationRow>
  );
};
