/* eslint-disable max-len */
const getNavState = state => state.navState.navbar_open;
const getPhaseData = state => state.roadmapState.phases;

const getActivePhase = state =>
  Object.entries(state.roadmapState.phases).filter(entry => entry[1].active === true)[0][1];

const getEducationPhases = state => Object.values(state.roadmapState.phases);
const getTeasers = state =>
  state.roadmapState.teasers.filter(
    teaser =>
      teaser.attributes && teaser.attributes.phase_list.includes(getActivePhase(state).text),
  );

const selectClasses = state => state.classesState;
const selectNewClass = state => state.classesState.newClass;

const selectUserName = state => ({
  firstName: state.userState.user.attributes.firstName,
  lastName: state.userState.user.attributes.lastName,
});
const getUserData = state => ({
  data: { ...state.userState.user },
  signedIn: state.userState.signedIn,
});
const getUserId = state => state.userState.user.id;

const getClassroomData = state => ({
  fetching: state.classroomState.fetching,
  error: state.classroomState.error,
  classroomData: state.classroomState.classroomData,
});

const getClassFormErrors = state => state.classesState.newClass.error;

const getStudentState = state => state.student;

const getOldRegistrationData = state => ({
  schoolLevel: state.classroomState.classroomData.attributes.schoolLevel,
  state: state.classroomState.classroomData.attributes.state,
});

const getEditClassModalData = state => ({
  name: state.classroomState.classroomData.attributes.name,
  state: state.classroomState.classroomData.attributes.state,
  schoolLevel: state.classroomState.classroomData.attributes.schoolLevel,
  graduationYear: state.classroomState.classroomData.attributes.graduationYear,
});

const getTokenStatus = state => ({
  isTokenTimedOut: state.errorState.timeout,
});

const getConfirmationBarStatus = state => {
  const isUserLoggedIn = state.userState.signedIn;
  const isUserUnconfirmed = state.userState.user.attributes
    ? !state.userState.user.attributes.confirmed
    : false;

  return isUserUnconfirmed && isUserLoggedIn;
};

const getUserLoginStatus = state => state.userState.signedIn;

const getStudentError = state => state.student.error;

const getClassGraduationYear = state =>
  state.classroomState.classroomData.attributes.graduationYear;

export { studentApplicationsSelectors } from './student/applications';
export { getAllStudentDraftApplications } from './student/draftApplications';
export { studentsSelectors } from './classroom/students';
export { photosSelectors } from './classroom/photos';
export {
  getNavState,
  getPhaseData,
  getActivePhase,
  getEducationPhases,
  selectClasses,
  getUserData,
  getTeasers,
  getUserId,
  selectUserName,
  getClassroomData,
  selectNewClass,
  getClassFormErrors,
  getStudentState,
  getOldRegistrationData,
  getEditClassModalData,
  getStudentError,
  getTokenStatus,
  getConfirmationBarStatus,
  getUserLoginStatus,
  getClassGraduationYear,
};
