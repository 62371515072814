import React from 'react';
import { NavLink } from 'react-router-dom';
import { Paragraph } from 'Typography';
import styled from 'styled-components';

const WhiteParagraph = styled(Paragraph)`
  color: var(--font-color-secondary);
`;

const SidebarLink = ({ url, img, alt, text }) => {
  return (
    <NavLink to={url}>
      <img src={img} alt={alt} />
      <WhiteParagraph>{text}</WhiteParagraph>
    </NavLink>
  );
};

export default SidebarLink;
