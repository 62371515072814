import { errorTypes } from '../rootTypes';

const INITIAL_STATE = {
  timeout: false,
};

const errorState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case errorTypes.apiTimeoutError:
      return {
        ...INITIAL_STATE,
        timeout: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default errorState;
