import React from 'react';
import { Card, CardContent, CardHeader, CardText, LinkButton } from 'molecules';
import tracker from 'tracker';

const SecondaryTeaser = ({
  area,
  textContent,
  link,
  hideTextMobile,
  newTab = false,
  gaEvent,
  ...args
}) => (
  <Card
    secondary
    area={area}
    {...args}
    css={`
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}
  >
    <CardContent>
      <CardHeader color="secondary">{textContent.header}</CardHeader>
      {textContent.text && <CardText hideTextMobile={hideTextMobile}>{textContent.text}</CardText>}
    </CardContent>
    {newTab ? (
      <LinkButton
        secondary
        mobileFullWidth
        marginTop="1rem"
        flexAlign="flex-end"
        onClick={() => tracker.trackEvent(gaEvent)}
      >
        <a href={link.to} target="_blank" rel="noopener noreferrer">
          {link.text}
        </a>
      </LinkButton>
    ) : (
      <LinkButton
        as="a"
        secondary
        mobileFullWidth
        marginTop="1rem"
        flexAlign="flex-end"
        href={link.to}
        onClick={() => tracker.trackEvent(gaEvent)}
        target="_blank"
      >
        {link.text}
      </LinkButton>
    )}
  </Card>
);

export default SecondaryTeaser;
