import React from 'react';
import styled from 'styled-components';
import { Button } from 'atoms';
import { Paragraph } from 'Typography';
import { Card } from 'molecules';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteClassroom } from 'actions';
import { withRouter } from 'react-router-dom';
import { GridColumns, GridRows } from './Modal';

const Buttons = styled.div`
  display: flex;

  ${Button}:nth-child(2) {
    margin-left: 1rem;
  }
`;

const DeleteClassModal = ({
  classroomId,
  deleteClassroomHandler,
  toggleLocalModal,
  history,
  ...args
}) => {
  const { t } = useTranslation();

  const handleDeleteClassFlow = () => {
    deleteClassroomHandler(classroomId);
    toggleLocalModal(false);
    setTimeout(() => {
      history.push(`/${t('classes')}`);
    }, 2000);
  };

  return (
    <Card primary {...args}>
      <GridColumns>
        <GridRows>
          <Paragraph>{t('delete_class_confirmation')}</Paragraph>
          <Buttons>
            <Button primary onClick={handleDeleteClassFlow}>
              {t('delete_class_button_1')}
            </Button>
            <Button secondary onClick={() => toggleLocalModal(false)}>
              {t('delete_class_button_2')}
            </Button>
          </Buttons>
        </GridRows>
      </GridColumns>
    </Card>
  );
};

const mapStateToProps = state => ({
  classroomId: state.classroomState.classroomData.id,
});

const mapDispatchToProps = dispatch => ({
  deleteClassroomHandler: id => dispatch(deleteClassroom(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteClassModal));
