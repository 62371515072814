import styled from 'styled-components';

const Paper = styled.div`
  ${props => {
    if (props.primary) {
      return `
      color: var(--font-color-primary);
      background-color: var(--color-secondary);
    `;
    }
    if (props.secondary) {
      return `
      color: var(--font-color-secondary);
      background-color: var(--color-primary);
    `;
    }
    if (props.yousty) {
      return `
      color: var(--font-color-secondary);
      background-color: var(--color-primary);
    `;
    }
    return 'background-color: transparent;';
  }};
  ${props => props.area && `grid-area: ${props.area};`};
  border-radius: 5px;
  box-shadow: var(--elevation-default);
  ${props =>
    props.hideMobile &&
    `
  @media (max-width: 767px) {
      display: none;
    }
  `}
  ${props =>
    props.elevation && `box-shadow: var(--elevation-${props.elevation}, var(--elevation-default));`}
  ${props => props.grid && 'display:grid;'}
  ${props => props.gridColumns && `grid-template-columns: ${props.gridColumns};`}
  ${props => props.gridRows && `grid-template-rows: ${props.gridRows};`}
  ${props => props.gridColumn && `grid-column: ${props.gridColumn};`}
  ${props => props.gridRow && `grid-row: ${props.gridRow};`}
  ${props => props.gridGap && `grid-gap: ${props.gridGap};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.relative && 'position: relative;'}
`;

export default Paper;
