import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Sidebar } from 'organisms';
import { Hamburger } from 'molecules';
import { PAD_S } from 'breakpoints';
import { getNavState, getConfirmationBarStatus } from 'selectors';
import { useLocation } from 'react-router-dom';
import UserField from '../UserField/UserField';

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  ${props => props.unconfirmed && 'top: 50px;'}
  right: 0;
  width: 100%;
  transition: all 250ms ease-in;

  ${props =>
    props.active &&
    `
    position: fixed;
    background-color: var(--color-background);
    transition: all 250ms ease-out;
  `};
`;

const HamburgerContainer = styled.div`
  display: inherit;

  ${props => props.hidden && 'visibility: hidden;'}
`;

const Navigation = () => {
  const [isNavbarTravelling, changeNavbarState] = useState(false);
  const [currentScrollPosition, changeScrollPosition] = useState(0);
  const { pathname } = useLocation();

  const navbarVisible = useSelector(getNavState);
  const showConfirmationBar = useSelector(getConfirmationBarStatus);
  const isRouteNotFound = useSelector(state => state.navState.notFound);

  const onScrollHandler = () => {
    const nextScrollPosition = window.pageYOffset;
    const visible = currentScrollPosition > nextScrollPosition;

    if (nextScrollPosition !== currentScrollPosition) changeScrollPosition(nextScrollPosition);
    if (visible !== isNavbarTravelling) changeNavbarState(visible);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  });

  const isLoginPage = pathname.includes('auth');
  const isEntry = pathname === '/';
  const isNavbarActive = isNavbarTravelling && currentScrollPosition !== 0;

  if (isLoginPage || isRouteNotFound) {
    return <> </>;
  }

  return (
    <>
      <div
        css={css`
          @media (max-width: ${PAD_S}) {
            display: initial;
          }
          @media (min-width: ${PAD_S}) {
            display: none;
          }
        `}
      >
        <Navbar active={isNavbarActive} unconfirmed={showConfirmationBar}>
          <HamburgerContainer hidden={navbarVisible}>
            <Hamburger />
          </HamburgerContainer>
          <UserField />
        </Navbar>
      </div>
      <div
        css={css`
          @media (max-width: ${PAD_S}) {
            display: none;
          }
          @media (min-width: ${PAD_S}) {
            display: initial;
          }
        `}
      >
        <Navbar unconfirmed={showConfirmationBar}>
          <HamburgerContainer hidden={navbarVisible}>
            <Hamburger />
          </HamburgerContainer>
          <UserField />
        </Navbar>
      </div>
      {!isEntry && <Sidebar unconfirmed={showConfirmationBar} />}
    </>
  );
};

export default Navigation;
