import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { useRemoveStudent } from 'utils';

const DeleteStudentModal = ({ isOpen, memberId, handleClose }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, removeStudent] = useRemoveStudent();
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('delete_student')}</DialogTitle>
      <DialogContent>{t('confirm_deleting_student_from_class_impers')}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => removeStudent(memberId)}>
          {t('remove')}
        </Button>
        <Button variant="outlined" color="primary" onClick={() => handleClose()}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStudentModal;
