/* eslint-disable import/prefer-default-export */
import { modalActions } from '../rootTypes';

export const spawnModal = action => ({
  type: modalActions.spawnModal,
  payload: action,
});

export const toggleModal = show => ({
  type: modalActions.toggleModal,
  payload: show,
});

export const initModalData = (key, initState) => ({
  type: modalActions.initModalData,
  payload: initState,
  key,
});
