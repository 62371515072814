/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import throttle from 'lodash/throttle';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import createSpacing from '@material-ui/core/styles/createSpacing';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ErrorBoundary } from 'templates';
import tracker from 'tracker';
import * as breakpoints from 'breakpoints';
import configureStore from './redux/rootStore';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('youstycareerchoiceradarstate');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = state => {
  const {
    photosState,
    navState,
    classroomState,
    classesState,
    classroomActivitiesState,
    student,
    students,
    registerState,
    classroomApplicationState,
    modalState,
    ...savedState
  } = state;
  try {
    const serializedState = JSON.stringify(savedState);
    localStorage.setItem('youstycareerchoiceradarstate', serializedState);
  } catch (err) {
    // ignore
  }
};

const spacing = createSpacing();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6A205F',
    },
    secondary: {
      main: '#e6a54b',
    },
  },
  overrides: {
    MuiCard: {
      root: {
        height: '100%',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiCardActions: {
      root: {
        marginTop: 'auto',
        paddingBottom: spacing(2),
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0, // default: 0,
      sm: parseInt(breakpoints.PAD_S, 10), // default: 600, PAD_S = '768px'
      md: parseInt(breakpoints.PAD_L, 10), // default: 960, PAD_L = '980px'
      lg: parseInt(breakpoints.DESKTOP, 10), // default: 1280, DESKTOP = '1280px'
      xl: parseInt(breakpoints.XXL, 10), // default: 1920, XXL = '1920px'
    },
  },
});

const store = configureStore(loadState());

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

// eslint-disable-next-line no-unused-expressions
process.env.SENTRY_DSN &&
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  });

ReactDOM.render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <SCThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </SCThemeProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
