import React, { useState } from 'react';
import styled from 'styled-components';
import { PAD_S } from 'breakpoints';
import { Button, Input, Checkbox } from 'atoms';
import { Paragraph } from 'Typography';
import { InputField, Form, Card } from 'molecules';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { addTeacherToClassroom } from 'actions';
import { GridRows } from './Modal';

const Buttons = styled.div`
  margin-top: 1rem;

  @media (min-width: ${PAD_S}) {
    margin-top: 0.84rem;
  }

  ${Button}:nth-child (2) {
    margin-left: 1rem;
  }
`;

const SmallButton = styled(Button)`
  min-width: 6.14rem;
`;

const FormGrid = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const InputFieldSpace = styled(InputField)`
  label {
    padding: 1px;
  }
`;

const InviteTeacherModal = ({
  toggleLocalModal,
  classId,
  addTeacher,
  addTeacherState,
  ...args
}) => {
  /* eslint-disable jsx-a11y/label-has-for */
  /* eslint-disable jsx-a11y/label-has-associated-control */
  const { t } = useTranslation();
  const [email, changeEmailState] = useState('');
  const [buttonState, changeButtonState] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    addTeacher(email, classId);
    toggleLocalModal(false);
    changeEmailState('');
    changeButtonState(false);
  };

  return (
    <Card primary {...args}>
      <GridRows>
        <Paragraph>{t('invite_teacher_par_1')}</Paragraph>
        <FormGrid onSubmit={e => submitForm(e)}>
          <InputFieldSpace label={t('invite_teacher_label_1')} id="email" required>
            <Input
              type="email"
              required
              name="email"
              value={email}
              placeholder="max.musterman@schule.ch"
              onChange={e => changeEmailState(e.target.value)}
            />
          </InputFieldSpace>
          <Checkbox>
            <input
              value={buttonState}
              onClick={() => changeButtonState(!buttonState)}
              type="checkbox"
              id="terms"
            />
            <span />
            <p>{t('invite_teacher_par_2')}</p>
          </Checkbox>
          <Buttons>
            <SmallButton type="submit" primary={buttonState} disabled={!buttonState}>
              {t('invite_teacher_button_1')}
            </SmallButton>
          </Buttons>
        </FormGrid>
      </GridRows>
    </Card>
  );
};

const mapStateToProps = state => ({
  classId: state.classroomState.classroomData.id,
  addTeacherState: state.classroomState.addTeacher,
});

const mapDispatchToProps = dispatch => ({
  addTeacher: (email, classId) => dispatch(addTeacherToClassroom(email, classId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteTeacherModal);
