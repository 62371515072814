import classroom from './images/classroom.png';
import entryBackground from './images/entryBackground.png';
import roadmap from './images/roadmap.png';
import backgroundBig from './images/backgroundBig.jpg';
import backgroundMedium from './images/backgroundMedium.jpg';
import backgroundSmall from './images/backgroundSmall.jpg';
// eslint-disable-next-line import/prefer-default-export
export const images = {
  classroom,
  entryBackground,
  roadmap,
  backgroundBig,
  backgroundMedium,
  backgroundSmall,
};
