/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  fetchStudentApplications,
  resetApplications,
  fetchStudentDraftApplications,
  resetDraftApplications,
} from 'actions';
import { ApplicationSimple, ApplicationDetailed, ApplicationSkeleton } from 'molecules';
import { getStudentState } from 'selectors';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faExclamationCircle, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import uuid4 from 'uuid';

const Summary = styled(AccordionSummary).attrs({ expandIcon: <ExpandMoreIcon /> })`
  &&& {
    padding: 0 1.6em;
    font-weight: bold;
  }
`;

const CustomAccordion = styled(Accordion)`
  &&& {
    padding: 0;
  }
`;

const Details = styled(AccordionDetails)`
  &&& {
    padding: 0;
  }
`;

const Application = ({ application }) => {
  const [detailed, toggleDetailed] = React.useState(false);
  return (
    <>
      <ApplicationSimple
        application={application}
        onDocumentsClick={() => toggleDetailed(!detailed)}
        expanded={detailed}
      />
      {detailed && <ApplicationDetailed application={application} />}
    </>
  );
};

const ApplicationsContent = styled.div`
  width: 100%;
  ${props => {
    switch (props.type) {
      case 'no_content':
      case 'error':
        return `
          background-color: var(--color-lighter, #E8E8E8);
          min-height: 16em;
          display: grid;
          grid-template-rows: 4fr 3fr;
          align-items: start;
          justify-items: center;
          border-radius: 0 0 5px 5px;
          grid-gap: 0.5rem;
          color: var(--color-darker, #616161);
          box-sizing: border-box;
        `;
      default:
        return '';
    }
  }}
`;

const reduxMapping = {
  drafts: {
    fetchContent: fetchStudentDraftApplications,
    resetContent: resetDraftApplications,
    selector: state => state.studentDraftApplications,
  },
  applications: {
    fetchContent: fetchStudentApplications,
    resetContent: resetApplications,
    selector: state => state.studentApplications,
  },
};

export const Applications = ({ type = 'applications' }) => {
  const { t } = useTranslation();
  const { fetchContent, resetContent, selector } = reduxMapping[type];
  const dispatch = useDispatch();
  const {
    data: { memberId },
  } = useSelector(getStudentState);
  const { loading, error, ids, entities } = useSelector(selector);
  useEffect(() => {
    const promise = dispatch(fetchContent(memberId));
    return () => {
      promise.abort();
      dispatch(resetContent());
    };
  }, []);
  if (loading)
    return (
      <CustomAccordion square>
        <Summary aria-controls="panel1a-content" id="panel1a-header">
          {t(type)}
        </Summary>
        <Details>
          <ApplicationsContent>
            <ApplicationSkeleton />
            <ApplicationSkeleton />
            <ApplicationSkeleton />
          </ApplicationsContent>
        </Details>
      </CustomAccordion>
    );
  if (error) {
    return (
      <CustomAccordion square>
        <Summary aria-controls="panel1a-content" id="panel1a-header">
          {t(type)}
        </Summary>
        <Details>
          <ApplicationsContent type="error">
            <FAIcon
              icon={faExclamationCircle}
              css={`
                &&& {
                  height: 50%;
                  width: 50%;
                  align-self: end;
                }
              `}
            />
            {t(error)}
          </ApplicationsContent>
        </Details>
      </CustomAccordion>
    );
  }
  if (ids.length) {
    return (
      <CustomAccordion square>
        <Summary aria-controls="panel1a-content" id="panel1a-header">
          {t(type)}
        </Summary>
        <Details>
          <ApplicationsContent>
            {ids.map(applicationId => (
              <Application application={entities[applicationId]} key={uuid4()} />
            ))}
          </ApplicationsContent>
        </Details>
      </CustomAccordion>
    );
  }
  return (
    <CustomAccordion square>
      <Summary aria-controls="panel1a-content" id="panel1a-header">
        {t(type)}
      </Summary>
      <Details>
        <ApplicationsContent type="no_content" css="text-align: center">
          <FAIcon
            icon={faChalkboardTeacher}
            css={`
              &&& {
                height: 4rem;
                width: 4rem;
                align-self: end;
              }
            `}
          />
          {type === 'applications' ? t('no_apprenticeships_yet') : t('no_drafts_yet')}
        </ApplicationsContent>
      </Details>
    </CustomAccordion>
  );
};

export default Applications;
