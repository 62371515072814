import React from 'react';
import styled from 'styled-components';
import * as bp from 'breakpoints';

const BaseFunc = styled.span`
  ${props => props.area && `grid-area: ${props.area};`}
  color: ${props => {
    switch (props.color) {
      case 'primary':
        return 'var(--font-color-primary);';
      case 'secondary':
        return 'var(--font-color-secondary);';
      case 'yousty':
        return 'var(--font-color-yousty);';
      default:
        return 'var(--font-color-primary);';
    }
  }};
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props =>
    props.withHeroImage &&
    `
      color: var(--font-color-primary);
      text-shadow: none;
      & h1 {
        color: var(--font-color-primary);
        text-shadow: none;
        margin: 0;
      }
      & h2 {
        color: var(--font-color-primary);
        margin-top: 0;
        margin-bottom: 4em;
        }
      @media (min-width: ${bp.PAD_S}) {
        & h2 {
            color: var(--font-color-secondary);
            margin-bottom: 0;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
          }
        & h1 {
            color: var(--font-color-secondary);
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
          }
      }
  `}
`;

const H1 = styled(BaseFunc)`
  font-family: var(--font-secondary);
  font-weight: bold;
  font-size: 2em;
  line-height: 1.08em;
  max-height: 2.16em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`;

const H2 = styled(BaseFunc)`
  font-family: var(--font-primary);
  font-size: 1.67em;
  line-height: 1.87em;
  font-weight: 300;
`;

const H3 = styled(BaseFunc)`
  font-family: var(--font-primary);
  font-weight: bold;
  font-size: 1.67em;
  line-height: 1.87em;
`;

const H4 = styled(BaseFunc)`
  font-family: var(--font-primary);
  font-weight: bold;
  font-size: 1.334em;
  line-height: 1.08em;
`;

const H5 = styled(BaseFunc)`
  font-size: 1em;
  line-height: 1.34em;
  font-weight: bold;
  margin: 0;
`;

const H6 = styled(BaseFunc)`
  font-size: 0.67em;
  line-height: 1.08em;
`;

const Heading = ({ type, ...props }) => {
  switch (type) {
    case 'h1':
      return <H1 as="h1" {...props} />;
    case 'h2':
      return <H2 as="h2" {...props} />;
    case 'h3':
      return <H3 as="h3" {...props} />;
    case 'h4':
      return <H4 as="h4" {...props} />;
    case 'h5':
      return <H5 as="h5" {...props} />;
    default:
      return <H6 as="h6" {...props} />;
  }
};

export default Heading;
