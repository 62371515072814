import AddClassroom from 'components/AddClassroom';
import Auth from 'components/Auth';
import Register from 'components/Register';
import { CareerRoadmap, ClassesOverview, ClassOverview, Entry, NoMatch } from 'pages';
import { Conventions, Events, Finder, Presentations } from 'pages/Ads';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { getUserData } from 'selectors';

const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user.signedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { from: props.location, user },
          }}
        />
      )
    }
  />
);

const handleAppcues = user => {
  if (window.Appcues !== undefined && process.env.NODE_ENV !== 'development') {
    const { firstName, lastName, email } = user.data.attributes;
    window.Appcues.page();

    if (user.signedIn) {
      window.Appcues.identify(user.data.id, {
        firstName,
        lastName,
        email,
      });
    } else {
      window.Appcues.reset();
    }
  }
};

const Routes = () => {
  const { t } = useTranslation();
  const user = useSelector(getUserData);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    handleAppcues(user);
  }, [pathname, user]);

  return (
    <Switch>
      <Route path="/" exact component={Entry} />
      <Route path={`/${t('roadmap')}`} component={CareerRoadmap} />
      <PrivateRoute path={`/${t('classes')}`} user={user} component={ClassesOverview} />
      <PrivateRoute path={`/${t('class')}/:id`} user={user} component={ClassOverview} />
      <Route path="/auth/login" component={Auth} />
      <Route path="/auth/register" component={Register} />
      <Route path="/auth/add_classroom" component={AddClassroom} />
      <Route path={`/${t('presentations')}`} component={Presentations} />
      <Route path={`/${t('conventions')}`} component={Conventions} />
      <Route path={`/${t('finder')}`} component={Finder} />
      <Route path={`/${t('events')}`} component={Events} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Routes;
