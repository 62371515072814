/* eslint-disable consistent-return */
import React from 'react';
import { Link, Card, CardActionArea, CircularProgress } from '@material-ui/core';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import styled, { css } from 'styled-components';
import { useFetchPhotosLink } from 'utils';

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 16em;
  height: 18em;
  text-overflow: ellipsis;
`;

const StyledLink = styled(Link).attrs({
  component: CardActionArea,
  variant: 'h5',
  color: 'textPrimary',
  underline: 'always',
})`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: start;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;

  &&& {
    padding: 1em 1.6em;
  }
  ${({ href }) => {
    if (href === '')
      return css`
        &&& {
          background-color: var(--color-background);
          cursor: not-allowed;
          color: var(--font-color-faded);
        }
      `;
  }}
`;

const StyledIcon = styled(PermMediaIcon)`
  &&& {
    display: block;
    width: 1em;
    height: 1em;
    margin-bottom: 2em;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  &&& {
    display: block;
    width: 1em;
    height: 1em;
    margin-bottom: 2em;
  }
`;

const EventPhotosLink = ({ event }) => {
  const {
    attributes: { text },
    id,
  } = event;
  const [{ loading, url }, fetchPhotos] = useFetchPhotosLink();
  React.useEffect(() => {
    fetchPhotos(id);
  }, []);
  return (
    <StyledCard>
      <StyledLink href={url}>
        {loading && <StyledCircularProgress />}
        {url && <StyledIcon />}
        {text}
      </StyledLink>
    </StyledCard>
  );
};

export default EventPhotosLink;
