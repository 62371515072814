/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { constructStudentFromResponse } from '../helpers';
import * as api from '../api';

export const fetchStudent = createAsyncThunk('student/fetchStatus', async (memberId, thunkApi) => {
  const token = api.getToken();
  try {
    const response = await api.userApi.get(`/classroom_members/${memberId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return constructStudentFromResponse(response.data.data, response.data.included[0]);
  } catch (err) {
    // @TODO: Add common error handling that checks for token out of sync
    return thunkApi.rejectWithValue(err.message);
  }
});

export const resetStudent = createAction('student/reset');

export const INITIAL_STATE = {
  loading: false,
  error: '',
  data: undefined,
};

const studentSlice = createSlice({
  name: 'student',
  initialState: INITIAL_STATE,
  reducers: { resetStudent: () => INITIAL_STATE },
  extraReducers: builder => {
    builder
      .addCase(fetchStudent.pending, state => {
        state.loading = true;
      })
      .addCase(fetchStudent.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchStudent.rejected, state => {
        state.loading = false;
        state.error = 'err_fetching';
      })
      .addDefaultCase(() => {});
  },
});

export default studentSlice.reducer;
