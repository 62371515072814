import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider as MuiDivider,
  Link,
  TextField,
  Grid,
  Button as MuiButton,
  Snackbar,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Alert from '@material-ui/lab/Alert';
import useLogin from 'utils/useLogin';
import { loginSchema } from 'utils';

const Button = styled(MuiButton)`
  &.MuiButton-root {
    width: 100%;
  }
`;

const Divider = styled(MuiDivider)`
  &.MuiDivider-root {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const LoginForm = () => {
  const { push } = useHistory();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [{ isLoading, isSuccess, isError, error }, loginUser] = useLogin();
  const { t } = useTranslation();
  const { register, handleSubmit, formState, errors, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(loginSchema),
  });
  const errorCaptions = {
    min: t('Password should be at least 8 characters long'),
    email: t('Provided email doesnt fulfill requirements'),
    matches: t('Password should contain at least one number'),
  };
  const canSubmit = formState.isValid && !isLoading;
  const onSubmit = handleSubmit(data => loginUser(data));
  React.useEffect(() => {
    if (isError) {
      reset();
      setAlertOpen(true);
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      push(`/${t('roadmap')}`);
    }
  });

  return (
    <Grid
      container
      direction="column"
      component="form"
      onSubmit={onSubmit}
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid item xs={12}>
        <TextField
          required
          error={!!errors.email}
          helperText={errors.email ? errorCaptions[errors.email.type] || errors.email.message : ' '}
          inputRef={register}
          label="E-mail"
          type="email"
          name="email"
          fullWidth
          placeholder={t('mail_placeholder')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          inputRef={register}
          label="Passwort"
          type="password"
          name="password"
          fullWidth
          error={!!errors.password}
          helperText={
            errors.password ? errorCaptions[errors.password.type] || errors.password.message : ' '
          }
          placeholder="********"
        />
      </Grid>
      <Grid
        container
        spacing={2}
        item
        component={props => <Box display="flex" alignItems="center" {...props} />}
      >
        <Grid item xs={8}>
          <Link underline="always" href="https://www.yousty.ch/de-CH/password/new">
            {t('forgot_password')}
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
            {t('login')}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          elevation={6}
          onClose={() => {
            setAlertOpen(false);
          }}
          variant="filled"
          severity="error"
        >
          {error}
        </Alert>
      </Snackbar>
      <Divider />
    </Grid>
  );
};

export default LoginForm;
