import { classroomActions } from '../rootTypes';

const INITIAL_STATE = {
  fetching: false,
  error: '',
  classroomData: {
    attributes: {
      graduationYear: null,
      name: '',
      schoolLevel: '',
      state: '',
      studentsConfirmed: 0,
      studentsUnconfirmed: 0,
    },
    relationships: {
      addedTeachers: {
        data: [],
      },
    },
  },
  included: [],
  invite: {
    sending: false,
    sent: false,
    error: {},
    isError: false,
  },
  inviteClassToBerufsCheck: {
    sending: false,
    sent: false,
    error: {},
    isError: false,
  },
  addTeacher: {
    fetching: false,
    isError: false,
  },
};

const classroomState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case classroomActions.fetchRequest:
      return {
        ...INITIAL_STATE,
      };
    case classroomActions.fetchPending:
      return {
        ...state,
        fetching: true,
      };
    case classroomActions.fetchSuccess:
      return {
        ...state,
        fetching: false,
        error: false,
        classroomData: { ...action.payload.data },
        included: [...action.payload.included],
      };
    case classroomActions.fetchError:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        classroomData: { ...INITIAL_STATE.classroomData },
      };
    case classroomActions.updatePending:
      return {
        ...state,
        fetching: true,
      };
    case classroomActions.updateSuccess:
      return {
        ...state,
        fetching: false,
        error: false,
        classroomData: { ...action.payload.data },
      };
    case classroomActions.updateError:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case classroomActions.addTeacherPending:
      return {
        ...state,
        addTeacher: {
          ...state.addTeacher,
          fetching: true,
        },
      };
    case classroomActions.addTeacherSuccess:
      return {
        ...state,
        addTeacher: {
          ...INITIAL_STATE.addTeacher,
        },
        included: [...state.included, { ...action.payload.included }],
        classroomData: {
          ...state.classroomData,
          relationships: {
            ...state.classroomData.relationships,
            addedTeachers: {
              ...state.classroomData.relationships.addedTeachers,
              data: [
                ...state.classroomData.relationships.addedTeachers.data,
                { ...action.payload.data },
              ],
            },
          },
        },
      };
    case classroomActions.addTeacherError:
      return {
        ...state,
        addTeacher: {
          fetching: false,
          isError: true,
        },
      };
    case classroomActions.incrementConfirmed:
      return {
        ...state,
        classroomData: {
          ...state.classroomData,
          studentsConfirmed: state.classroomData.studentsConfirmed + 1,
        },
      };
    case classroomActions.incrementUnconfirmed:
      return {
        ...state,
        classroomData: {
          ...state.classroomData,
          studentsUnconfirmed: state.classroomData.studentsUnconfirmed + 1,
        },
      };
    case classroomActions.decrementConfirmed:
      return {
        ...state,
        classroomData: {
          ...state.classroomData,
          studentsConfirmed: state.classroomData.studentsConfirmed - 1,
        },
      };
    case classroomActions.decrementUnconfirmed:
      return {
        ...state,
        classroomData: {
          ...state.classroomData,
          studentsUnconfirmed: state.classroomData.studentsUnconfirmed - 1,
        },
      };

    case classroomActions.inviteClassroomPending:
      return {
        ...state,
        invite: {
          ...state.invite,
          sending: true,
          sent: false,
        },
      };
    case classroomActions.inviteClassroomSuccess:
      return {
        ...state,
        invite: {
          ...INITIAL_STATE.invite,
          sent: true,
        },
      };
    case classroomActions.inviteClassroomError:
      return {
        ...state,
        invite: {
          ...state.invite,
          sending: false,
          isError: true,
          error: action.payload,
        },
      };
    case classroomActions.inviteClassroomToBerufsCheckPending:
      return {
        ...state,
        inviteClassToBerufsCheck: {
          ...state.inviteClassToBerufsCheck,
          sending: true,
          sent: false,
        },
      };
    case classroomActions.inviteClassroomToBerufsCheckSuccess:
      return {
        ...state,
        inviteClassToBerufsCheck: {
          ...INITIAL_STATE.inviteClassToBerufsCheck,
          sent: true,
        },
      };
    case classroomActions.inviteClassroomToBerufsCheckError:
      return {
        ...state,
        inviteClassToBerufsCheck: {
          ...state.inviteClassToBerufsCheck,
          sending: false,
          isError: true,
          error: action.payload,
        },
      };
    default:
      return { ...state };
  }
};

export default classroomState;
