import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { TrashIconButton } from 'atoms';
import { UserAvatar } from 'molecules';
import { spawnModal, toggleModal } from 'actions';
import { getStudentState } from 'selectors';
import { Heading } from 'Typography';
import DeleteStudentModal from '../Modals/DeleteStudentModal';

export const StudentHeader = styled(Heading).attrs({ type: 'h2' })`
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: auto 1fr auto;
  font-weight: bold;
  align-items: center;
`;

const StudentCardHeader = ({ student }) => {
  const {
    data: { firstName, lastName, avatarSrc, id },
  } = student;
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);

  return (
    <StudentHeader>
      <UserAvatar
        src={avatarSrc || 'https://picsum.photos/30/30'}
        css={`
          &&& {
            height: 3em;
            width: 3em;
            align-self: center;
          }
        `}
      />
      {`${firstName} ${lastName}`}
      <TrashIconButton
        css={`
          &&&& {
            height: 1em;
            width: 1em;
            padding: 0.334em;
          }
        `}
        onClick={() => setDeleteModalOpen(true)}
      />
      <DeleteStudentModal
        isOpen={isDeleteModalOpen}
        memberId={id}
        handleClose={() => setDeleteModalOpen(false)}
      />
    </StudentHeader>
  );
};

const mapStateToProps = state => ({
  student: getStudentState(state),
});

const mapDispatchToProps = dispatch => ({
  toggleModalHandler: newModalState => dispatch(toggleModal(newModalState)),
  spawnModalHandler: action => dispatch(spawnModal(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCardHeader);
