import styled from 'styled-components';
import { DESKTOP, PAD_S } from 'breakpoints';

const Main = styled.main`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.6rem;
  padding-bottom: 50px;
  @media (min-width: ${DESKTOP}) {
    max-width: 1580px;
    grid-template-columns: 1fr 1fr auto;
  }
  @media (min-width: ${PAD_S}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default Main;
