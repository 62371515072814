/* eslint-disable no-unused-expressions */
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

const BackIcon = styled(FAIcon)`
  height: 0.67rem;
`;

const IconWrapper = styled.div`
  width: 1.334rem;
  display: flex;
  justify-content: flex-start;
`;

const LinkContainer = styled(Button)`
  display: flex;
  align-items: center;
  ${props => {
    switch (props.direction) {
      case 'forward':
        return `
          flex-direction: row-reverse;
          ${BackIcon} {
            transform: rotate(180deg);
            }
          ${IconWrapper} {
              justify-content: flex-end;
            }
        `;
      case 'down':
        return `
          flex-direction: column-reverse;
          ${BackIcon} {
              transform: rotate(-90deg);
              margin-top: 0.334rem;
            }
        `;
      case 'up':
        return `
          flex-direction: column;
          ${BackIcon} {
            transform: rotate(90deg);
            margin-bottom: 0.334rem;
          }
        `;
      default:
        return '';
    }
  }}
  ${props =>
    props.iconStyle &&
    `
    ${IconWrapper} {
      ${props.iconStyle}
      }
  `}
  padding: 0;
  min-width: 0;
  cursor: pointer;
  font-weight: lighter;
  color: var(--font-color-primary, black);
  transition: all 150ms ease-in;

  &:hover {
    text-decoration: underline;
    background-color: var(--color-lighter);
    filter: brightness(95%);
  }
`;

const ArrowButton = ({ direction, children, ...args }) => (
  <LinkContainer direction={direction} {...args}>
    <IconWrapper>
      <BackIcon icon={faArrowLeft} aria-labelledby={`go ${direction}`} />
    </IconWrapper>
    {children}
  </LinkContainer>
);

export default ArrowButton;
