import React from 'react';
// import styled from 'styled-components';
import normalize from 'jsonapi-normalizer';
import { useDispatch, useSelector } from 'react-redux';
import { getOldRegistrationData, getClassGraduationYear } from 'selectors';
import { addStudent } from 'actions';
import * as api from 'api';
import { setRegistrationBody } from '../redux/helpers';

const useInviteStudent = classId => {
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [student, setStudent] = React.useState(undefined);
  const oldRegistrationData = useSelector(getOldRegistrationData);
  const graduationYear = useSelector(getClassGraduationYear);

  React.useEffect(() => {
    const fetchStudent = async () => {
      setIsError(false);
      setIsSuccess(false);
      setIsLoading(true);
      try {
        const token = api.getToken();

        const personalData = {
          ...oldRegistrationData,
          ...formData,
          graduationYear,
        };
        const oldRequestData = setRegistrationBody(personalData);
        const addStudentOld = await api.oldUserApi.post(
          '/registrations',
          JSON.stringify(oldRequestData),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
        );
        const newRequestData = {
          data: {
            attributes: {
              userId: addStudentOld.data.user.id,
            },
          },
        };
        const addStudentNew = await api.userApi.post(
          `/classrooms/${classId}/members`,
          newRequestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const normalisedResponse = normalize(addStudentNew.data);
        const {
          entities: { classroomMembers, students },
          result,
        } = normalisedResponse;

        const studentId = result.classroomMembers[0];
        const addedMember = classroomMembers[studentId];
        const addedStudent = students[addedMember.userUuid];
        const studentData = { ...addedStudent, ...addedMember };
        dispatch(addStudent(studentData));
        setStudent(studentData);
        setIsSuccess(true);
      } catch (error) {
        const errorMsg = error?.response?.data?.errors[0]?.detail ?? '';
        setIsError(errorMsg || true);
      }
      setIsLoading(false);
      setFormData(null);
    };
    if (formData) fetchStudent();
  }, [formData]);

  return [{ isLoading, isError, isSuccess, student }, setFormData];
};

export default useInviteStudent;
