/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { Paper } from 'atoms';

const passPropsToChildren = (props, children) =>
  React.Children.map(children, child => React.cloneElement(child, props));

const Options = styled(Paper)`
  display: none;
  ${props => props.visible && 'display: block;'}
  background-color: white;
  position: absolute;
  z-index: 1;
  margin-top: 2.5rem;
  margin-left: 1rem;
  padding: 1rem 0;
  border-radius: 0;
  min-width: 100%;
  right: 0;
  max-height: 20vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

const DropdownOptions = ({ turnInvisible, visible, children, ...args }) => {
  const mappedChildren = passPropsToChildren(
    {
      turnInvisible: () => turnInvisible(),
    },
    children,
  );
  return (
    <Options elevation="1" visible={visible} {...args}>
      {mappedChildren}
    </Options>
  );
};

export default DropdownOptions;
