/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import tracker from 'tracker';
import { SkeletonCard } from 'templates';
import uuid4 from 'uuid';
import { Paragraph } from 'Typography';
import { useSelector } from 'react-redux';
import { studentsSelectors } from 'selectors';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/Error';
import { Grid } from '@material-ui/core';
import InviteStudentCard from './StudentCards/InviteStudentCard/InviteStudentCard';
import StudentCard from './StudentCards/StudentCard';
import PendingStudentCard from './StudentCards/PendingStudentCard';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: var(--font-color-faded);
`;

const StudentsSkeleton = () =>
  [0, 0, 0, 0].map(() => (
    <Grid item xs={12} sm={6} key={uuid4()}>
      <SkeletonCard primary />
    </Grid>
  ));

const Students = () => {
  const { t } = useTranslation();
  const loading = useSelector(state => state.students.loading);
  const error = useSelector(
    state => state.classroomState.error === 'unauthorized' || state.students.error,
  );
  const classroomStudents = useSelector(studentsSelectors.selectAll);
  useEffect(() => {
    tracker.trackPageView('/klasse', 'Visits - P 5.1 - Klassenübersicht');
  }, []);

  return (
    <Grid container spacing={3}>
      {loading && <StudentsSkeleton />}
      {error && (
        <ErrorContainer type="error">
          <Paragraph>{t('no_access_to_class')}</Paragraph>
          <ErrorIcon fontSize="large" />
        </ErrorContainer>
      )}
      {!loading && !error && (
        <>
          <InviteStudentCard />
          {classroomStudents &&
            classroomStudents.map(student => (
              <Grid item xs={12} sm={6} key={uuid4()}>
                {student.confirmed ? (
                  <StudentCard student={student} key={uuid4()} />
                ) : (
                  <PendingStudentCard student={student} key={uuid4()} />
                )}
              </Grid>
            ))}
        </>
      )}
    </Grid>
  );
};

export default Students;
