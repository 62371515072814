import { faPen } from '@fortawesome/free-solid-svg-icons';
import { fetchClasses, initModalData, spawnModal, toggleModal } from 'actions';
import { TrashIconButton } from 'atoms';
import { StudentNumbers, TeachersList, Teaser } from 'molecules';
import { Dropdown, DropdownHeader, DropdownOption, DropdownOptions } from 'organisms';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from 'Typography';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import uuid4 from 'uuid';
import { ColoredIcon, InfoSegment } from './styles';

const getClassName = (name, graduationYear) =>
  `Klasse ${name}, Abschluss ${graduationYear || '2021'}`;

const ManageClassCard = ({
  classroomInfo,
  classes,
  spawnModalHandler,
  toggleModalHandler,
  loadModalData,
  modalData,
  isClassLoading,
  loadClasses,
  teachersData,
  teachersIncluded,
  isUnauthorized,
  ...args
}) => {
  const { id } = useParams();

  useEffect(() => {
    toggleModalHandler(false);
  }, []);

  useEffect(() => {
    if (!classes.length) {
      loadClasses();
    }
  }, [classes]);

  const showEditModal = () => {
    toggleModalHandler(false);
    loadModalData('EditClass', modalData);
    spawnModalHandler('EditClass');
    toggleModalHandler(true);
  };

  const showDeleteClassModal = () => {
    toggleModalHandler(false);
    spawnModalHandler('DeleteClass');
    toggleModalHandler(true);
  };

  const showInviteModal = () => {
    toggleModalHandler(false);
    spawnModalHandler('InviteTeacher');
    toggleModalHandler(true);
  };

  const { t } = useTranslation();
  const { graduationYear, name, studentsConfirmed, studentsUnconfirmed } = classroomInfo;

  const classNums = [
    {
      number: studentsConfirmed,
      text: t('manage_class_caption_1'),
    },
    {
      number: studentsUnconfirmed,
      text: t('manage_class_caption_2'),
    },
  ];

  const classname = isUnauthorized
    ? `${t('choose_option')}...`
    : getClassName(name, graduationYear, isUnauthorized);

  const mergedTeachersList = useMemo(
    () => teachersIncluded.filter(incl => teachersData.some(teacher => teacher.id === incl.id)),
    [teachersData, teachersIncluded],
  );

  return (
    <Teaser primary elevation="1" css="padding: 1rem 1.6rem;" {...args}>
      <InfoSegment hidden={isUnauthorized}>
        <Heading
          type="h5"
          css={`
            display: grid;
            grid-template-columns: 1fr auto auto;
            grid-gap: 1rem;
            align-items: center;
            overflow: ellipses;
          `}
          {...args}
        >
          <Dropdown>
            <DropdownHeader>{isClassLoading ? '...' : classname}</DropdownHeader>
            <DropdownOptions>
              {classes
                .filter(classroom => classroom.id !== id)
                .map(link => (
                  <DropdownOption key={uuid4()}>
                    <Link
                      to={`/klasse/${link.id}`}
                      css={`
                        display: flex;
                        text-decoration: none;
                        padding: 0.5rem 0.75rem;
                        color: initial;
                        width: 100%;
                        height: 100%;
                      `}
                      replace
                      onClick={() => {
                        if (!classes.length) loadClasses();
                      }}
                    >
                      {getClassName(link.attributes.name, link.attributes.graduationYear)}
                    </Link>
                  </DropdownOption>
                ))}
            </DropdownOptions>
          </Dropdown>
          {!isUnauthorized && (
            <>
              <ColoredIcon icon={faPen} onClick={showEditModal} />
              <TrashIconButton onClick={showDeleteClassModal} />
            </>
          )}
        </Heading>
        <StudentNumbers stats={classNums} />
      </InfoSegment>
      {!isUnauthorized && (
        <InfoSegment>
          <Heading type="h5">{t('manage_class_list_users')}</Heading>
          <TeachersList
            showInviteModal={showInviteModal}
            teachers={mergedTeachersList}
            toggleModal={toggleModalHandler}
          />
        </InfoSegment>
      )}
    </Teaser>
  );
};

const mapStateToProps = state => ({
  classroomInfo: state.classroomState.classroomData.attributes,
  teachersData: state.classroomState.classroomData.relationships.addedTeachers.data,
  teachersIncluded: state.classroomState.included,
  classes: state.classesState.classes,
  modal: state.modalState,
  modalData: state.classroomState,
  isClassLoading: state.classroomState.fetching,
  isUnauthorized: state.classroomState.error === 'unauthorized',
});

const mapDispatchToProps = dispatch => ({
  spawnModalHandler: action => dispatch(spawnModal(action)),
  toggleModalHandler: newModalState => dispatch(toggleModal(newModalState)),
  loadModalData: (key, modalData) => dispatch(initModalData(key, modalData)),
  loadClasses: () => dispatch(fetchClasses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageClassCard);
