/* eslint-disable quote-props */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { Cookies } from 'react-cookie';

export const cookie = new Cookies();

export const domain = process.env.NODE_ENV === 'production' ? '.yousty.ch' : 'localhost';

export const getItemFromStorage = name => JSON.parse(localStorage.getItem(name));

export const saveToken = token => {
  localStorage.setItem('cookietoken', token, 'domain', domain);
  cookie.set('yousty_token', token, {
    path: '/',
    domain,
  });
  localStorage.setItem('yousty_api_token', token);
};

export const getToken = () => {
  const token = localStorage.getItem('yousty_api_token');
  const tokenString = token ? token.toString() : '';
  return tokenString;
};

export const domainAPI =
  process.env.REACT_APP_API_HOST ||
  (process.env.NODE_ENV === 'production' ? 'https://www.yousty.ch' : 'http://localhost:3000');

export const getUri = () =>
  process.env.NODE_ENV !== 'production' && process.env.REACT_APP_API_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL
    : `${domainAPI}/api/v2`;

export const getOldUri = () =>
  process.env.NODE_ENV !== 'production' && process.env.REACT_APP_API_BASE_OLD_URL
    ? process.env.REACT_APP_API_BASE_OLD_URL
    : `${domainAPI}/de-CH/api`;

export const headers = {
  'Content-Type': 'application/json',
  content: 'application/json',
  timeout: 5000,
};

export const userApi = axios.create({
  baseURL: getUri(),
  headers: { ...headers },
});

export const oldUserApi = axios.create({
  baseURL: getOldUri(),
  headers: { ...headers, content: 'application/x-www-form-urlencoded' },
});

export const postUser = user => {
  const rqBody = { data: { attributes: { ...user } } };
  return axios.post('/auth/tokens', rqBody, {
    baseURL: getUri(),
    headers: { 'Content-Type': 'application/json' },
  });
};

export const saveToStorage = (name, object) => {
  localStorage.setItem(name, JSON.stringify(object));
};

export const Base64ToString = (buffer, stringType = 'ascii') =>
  Buffer.from(buffer.toString(), 'base64').toString(stringType);

export const StringToBase64 = string => Buffer.from(string).toString('base64');

export const isAuthorized = user => user.scopes && user.scopes.includes('ccr');
