import React from 'react';
import uuid4 from 'uuid';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, Spinner } from 'atoms';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useFetchPhotosLink } from 'utils';
import { photosSelectors } from '../redux/selectors';

const FetchingLink = styled(Link)`
  text-decoration: underline;

  ${props =>
    !props.active &&
    css`
      color: gray;
      text-decoration: none;
    `}
`;

const Icons = styled.div`
  display: flex;

  ${Spinner} {
    display: none;
  }

  ${props =>
    props.fetching &&
    css`
      ${Spinner} {
        display: flex;
      }
    `}
`;

const ResLink = styled.div`
  display: flex;
  align-items: center;

  ${Icons} {
    margin-left: 0.5rem;
  }

  flex-direction: row-reverse;

  ${Icons} {
    margin-right: 0.5rem;
  }
`;

const FetchAllPhotosLink = () => {
  const photosLoading = useSelector(state => state.classroomPhotos.loading);
  const photosNo = useSelector(photosSelectors.selectTotal);
  const [{ loading, url }, fetchPhotos] = useFetchPhotosLink();

  const { t } = useTranslation();
  if (photosNo > 0 && !photosLoading)
    return (
      <ResLink key={uuid4()}>
        <FetchingLink
          active={url}
          fetching={loading}
          href={url}
          onClick={e => {
            if (!url) {
              e.preventDefault();
              fetchPhotos();
            }
          }}
        >
          {t('photos_all_link')}
        </FetchingLink>
        <Icons fetching={loading} active={url}>
          <Spinner />
          {url && <FAIcon css="color: var(--color-primary)" icon={faDownload} />}
        </Icons>
      </ResLink>
    );
  return null;
};

export default FetchAllPhotosLink;
