import styled from 'styled-components';

const ProgressBar = styled.div`
  height: 1rem;
  width: 100%;
  border-radius: 1rem;
  color: white;
  text-align: center;
  position: relative;
  background-color: var(--color-lighter, #e8e8e8);
  :after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: ${props => props.progress || '0'}%;
    z-index: 2;
    height: 100%;
    border-radius: 1rem;
    background-color: ${props => {
      if (props.progress < 40) {
        return 'var(--color-red, #D03D59)';
      }
      if (props.progress >= 40 && props.progress < 80) {
        return 'var(--color-orange, #e6a54b)';
      }
      return 'var(--color-green, #1FD581)';
    }};
  }
`;

export default ProgressBar;
