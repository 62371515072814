import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { selectClasses } from 'selectors';

import { Paper } from 'atoms';
import { ClassCard } from 'molecules';
import { Heading } from 'Typography';
import * as bp from 'breakpoints';
import uuid4 from 'uuid';
import { useTranslation } from 'react-i18next';

const ClassesGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-column: 1 / -1;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(auto-fill, minmax(254px, 1fr));
  @media (min-width: ${bp.DEFAULT}) {
    grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
  }
`;

const Classes = styled(Paper)`
  grid-area: article;
  padding: 1.33rem 1.65rem;
  display: grid;
  grid-gap: 1.65rem;
`;

const ClassesDisplay = ({ classes, ...args }) => {
  const { t } = useTranslation();
  return (
    <Classes
      css={!classes.classes.length && !classes.fetching && 'display: none;'}
      {...args}
      as="article"
      primary
      elevation="2"
    >
      <Heading type="h5">{t('active_classes')}</Heading>
      <ClassesGrid>
        {classes.classes
          .sort((a, b) => (a.attributes.name[0] > b.attributes.name[0] ? 1 : -1))
          .sort((a, b) => (a.attributes.graduationYear > b.attributes.graduationYear ? 1 : -1))
          .map(classData => (
            <ClassCard key={uuid4()} elevation="1" classData={classData} />
          ))}
      </ClassesGrid>
    </Classes>
  );
};

const mapStateToProps = state => ({
  classes: selectClasses(state),
});

export default connect(mapStateToProps)(ClassesDisplay);
