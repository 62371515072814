import React from 'react';
import styled from 'styled-components';
import { Stat } from 'molecules';
import uuid4 from 'uuid';

const StatsHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

/* very vague naming */
const StudentNumbers = ({ stats }) => (
  <StatsHolder>
    {stats.map(stat => (
      <Stat value={stat.number} caption={stat.text} key={uuid4()} />
    ))}
  </StatsHolder>
);

export default StudentNumbers;
