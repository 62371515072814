import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserData } from 'selectors';
import { fetchUser } from 'actions';
import AuthLayout from './AuthLayout';
import RegistrationForm from './RegistrationForm';

export const Register = () => {
  const userSignedIn = useSelector(state => getUserData(state).signedIn);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t } = useTranslation();
  const from = `/${t('roadmap')}`;
  return userSignedIn ? (
    <Redirect to={from} />
  ) : (
    <AuthLayout>
      <RegistrationForm
        onRegister={() => {
          dispatch(fetchUser());
          push('/auth/add_classroom');
        }}
      />
    </AuthLayout>
  );
};

export default Register;
