import React from 'react';
import RegisterClassForm from './RegisterClassForm';
import AuthLayout from './AuthLayout';

const AddClassroom = () => {
  return (
    <AuthLayout>
      <RegisterClassForm />
    </AuthLayout>
  );
};

export default AddClassroom;
