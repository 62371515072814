import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Aside,
  Main,
  ManageClassCard,
  ClassActivities,
  InviteCard,
  BackButton,
  StudentToolbox,
} from 'templates';
import { DynamicTeaser } from 'organisms';
import { DESKTOP, PAD_S, XL, PAD_L } from 'breakpoints';
import { getConfirmationBarStatus } from 'selectors';

import { resetStudents, getClassroomStudents, getClassroom } from 'actions';
import CardDisplay from '../../CardDisplay';

const ClassAside = styled(Aside)`
  grid-column: unset;
  grid-template-columns: 1fr;
  order: 1;
  grid-template-areas: none;

  @media (min-width: ${PAD_S}) {
    grid-column: 1 / -1;
    grid-template-columns: 1fr;
  }

  @media (min-width: ${DESKTOP}) {
    grid-template-areas: initial;
    grid-template-columns: 1fr;
    order: 2;
    max-width: 23.07rem;
    grid-column: 3 / -1;
  }
`;

const AsideAds = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-auto-rows: initial;
  grid-template-columns: 1fr;
  padding-top: ${props => (props.extraTopPadding ? 'calc(6rem + 50px)' : '6rem')};

  @media (min-width: ${PAD_S}) {
    grid-template-columns: 1fr 1fr;
    padding-top: 10rem;
  }

  @media (min-width: ${DESKTOP}) {
    grid-auto-rows: min-content;
    grid-template-columns: initial;
    padding-top: 4.05rem;
  }
`;

const ClassMain = styled(Main)`
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 0 1.67rem 1.134rem 1.67rem;

  @media (min-width: ${PAD_S}) {
    grid-gap: 1.3rem;
    grid-template-columns: 1fr;
    padding: 0 1.34rem 1.134rem 6rem;
  }

  @media (min-width: ${PAD_L}) and (max-width: ${DESKTOP}) {
    max-width: 90vw;
    margin: 0 auto;
  }

  @media (min-width: ${DESKTOP}) {
    grid-template-columns: 1fr auto;
    margin: 0 auto 0 9.67rem;
    padding: 1.134rem 1.34rem 1.34rem 0;
  }

  @media (min-width: ${XL}) {
    margin: 0 auto;
  }

  @media (min-width: 1690px) and (max-width: 1800px) {
    padding: 1.134rem 1.34rem 1.34rem 6rem;
  }

  ${props =>
    props.extraTopPadding &&
    css`
      @media (min-width: ${DESKTOP}) {
        padding: 6rem 1.34rem 1.34rem 0;
      }
    `};
`;

const ClassSubGrid = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-auto-rows: min-content;
`;

const LinkHolder = styled.div`
  display: flex;

  @media (min-width: ${PAD_S}) {
    display: none;
  }
`;

const BButton = styled(BackButton)`
  width: max-content;
  padding: 0 0.5rem;
`;

export const ClassOverview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const showConfirmationBar = useSelector(getConfirmationBarStatus);

  useEffect(() => {
    dispatch(getClassroom(id));
    const promise = dispatch(getClassroomStudents(id));
    return () => {
      promise.abort();
      dispatch(resetStudents());
    };
  }, [id]);

  return (
    <ClassMain extraTopPadding={showConfirmationBar}>
      <CardDisplay />
      <ClassAside>
        <AsideAds extraTopPadding={showConfirmationBar}>
          <LinkHolder>
            <BButton to={`/${t('classes')}`}>{t('back_to_classoverview')}</BButton>
          </LinkHolder>
          <ManageClassCard />
          <ClassSubGrid>
            <InviteCard />
            <DynamicTeaser
              textContent={{
                header: t('dynamic_teaser_heading'),
                text: t('dynamic_teaser_text'),
              }}
              link={{
                to: 'https://www.yousty.ch/de-CH/vorlagen',
                text: t('dynamic_teaser_link_text'),
              }}
              gaEvent="Clicks - Teaser - Vorlagen"
              hideTextMobile
              hideMobile
            />
          </ClassSubGrid>
          <ClassActivities />
        </AsideAds>
        <StudentToolbox />
      </ClassAside>
    </ClassMain>
  );
};

export default ClassOverview;
