/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { DEFAULT, PAD_S } from 'breakpoints';

const Button = styled.button`
  ${props =>
    props.hideMobile &&
    css`
      @media (max-width: ${PAD_S}) {
        display: none;
      }
    `}
  font-family: var(--font-primary);
  font-size: 1rem;
  height: 2.67rem;
  cursor: pointer;
  min-width: 9.34rem;
  padding: 0 1rem;
  border: none;
  box-shadow: none;
  border-radius: 0.4rem;
  text-decoration: none;
  transition: all 150ms;
  -webkit-appearance: none;
  :hover {
    filter: brightness(125%);
  }
  &:focus {
    outline: none;
  }
  * {
    text-decoration: none;
    color: inherit;
  }
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}
  ${props => {
    if (props.primary) {
      return css`
        color: var(--color-secondary);
        background-color: var(--color-primary, black);
        box-shadow: 0 1px 2px var(--color-tertiary);
      `;
    }
    if (props.secondary) {
      return css`
        color: var(--color-primary, black);
        background-color: var(--color-secondary);
        border: 1px solid var(--color-primary, black);
        box-shadow: 0 1px 2px var(--color-primary, black);
      `;
    }
    if (props.tertiary) {
      return css`
        color: inherit;
        background: none;
        text-shadow: none;
        font-size: inherit;
        font-family: inherit;
        text-decoration: underline;
      `;
    }
    return css`
      color: var(--color-secondary);
      background-color: transparent;
    `;
  }};
  ${props =>
    props.disabled &&
    css`
      color: rgba(255, 255, 255, 0.8);
      background-color: var(--color-tertiary);
      border: none;
      box-shadow: none;
      cursor: not-allowed;

      &:hover {
        filter: none;
      }
    `};
  ${props =>
    props.withHover &&
    css`
      &:hover {
        box-shadow: 0 3px 2px ${props.secondary ? 'var(--color-primary)' : 'var(--color-tertiary)'};
        ${props.tertiary &&
          css`
            box-shadow: none;
            background-color: var(--color-tertiary, grey);
          `}
      }
    `};
  ${props =>
    props.mobileFullWidth &&
    css`
      @media (max-width: ${DEFAULT}) {
        width: 100%;
      }
    `};
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};
  ${props => props.area && `grid-area: ${props.area};`};
  ${props => props.flexAlign && `align-self: ${props.flexAlign}; `};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`}
  ${props => props.flex && 'display: flex;'};
  ${props =>
    props.withShadow &&
    css`
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    `}
  ${props =>
    props.elevation &&
    css`
      box-shadow: var(--elevation- ${props.elevation}, var(--elevation-default));
    `}
  ${props => props.inlineFlex && 'display: inline-flex; align-items: center;'}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

Button.Toggle = styled(Button)`
  display: flex;
  padding: 0;
  z-index: 5;
  min-width: 0;
`;

Button.Open = styled(Button)`
  line-height: 1.67rem;
  min-width: 3rem;
  max-width: 3rem;
  border-radius: 0 5px 5px 0;
  max-height: none;
  width: 100%;
  height: 100%;
  @media (min-width: ${DEFAULT}) {
    min-width: 3rem;
    max-height: 3rem;
    border-radius: 5px;
    margin: 1rem;
    align-self: center;
  }
`;

export default Button;
