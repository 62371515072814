import { actionChannel, call, put, take, delay, select } from 'redux-saga/effects';
import { getTokenStatus } from 'selectors';
import { errorTypes } from '../rootTypes';
import { userLogout } from '../user/actions';

// eslint-disable-next-line import/prefer-default-export
export function* watchErrors() {
  const errorChannel = yield actionChannel(errorTypes.api);
  const { isTokenTimedOut } = yield select(getTokenStatus);

  while (true) {
    const { payload } = yield take(errorChannel);
    if (payload && payload.data) {
      const { detail } = payload.data.errors[0];
      if (detail === 'Token expired' && !isTokenTimedOut) {
        yield put({ type: errorTypes.apiTimeoutError });
        yield delay(5000);
        errorChannel.close();
        yield call(userLogout);
      }
    }
  }
}
