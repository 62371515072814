import styled from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default styled(FAIcon).attrs({ icon: faArrowRight })`
  filter: drop-shadow(0 0 1.5px var(--color-primary, #6a205f));
  color: var(--color-secondary);
  background-color: var(--color-primary);
  && {
    height: 2em;
    width: 2em;
  }
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0.334em;
`;
