import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const Boundary = styled.a`
  cursor: pointer;
  background: none;
  box-sizing: border-box;
  border: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 3px 6px var(--color-background);
  text-decoration: none;
  color: var(--font-color-primary, #333333);
`;

const PlayIcon = styled(FAIcon)`
  align-self: center;
  margin-left: 0.2rem;
  width: 0.7rem !important; /* faicons come with very specific styleset, better workaround needed */
`;

const PlayButton = ({ ...args }) => (
  <Boundary {...args}>
    <PlayIcon
      css={`
        align-self: center;
        margin-left: 0.2rem;
        width: 2.134rem;
        height: 2.134rem;
      `}
      icon={faPlay}
    />
  </Boundary>
);

export default PlayButton;
