import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import uuid5 from 'uuid';
import { useTranslation } from 'react-i18next';
import tracker from 'tracker';

import { Button } from 'atoms';
import {
  Dropdown,
  DropdownHeader,
  DropdownOption,
  DropdownOptions,
  ContentTeaser,
} from 'organisms';
import { DESKTOP } from 'breakpoints';
import { Heading } from 'Typography';
import { getActivePhase, getEducationPhases, getTeasers } from 'selectors';

import { setActivePhase } from 'actions';

const FilteredDisplay = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  @media (min-width: ${DESKTOP}) {
    grid-auto-rows: min-content;
  }
  a {
    height: 100%;
  }
`;

const DisplayHeader = styled(Heading).attrs({ type: 'h2' })`
  grid-column: 1 / -1;
  color: var(--font-color-primary);
`;

const Loader = () => (
  <>
    <ContentTeaser.Loading />
    <ContentTeaser.Loading />
    <ContentTeaser.Loading />
    <ContentTeaser.Loading />
    <ContentTeaser.Loading />
    <ContentTeaser.Loading />
  </>
);

const RoadmapResults = ({ teasers, fetching, active, options, setPhaseAsActive, ...args }) => {
  const { t } = useTranslation();
  return (
    <FilteredDisplay {...args}>
      <DisplayHeader margin="0">
        {t('filter_by')}
        <Dropdown
          css={`
            margin: 0 0.5rem;
          `}
        >
          <DropdownHeader>
            <Button tertiary padding="0">
              {active.text}
            </Button>
          </DropdownHeader>
          <DropdownOptions>
            {options.map(option => (
              <DropdownOption
                key={uuid5()}
                onOptionClick={() => {
                  tracker.trackEvent(`Clicks - P3.0 - ${option.text}`);
                  setPhaseAsActive(option.name);
                }}
                active={option.active}
              >
                {option.text}
              </DropdownOption>
            ))}
          </DropdownOptions>
        </Dropdown>
      </DisplayHeader>
      {fetching ? (
        <Loader />
      ) : (
        teasers.map(teaser => (
          <ContentTeaser
            elevation="1"
            key={uuid5()}
            headerText={teaser.attributes.title}
            text={teaser.attributes.description}
            linkTo={teaser.attributes.link}
          />
        ))
      )}
    </FilteredDisplay>
  );
};

const mapDispatchToProps = dispatch => ({
  setPhaseAsActive: phaseName => {
    dispatch(setActivePhase(phaseName));
  },
});

const mapStateToProps = state => ({
  fetching: state.roadmapState.fetching,
  active: getActivePhase(state),
  options: getEducationPhases(state),
  teasers: getTeasers(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoadmapResults);
