/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InputField, Form, Card } from 'molecules';
import { Input, Button, ToggleButton } from 'atoms';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownHeader, DropdownOption, DropdownOptions } from 'organisms';
import uuid4 from 'uuid';
import { connect } from 'react-redux';
import { updateClassroom } from 'actions';
import { GridRows } from './Modal';
import { schoolTypes, districts, capitalizeSentence } from '../ClassForm/classFormUtils';
import { objectKeysIncludeString } from '../../../utils';
import { years } from '../../../utils/class';

const FormGrid = styled(Form)`
  display: grid;
  grid-gap: 0.47rem;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 1.54rem;
  padding: 1.2rem 0.8rem;
`;

const SmallButton = styled(Button)`
  min-width: 6.14rem;
`;

const EditClassModal = ({ classroomState, updateClassroomHandler, toggleLocalModal, ...args }) => {
  const { name, state, schoolLevel, graduationYear } = classroomState.classroomData.attributes;
  const classId = classroomState.classroomData.id;
  const { error } = classroomState;

  const { t } = useTranslation();
  const [className, changeClassName] = useState(name);

  const [schoolType, changeSchoolType] = useState(schoolLevel.toLowerCase());
  const [schoolErr, schoolValidation] = useState({ type: 'none' });

  const [district, changeState] = useState(state.toLowerCase());
  const [stateErr, stateValidation] = useState({ type: 'none' });

  const [endYear, changeEndYear] = useState(graduationYear);
  const [endYearErr, endYearSelected] = useState({ type: 'none' });

  const [filteredSchools, setSchools] = useState([]);

  const submitReady =
    className.length > 0 &&
    district.length > 0 &&
    stateErr.type === 'none' &&
    schoolType.length > 0 &&
    schoolErr.type === 'none' &&
    endYearErr.type === 'none';

  useEffect(() => {
    setTimeout(() => {
      district.length === 0 || objectKeysIncludeString(districts, district)
        ? stateValidation({ type: 'none' })
        : stateValidation({
            type: 'warning',
            errors: [{ detail: 'err_not_a_district' }],
          });
    }, 300);
  }, [district]);

  useEffect(() => {
    setTimeout(() => {
      schoolType.length === 0 || objectKeysIncludeString(schoolTypes, schoolType)
        ? schoolValidation({ type: 'none' })
        : schoolValidation({
            type: 'warning',
            errors: [{ detail: 'err_not_a_schooltype' }],
          });
    }, 300);
  }, [schoolType]);

  useEffect(() => endYearSelected({ type: 'none' }), [endYear]);

  useEffect(() => {
    setTimeout(() => {
      district.length === 0 || objectKeysIncludeString(districts, district)
        ? stateValidation({ type: 'none' })
        : stateValidation({
            type: 'warning',
            errors: [{ detail: 'err_not_a_district' }],
          });
    }, 300);

    const filteredSchoolTypes = Object.entries(schoolTypes)
      .filter(entry => entry[1].filter(statename => statename.includes(district)).length > 0)
      .sort();

    setSchools(filteredSchoolTypes);
    if (district.length > 0) {
      changeSchoolType(capitalizeSentence(filteredSchoolTypes[0][0]));
    }
  }, [district]);

  const handleFormSubmit = e => {
    e.preventDefault();
    if (submitReady) {
      const formData = {
        name: className,
        state: districts[district.toLowerCase()],
        schoolLevel: schoolType,
        graduationYear: endYear,
      };

      updateClassroomHandler(formData, classId);
      toggleLocalModal(false);
      changeClassName(className);
      changeState(district);
      changeEndYear(endYear);
      changeSchoolType(schoolType);
    }
  };

  return (
    <Card primary>
      <GridRows>
        <Container>
          <FormGrid novalidate autoComplete="off" onSubmit={e => handleFormSubmit(e)}>
            <InputField label={t('edit_class_label_1')} id="class" error={error} required>
              <Input
                label="name"
                required
                id="classname"
                value={className}
                onChange={e => changeClassName(e.target.value)}
                autoComplete="off"
              />
            </InputField>
            <InputField
              gridColumn="span 1"
              fullWidth
              required
              label={t('edit_class_label_2')}
              id="state"
              type="datalist"
              error={error.type !== 'none' ? error : stateErr}
            >
              <Dropdown>
                <DropdownHeader
                  css={`
                    width: 100%;
                    display: flex;
                  `}
                >
                  <Input
                    placeholder={t('edit_class_placeholder_1')}
                    name="state"
                    autoComplete="off"
                    error={error.type !== 'none' ? error : stateErr}
                    css={`
                      width: 100%;
                      color: transparent;
                      text-shadow: 0 0 0 black;
                    `}
                    value={capitalizeSentence(district)}
                    onChange={() => null}
                  />
                </DropdownHeader>
                <DropdownOptions
                  css={`
                    margin-top: 1.86rem;
                  `}
                >
                  {Object.keys(districts).map(option => (
                    <DropdownOption key={uuid4()} onOptionClick={() => changeState(option)}>
                      {capitalizeSentence(option)}
                    </DropdownOption>
                  ))}
                </DropdownOptions>
              </Dropdown>
            </InputField>
            <InputField
              gridColumn="span 1"
              fullWidth
              required
              label={t('edit_class_label_3')}
              id="schoolType"
              type="datalist"
              error={error.type !== 'none' ? error : schoolErr}
            >
              <Dropdown>
                <DropdownHeader
                  css={`
                    width: 100%;
                    display: flex;
                  `}
                >
                  <Input
                    name="state"
                    autoComplete="off"
                    onChange={() => null}
                    value={capitalizeSentence(schoolType)}
                    placeholder={Object.keys(schoolTypes)[0]}
                    error={error.type !== 'none' ? error : schoolErr}
                    css={`
                      width: 100%;
                      color: transparent;
                      text-shadow: 0 0 0 black;
                    `}
                  />
                </DropdownHeader>
                <DropdownOptions
                  css={`
                    margin-top: 1.86rem;
                  `}
                >
                  {filteredSchools.map(option => (
                    <DropdownOption
                      key={uuid4()}
                      onOptionClick={() => changeSchoolType(capitalizeSentence(option[0]))}
                    >
                      {capitalizeSentence(option[0])}
                    </DropdownOption>
                  ))}
                </DropdownOptions>
              </Dropdown>
            </InputField>
            <InputField
              gridColumn="1 / -1"
              fullWidth
              label={t('edit_class_label_4')}
              required
              options={years}
              activeField={endYear}
              id="finishYear"
              error={endYearErr}
              onChange={selectedEndYear => changeEndYear(selectedEndYear)}
            >
              <div
                {...args}
                css={`
                  margin: 0.67rem 0;
                  display: grid;
                  grid-gap: 0.67rem;
                  grid-template-columns: repeat(auto-fit, minmax(4rem, min-content));
                `}
              >
                {years.map(option => (
                  <ToggleButton
                    {...args}
                    key={uuid4()}
                    type="button"
                    active={option.toString() === (endYear && endYear.toString())}
                    color="primary"
                    onClick={e => changeEndYear(e.target.innerText)}
                    css={`
                    color: grey;
                    height: fit-content;
                    min-width: 0;
                    line-height: 1.67rem;
                    ${props => props.active && 'color: var(--font-color-secondary);'}
                    }}
                  `}
                  >
                    {option}
                  </ToggleButton>
                ))}
              </div>
            </InputField>
            <div>
              <SmallButton
                type="submit"
                primary={submitReady}
                css={
                  !submitReady &&
                  `
          cursor: default;
          background-color: var(--color-tertiary);
          border-color: var(--color-tertiary);
          color: rgba(255,255,255,0.8);
          box-shadow: var(--elevation-none);
        `
                }
              >
                {t('edit_class_modal_button')}
              </SmallButton>
            </div>
          </FormGrid>
        </Container>
      </GridRows>
    </Card>
  );
};

const mapStateToProps = state => ({
  classroomState: state.classroomState,
});

const mapDispatchToProps = dispatch => ({
  updateClassroomHandler: (data, id) => dispatch(updateClassroom(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClassModal);
