import styled from 'styled-components';
import Button from '../Button/Button';

const ToggleButton = styled(Button)`
  color: var(--color-primary, black);
  background-color: white;
  border-width: 1px;
  border-style: solid;
  ${props =>
    props.color &&
    `
    color: var(--color-${props.color}, ${props.color});
    border-color: var(--color-${props.color},${props.color});
    box-shadow: 0 0 2px 0px var(--color-tertiary, black), 
    0 4px 3px 0 var(--color-tertiary, black);
    :hover {
      color: white;
      background-color: var(--color-${props.color}, ${props.color});
      box-shadow: 0 0 2px 0px  var(--color-tertiary, black),
          0 2px 4px 0  var(--color-tertiary, black);
    }
  `}
  ${props =>
    props.active &&
    `
    color: white;
    background-color: var(--color-${props.color}, ${props.color});
    box-shadow: var(--elevation-default);
    :hover {
      box-shadow: none;
    }
  `}
`;

export default ToggleButton;
