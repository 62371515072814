import React, { useEffect } from 'react';
import styled from 'styled-components';

import { DESKTOP } from 'breakpoints';

import { getRoadmapTeasers } from 'actions';
import { connect } from 'react-redux';
import { SecondaryTeaser } from 'organisms';
import teaserBg from 'assets/images/patterned_background.jpg';
import { useTranslation } from 'react-i18next';

import RoadmapTimetable from '../RoadmapTimetable/RoadmapTimetable';
import RoadmapResults from '../RoadmapResults/RoadmapResults';

const RoadmapContent = styled.article`
  grid-column: 1/-1;
  display: grid;
  grid-gap: 1.34rem;
  @media (min-width: ${DESKTOP}) {
    grid-column: span 2;
    grid-auto-rows: min-content;
  }
`;

const StyledSecondaryTeaser = styled(SecondaryTeaser)`
  justify-content: space-between;
  background: linear-gradient(to bottom, rgba(106, 32, 95, 0.85) 0%, rgba(106, 32, 95, 0.85) 100%),
    url(${teaserBg}) repeat 0 0;
  background-repeat: no-repeat;
  background-position: 0% 35%;
  background-size: auto;
  gap: 1rem;

  button {
    margin-left: auto;
    margin-top: 0;
  }
`;

const RoadmapArticle = ({ userState, fetchContent, ...args }) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchContent();
  }, [userState]);

  return (
    <RoadmapContent {...args}>
      <RoadmapTimetable />
      <StyledSecondaryTeaser
        textContent={{
          header: t('roadmap_ad_teaser_top_header'),
        }}
        link={{
          to:
            'https://yousty-switzerland.s3.eu-west-1.amazonaws.com/pdfs/Arbeitsdossier+-+Berufswahlunterricht.pdf',
          text: t('roadmap_ad_teaser_top_button'),
        }}
        gaEvent="Clicks - Teaser - Zusatzinformationen und Musterdokumente"
        newTab
      />
      <RoadmapResults />
    </RoadmapContent>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(getRoadmapTeasers()),
});

const mapStateToProps = state => ({
  userState: state.userState.signedIn,
});

export default connect(mapStateToProps, mapDispatchToProps)(RoadmapArticle);
