import * as React from 'react';
import * as api from 'api';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'actions';

const useLogin = () => {
  const dispatch = useDispatch();
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  React.useEffect(() => {
    const authorizeUser = async () => {
      setError('');
      setIsError(false);
      setIsSuccess(false);
      setIsLoading(true);
      try {
        const response = await api.postUser(user);
        const { token } = response.data.data;
        const data = token.split('.')[1];
        const tokenUser = JSON.parse(api.Base64ToString(data));
        if (!api.isAuthorized(tokenUser)) throw new Error('Unauthorised');
        if (response.status <= 301) {
          api.saveToken(token);
          dispatch(fetchUser());
          setIsLoading(false);
          setIsSuccess(true);
        }
      } catch (err) {
        setIsError(true);
        setError(err.message);
        setIsLoading(false);
      }
    };
    if (user) authorizeUser();
  }, [user]);
  return [{ isLoading, isSuccess, isError, error }, setUser];
};

export default useLogin;
