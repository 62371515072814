import styled from 'styled-components';

const Segment = styled.div`
  span {
    font-weight: 300;
  }
  a {
    line-height: 2.134rem;
  }
  &:first-child {
    border-bottom: 1px solid var(--color-background);
    padding-bottom: 1.384rem;
    * {
      grid-column: span 2;
    }
  }
  &:last-child {
    border-top: 1px solid var(--color-background);
    padding-top: 1.384rem;
  }
`;

export default Segment;
