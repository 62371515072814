/* eslint-disable camelcase */
import * as React from 'react';
import * as api from 'api';
import normalize from 'jsonapi-normalizer';
import tracker from 'tracker';

const useAddClass = () => {
  const [data, setData] = React.useState(null);
  const [newClass, setNewClass] = React.useState(null);
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  React.useEffect(() => {
    const authorizeUser = async () => {
      setData(null);
      setError('');
      setIsError(false);
      setIsSuccess(false);
      setIsLoading(true);
      try {
        tracker.trackEvent('Clicks - Klasse erstellen');
        const token = api.getToken();
        const userData = token.split('.')[1];
        const user = JSON.parse(api.Base64ToString(userData));
        const { user_uuid } = user.data;
        const requestData = {
          data: {
            type: 'classrooms',
            attributes: newClass,
          },
        };
        const postRequest = await api.userApi.post(
          `/teachers/${user_uuid}/classrooms`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setData(Object.values(normalize(postRequest.data).entities.classrooms)[0]);
        setIsSuccess(true);
        setIsLoading(false);
      } catch (err) {
        setIsError(true);
        setError(err.message);
        setIsLoading(false);
      }
    };
    if (newClass) {
      authorizeUser();
    }
  }, [newClass]);
  return [{ isLoading, isSuccess, isError, error, data }, setNewClass];
};

export default useAddClass;
