/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import normalize from 'jsonapi-normalizer';
import * as api from '../api';

export const getClassroomPhotos = createAsyncThunk(
  'classroomPhotos/getStatus',
  async (classId, { rejectWithValue }) => {
    try {
      const token = api.getToken();
      const response = await api.userApi.get(`/classrooms/${classId}/photos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const normalizedData = normalize(response.data);
      const {
        entities: { classroomPhotos },
      } = normalizedData;
      if (classroomPhotos) {
        return classroomPhotos;
      }

      return [];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const photosAdapter = createEntityAdapter();

const initialState = photosAdapter.getInitialState({ loading: false, error: '' });

export const photosSlice = createSlice({
  name: 'classroomPhotos',
  initialState,
  reducers: {
    resetPhotos: () => initialState,
    deletePhoto: photosAdapter.removeOne,
  },
  extraReducers: builder => {
    builder
      .addCase(getClassroomPhotos.pending, state => {
        photosAdapter.setAll(state, {});
        state.loading = true;
      })
      .addCase(getClassroomPhotos.fulfilled, (state, action) => {
        photosAdapter.setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getClassroomPhotos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  actions: { resetPhotos, assignStudent, unassignStudent, deletePhoto },
} = photosSlice;

export const photosSelectors = photosAdapter.getSelectors(state => state.classroomPhotos);

export default photosSlice.reducer;
