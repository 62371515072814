import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Box } from '@material-ui/core';
import { getUserData } from 'selectors';
import LoginForm from './LoginForm';
import AuthLayout from './AuthLayout';

export const Auth = () => {
  const { push } = useHistory();
  const userSignedIn = useSelector(state => getUserData(state).signedIn);
  const { t } = useTranslation();
  const from = `/${t('roadmap')}`;
  React.useEffect(() => {
    if (userSignedIn) {
      push(from);
    }
  });
  return (
    <AuthLayout>
      <LoginForm />
      <Grid
        container
        spacing={2}
        item
        component={props => <Box display="flex" alignItems="center" {...props} />}
      >
        <Grid item xs={8}>
          {t('not_yet_registered')}
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            type="button"
            onClick={() => {
              push('/auth/register');
            }}
          >
            {t('register_account')}
          </Button>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default Auth;
