import React from 'react';
import { useTranslation } from 'react-i18next';
import { years } from 'utils/class';
import Template from './Template';

const Conventions = () => {
  const { t } = useTranslation();
  return (
    <Template
      t={t}
      header={t('ads_conventions_header')}
      subheader={t('ads_conventions_subheader')}
      primaryTeaser={{
        text: t('ads_conventions_text', { year: years[0] }),
        link: {
          text: t('ads_conventions_button_text'),
          href: 'https://www.yousty.ch/de-CH/bewerbungsfoto',
          gaEvent: 'Berufsmessen',
        },
      }}
    />
  );
};

export default Conventions;
