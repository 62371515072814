import styled from 'styled-components';
import { Paragraph } from 'Typography';
import * as bp from 'breakpoints';

const CardText = styled(Paragraph)`
  ${props =>
    props.hideTextMobile &&
    `
      display: none;

       @media (min-width: ${bp.DESKTOP}) {
        display: initial;
      }
  `}
`;

export default CardText;
