import React from 'react';
import tracker from 'tracker';
import { Teaser, LinkButton, CardContent, CardHeader, CardMedia, CardText } from 'molecules';
import * as bp from 'breakpoints';

const MainTeaser = ({ area, image, textContent, gaEvent, link, ...args }) => (
  <Teaser
    primary
    area={area}
    css={`
      display: grid;
      grid-auto-rows: 1fr 1fr;
      @media (min-width: ${bp.DEFAULT}) {
        grid-auto-rows: 2fr 1fr auto;
      }
    `}
    {...args}
  >
    <CardMedia
      image={image.src}
      alt={image.alt}
      css={`
        display: none;
        background-image: none;
        @media (min-width: ${bp.PAD_S}) {
          background-image: url(${image.src});
          display: block;
          height: 150px;
          background-size: cover;
          align-self: center;
        }
      `}
    />
    <CardContent css="padding: 0 1.6rem;">
      <CardHeader>{textContent.header}</CardHeader>
      <CardText>{textContent.inner}</CardText>
    </CardContent>
    <div css="padding: 0 1.6rem">
      <LinkButton
        primary
        mobileFullWidth
        marginTop="1rem"
        flexAlign="flex-end"
        to={link.to}
        onClick={() => tracker.trackEvent(gaEvent)}
        css="justify-self: start; "
      >
        {link.text}
      </LinkButton>
    </div>
  </Teaser>
);

export default MainTeaser;
