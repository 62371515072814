import React from 'react';
import { withRouter } from 'react-router-dom';

import { ArrowButton } from 'atoms';

const BackButton = ({ history, to, ...args }) => (
  <ArrowButton direction="back" onClick={() => to && history.push(to)} {...args} />
);

export default withRouter(BackButton);
