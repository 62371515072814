import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  CircularProgress as MuiCircularProgress,
  Grid,
} from '@material-ui/core';
import { useFetchClassActivities } from 'utils';
import { Activity } from 'molecules';

import { DESKTOP } from 'breakpoints';
import { useTranslation } from 'react-i18next';
import uuid4 from 'uuid';

const Card = styled(MuiCard)`
  ${props =>
    props.hideMobile &&
    `
    @media (max-width: ${DESKTOP}) {
      display: none;
    }
  `}
`;

const ParagraphFaded = styled(Typography)`
  background-color &.MuiTypography-root {
    color: var(--color-faded);
  }
`;

const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    margin-left: auto;
    margin-right: auto;
  }
`;

const ClassActivities = () => {
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [
    {
      isPending,
      activities: { entities, ids },
    },
    fetchActivities,
  ] = useFetchClassActivities();
  React.useEffect(() => {
    fetchActivities(id);
  }, [id]);
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={t('deadlines_heading')}
        titleTypographyProps={{ variant: 'h6', gutterBottom: false }}
      />
      <CardContent>
        <Grid container direction="column" justify="center" spacing={2} css="width: 100%;">
          {isPending && <CircularProgress />}
          {!isPending &&
            ids &&
            ids.map(activityId => (
              <Grid item xs={12}>
                <Activity activity={entities[activityId]} key={uuid4()} />
              </Grid>
            ))}
          {!isPending && ids.length === 0 && (
            <ParagraphFaded>{t('deadlines_placeholder')}</ParagraphFaded>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClassActivities;
