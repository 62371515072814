import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown as Chevron } from '@fortawesome/free-solid-svg-icons';

const Header = styled.div`
  display: grid;
  grid-auto-flow: column dense;
  grid-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`;

const DropdownHeader = ({ headerClick, children, ...args }) => (
  <Header
    onClick={() => {
      headerClick();
    }}
    {...args}
  >
    {children}
    <FAIcon
      css={`
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.35));
        height: 1.34rem;
        width: 1.34rem;
        padding: 0 0 0 0.2rem;
      `}
      icon={Chevron}
    />
  </Header>
);

export default DropdownHeader;
