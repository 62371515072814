import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  margin: 0;
`;

// eslint-disable-next-line max-len
const passPropsToChildren = (props, children) =>
  React.Children.map(children, child => React.cloneElement(child, props));

const Dropdown = ({ children, ...args }) => {
  const node = useRef();

  const [visible, toggleVisibility] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    toggleVisibility(false);
  };

  const onHeaderClick = () => {
    toggleVisibility(!visible);
  };

  const handleBlur = e => {
    if (e.relatedTarget && !node.current.contains(e.relatedTarget)) {
      toggleVisibility(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const mappedChildren = passPropsToChildren(
    {
      visible,
      toggleVisibility: () => toggleVisibility(!visible),
      headerClick: () => onHeaderClick(),
      handleClick: e => handleClick(e),
      turnInvisible: () => toggleVisibility(false),
    },
    children,
  );

  return (
    <Wrapper ref={node} onClick={e => handleClick(e)} onBlur={e => handleBlur(e)} {...args}>
      {mappedChildren}
    </Wrapper>
  );
};

export default Dropdown;
