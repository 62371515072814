import React from 'react';
import styled from 'styled-components';
import { Heading, Paragraph } from 'Typography';
import { DESKTOP } from 'breakpoints';

const StatContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.invert ? 'column-reverse' : 'column')};
  align-items: ${props => (props.invert ? 'center' : 'initial')};

  ${Paragraph} {
    width: 80%;

    @media (min-width: ${DESKTOP}) {
      width: initial;
    }
  }
`;

const Stat = ({ value, caption, invert }) => (
  <StatContainer invert={invert}>
    <Heading
      type="h1"
      color="primary"
      css={`
        margin: 0;
      `}
    >
      {value}
    </Heading>
    <Paragraph>{caption}</Paragraph>
  </StatContainer>
);

export default Stat;
