/* eslint-disable no-unused-expressions */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'atoms';

const LinkButton = ({ history, to, onClick, ...rest }) => (
  <Button
    {...rest}
    onClick={event => {
      onClick && onClick(event);
      to && history.push(to);
    }}
  />
);

LinkButton.Open = withRouter(({ history, to, onClick, ...rest }) => (
  <Button.Open
    {...rest}
    onClick={event => {
      onClick && onClick(event);
      to && history.push(to);
    }}
  />
));

export default withRouter(LinkButton);
