import React from 'react';
import { useTranslation } from 'react-i18next';
import Template from './Template';

const Finder = () => {
  const { t } = useTranslation();
  return (
    <Template
      t={t}
      header={t('ads_finder_header')}
      subheader={t('ads_finder_subheader')}
      primaryTeaser={{
        header: null,
        text: t('ads_finder_text'),
        link: {
          text: t('ads_finder_button_text'),
          href: 'https://berufs-finder.yousty.ch/de-CH/index.html',
          gaEvent: 'Berufs-Finder',
        },
      }}
    />
  );
};

export default Finder;
