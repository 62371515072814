const today = new Date();
export const year = today.getFullYear();
const isNextSeason = new Date(`${year}-11-01`) <= today;
export const years = isNextSeason
  ? [year + 1, year + 2, year + 3, year + 4]
  : [year, year + 1, year + 2, year + 3];

export const districtNames = [
  'appenzell-au.',
  'aargau',
  'appenzell-in.',
  'basel-stadt',
  'baselland',
  'bern',
  'freiburg',
  'fürstentum liechtenstein',
  'genf',
  'glarus',
  'graubünden',
  'jura',
  'luzern',
  'neuenburg',
  'nidwalden',
  'obwalden',
  'schaffhausen',
  'schwyz',
  'solothurn',
  'st. gallen',
  'tessin',
  'thurgau',
  'uri',
  'waadt',
  'wallis',
  'zug',
  'zürich',
];

export const districtShortcuts = [
  'AR',
  'AG',
  'AI',
  'BS',
  'BL',
  'BE',
  'FR',
  'LI',
  'GE',
  'GL',
  'GR',
  'JU',
  'LU',
  'NE',
  'NW',
  'OW',
  'SH',
  'SZ',
  'SO',
  'SG',
  'TI',
  'TG',
  'UR',
  'VD',
  'VS',
  'ZG',
  'ZH',
];

export const schoolTypes = [
  'Anderes',
  'Gemischt',
  'Bezirksschule',
  "Cycle D'orientation I",
  "Cycle D'orientation Ii",
  'Gymnasium',
  'Oberschule',
  'Orientierungsschule A',
  'Orientierungsschule B',
  'Progymnasialklasse',
  'Progymnasium',
  'Realklasse',
  'Realschule',
  'Realstufe',
  'Section Générale',
  'Section Moderne',
  'Section Préparant Aux Écoles De Maturité',
  'Sek B',
  'Sek E',
  'Sek G',
  'Sek P',
  'Sekstufe',
  'Sekundarklasse',
  'Sekundarschule',
  'Sekundarschule A',
  'Sekundarschule B',
  'Sekundarschule C',
  'Sekundarschule E',
  'Sekundarschule P',
  'Untergymnasium',
];

export const districts = {
  AR: {
    name: 'Appenzell-au.',
    shortcut: 'AR',
    schools: ['Anderes', 'Gemischt', 'Sekundarschule'],
  },
  AG: {
    name: 'Aargau',
    shortcut: 'AG',
    schools: ['Anderes', 'Gemischt', 'Bezirksschule', 'Realschule', 'Sekundarschule'],
  },
  AI: {
    name: 'Appenzell-in.',
    shortcut: 'AI',
    schools: ['Anderes', 'Gemischt', 'Gymnasium', 'Realschule', 'Sekundarschule'],
  },
  BS: {
    name: 'Basel-stadt',
    shortcut: 'BS',
    schools: ['Anderes', 'Gemischt', 'Sekundarschule A', 'Sekundarschule E', 'Sekundarschule P'],
  },
  BL: {
    name: 'Baselland',
    shortcut: 'BL',
    schools: ['Anderes', 'Gemischt', 'Sekundarschule A', 'Sekundarschule E', 'Sekundarschule P'],
  },
  BE: {
    name: 'Bern',
    shortcut: 'BE',
    schools: [
      'Anderes',
      'Gemischt',
      'Realschule',
      'Section Générale',
      'Section Moderne',
      'Section Préparant Aux Écoles De Maturité',
      'Sekundarschule',
    ],
  },
  FR: {
    name: 'Freiburg',
    shortcut: 'FR',
    schools: ['Anderes', 'Gemischt', 'Progymnasialklasse', 'Realklasse', 'Sekundarklasse'],
  },
  LI: {
    name: 'Fürstentum Liechtenstein',
    shortcut: 'LI',
    schools: ['Anderes', 'Gemischt'],
  },
  GE: {
    name: 'Genf',
    shortcut: 'GE',
    schools: ['Anderes', 'Gemischt', 'Realstufe', 'Sekstufe'],
  },
  GL: {
    name: 'Glarus',
    shortcut: 'GL',
    schools: ['Anderes', 'Gemischt', 'Gymnasium', 'Oberschule', 'Realschule', 'Sekundarschule'],
  },
  GR: {
    name: 'Graubünden',
    shortcut: 'GR',
    schools: ['Anderes', 'Gemischt', 'Realschule', 'Sekundarschule', 'Untergymnasium'],
  },
  JU: {
    name: 'Jura',
    shortcut: 'JU',
    schools: ['Anderes', 'Gemischt', 'Sekundarschule A', 'Sekundarschule B', 'Sekundarschule C'],
  },
  LU: {
    name: 'Luzern',
    shortcut: 'LU',
    schools: [
      'Anderes',
      'Gemischt',
      'Sekundarschule A',
      'Sekundarschule B',
      'Sekundarschule C',
      'Untergymnasium',
    ],
  },
  NE: {
    name: 'Neuenburg',
    shortcut: 'NE',
    schools: ['Anderes', 'Gemischt', 'Realstufe', 'Sekstufe'],
  },
  NW: {
    name: 'Nidwalden',
    shortcut: 'NW',
    schools: [
      'Anderes',
      'Gemischt',
      'Orientierungsschule A',
      'Orientierungsschule B',
      'Progymnasium',
    ],
  },
  OW: {
    name: 'Obwalden',
    shortcut: 'OW',
    schools: [
      'Anderes',
      'Gemischt',
      'Orientierungsschule A',
      'Orientierungsschule B',
      'Progymnasium',
    ],
  },
  SH: {
    name: 'Schaffhausen',
    shortcut: 'SH',
    schools: ['Anderes', 'Gemischt', 'Realschule', 'Sekundarschule'],
  },
  SZ: {
    name: 'Schwyz',
    shortcut: 'SZ',
    schools: ['Anderes', 'Gemischt', 'Realschule', 'Sekundarschule'],
  },
  SO: {
    name: 'Solothurn',
    shortcut: 'SO',
    schools: ['Anderes', 'Gemischt', 'Sek B', 'Sek E', 'Sek P'],
  },
  SG: {
    name: 'St. Gallen',
    shortcut: 'SG',
    schools: ['Anderes', 'Gemischt', 'Realschule', 'Sekundarschule'],
  },
  TI: {
    name: 'Tessin',
    shortcut: 'TI',
    schools: ['Anderes', 'Gemischt', 'Realstufe', 'Sekstufe'],
  },
  TG: {
    name: 'Thurgau',
    shortcut: 'TG',
    schools: ['Anderes', 'Gemischt', 'Sek E', 'Sek G'],
  },
  UR: {
    name: 'Uri',
    shortcut: 'UR',
    schools: ['Anderes', 'Gemischt', 'Realschule', 'Untergymnasium'],
  },
  VD: {
    name: 'Waadt',
    shortcut: 'VD',
    schools: ['Anderes', 'Gemischt', 'Progymnasium', 'Sekstufe'],
  },
  VS: {
    name: 'Wallis',
    shortcut: 'VS',
    schools: ['Anderes', 'Gemischt', "Cycle D'orientation I", "Cycle D'orientation Ii"],
  },
  ZG: {
    name: 'Zug',
    shortcut: 'ZG',
    schools: ['Anderes', 'Gemischt', 'Realschule', 'Sekundarschule'],
  },
  ZH: {
    name: 'Zürich',
    shortcut: 'ZH',
    schools: [
      'Anderes',
      'Gemischt',
      'Sekundarschule A',
      'Sekundarschule B',
      'Sekundarschule C',
      'Untergymnasium',
    ],
  },
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeSentence = sentence => {
  const splitSentence = sentence.split(' ');
  const newSentence = [];

  splitSentence.forEach(word => {
    newSentence.push(capitalize(word));
  });

  return newSentence.join(' ');
};
