import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'Typography';
import { StatefulButton } from 'molecules';

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.117rem 0;
`;

const Action = ({ onClick, state, text, tooltip, disabled, ...args }) => {
  return (
    <ActionRow {...args}>
      <Paragraph>{text}</Paragraph>
      <StatefulButton
        disabled={disabled}
        state={state}
        tooltip={tooltip}
        onClick={() => onClick()}
      />
    </ActionRow>
  );
};

export default Action;
