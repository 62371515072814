import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUserData } from 'selectors';
import { userLogout } from 'actions';
import * as bp from 'breakpoints';
import { withRouter } from 'react-router-dom';
import { UserMenu } from 'templates';
import { LinkButton } from 'molecules';

const UserFieldContainer = styled.div`
  display: grid;
  grid-auto-flow: column dense;
  align-items: center;
  padding: 1.134rem 1.67rem;
  height: 2.67rem;
  z-index: 2;
  user-select: none;

  @media (min-width: ${bp.PAD_S}) {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const UserField = ({ user, onLogout }) => {
  const { t } = useTranslation();

  return (
    <UserFieldContainer>
      {user.signedIn ? (
        <UserMenu user={user} onLogout={onLogout} />
      ) : (
        <LinkButton primary to="/auth/login">
          {t('login')}
        </LinkButton>
      )}
    </UserFieldContainer>
  );
};

const mapStateToProps = state => {
  const user = getUserData(state);
  return {
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(userLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserField));
