import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Paragraph } from 'Typography';
import { useTranslation } from 'react-i18next';
import { Button } from 'atoms';
import { Link, withRouter } from 'react-router-dom';

const Container = styled.main`
  display: flex;
  flex-direction: column;
  height: 85%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ErrorHeading = styled.h1`
  height: 75%;
  color: #6a205f;
  font-size: 15rem;
  margin: 0;
`;

const ErrorSubHeading = styled.h4`
  color: #6a205f;
  margin: 0;
  font-size: 2.5rem;
  text-align: center;
`;

const FadedParagraph = styled(Paragraph)`
  color: var(--font-color-faded);
`;

const ErrorPage = ({ history }) => {
  useEffect(() => {
    setTimeout(() => {
      history.push('/');
    }, 3000);
  });

  const { t } = useTranslation();
  return (
    <div css="width: 100%; height: 100vh; background-color: #EFEFEF; position: absolute; z-index: 500;">
      <Container>
        <div css="display: flex; flex-direction: column;">
          <ErrorHeading>404</ErrorHeading>
          <ErrorSubHeading>{t('not_found_text')}</ErrorSubHeading>
        </div>
        <FadedParagraph>{t('error_404')}</FadedParagraph>
        <Button primary css="margin-top: 10vh;">
          <Link to="/">Zur Startseite</Link>
        </Button>
      </Container>
    </div>
  );
};

export default withRouter(ErrorPage);
