import React from 'react';
import { useDispatch } from 'react-redux';
import { removeStudent } from 'actions';
import * as api from 'api';

const useRemoveStudent = () => {
  const dispatch = useDispatch();
  const [studentId, setStudentId] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  React.useEffect(() => {
    const fetchRemoveStudent = async () => {
      setIsSuccess(false);
      setIsPending(true);
      setIsError(false);
      try {
        const token = api.getToken();
        await api.userApi.delete(`/classroom_members/${studentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(removeStudent(studentId));
        setIsSuccess(true);
      } catch (err) {
        setIsError(true);
      }
      setStudentId(null);
      setIsPending(false);
    };
    if (studentId) {
      fetchRemoveStudent();
    }
  }, [studentId]);

  return [{ isError, isPending, isSuccess }, setStudentId];
};
export default useRemoveStudent;
