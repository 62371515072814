import React from 'react';
import { ReactComponent as PDFIcon } from './assets/PDFIcon.svg';

const PdfButton = ({ ...args }) => (
  <PDFIcon
    css={`
      height: 2rem;
      width: 2rem;
    `}
    {...args}
  />
);

export default PdfButton;
