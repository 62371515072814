import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useFetch from 'use-http';
import { getUri, headers, getToken } from 'api';

const useFetchPhotosLink = () => {
  const [url, setUrl] = React.useState('');
  const {
    params: { id },
  } = useRouteMatch();

  const { post, get, loading, data } = useFetch(getUri(), {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    },
    retries: 1500,
    retryOn: async ({ response }) => {
      try {
        const resp = await response.json();
        const { url: reqUrl, check } = resp.data;
        if (reqUrl !== null || check) return false;
        return true;
      } catch (err) {
        return false;
      }
    },
    retryDelay: 3000,
  });

  const doFetch = eventId => {
    post(`/classroom_photos/${id}/zip`, eventId ? { data: { eventId } } : {});
  };

  React.useEffect(() => {
    if (data && data.data && data.data.url) {
      setUrl(data.data.url);
    }
    if (data && data.data && data.data.check) {
      const trimmedEndpoint = data.data.check.split('/v2')[1];
      get(trimmedEndpoint);
    }
  }, [data]);
  return [{ loading, url }, doFetch];
};

export default useFetchPhotosLink;
