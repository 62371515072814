import styled from 'styled-components';
import * as bp from 'breakpoints';

const Checkbox = styled.label`
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.8rem;
  cursor: default;
  height: 1rem;

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }

  span {
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: white;
    border: 1px solid black;

    &:hover {
      background-color: var(--color-primary);
    }
  }

  p {
    margin-left: 2rem;

    @media (min-width: ${bp.PAD_S}) {
      margin-left: 1.5rem;
    }
  }

  input:checked + span {
    background-color: var(--color-primary);
  }
`;

export default Checkbox;
