import React from 'react';
import { useTranslation } from 'react-i18next';
import Template from './Template';

const Events = () => {
  const { t } = useTranslation();
  return (
    <Template
      t={t}
      header={t('ads_events_header')}
      subheader={t('ads_events_subheader')}
      primaryTeaser={{
        text: t('ads_events_text'),
        link: {
          text: t('ads_events_button_text'),
          href: 'https://www.yousty.ch/berufswahl-anlaesse',
          gaEvent: 'Clicks - P 3.1 - Berufswahl-Anlässe',
        },
      }}
    />
  );
};

export default Events;
