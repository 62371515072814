import styled from 'styled-components';
import { DEFAULT } from 'breakpoints';

const Input = styled.input.attrs({ autoComplete: 'new-password' })`
  line-height: 1.34rem;
  font-size: 1rem;
  border: none;
  padding-bottom: 0.34rem;
  border-style: solid;
  border-width: ${props => (props.border ? '0 0 0.3px 0' : '0')};
  border-color: var(--font-color-faded, grey);
  font-family: var(--font-primary);

  @media (min-width: ${DEFAULT}) {
    border-width: 0 0 0.3px 0;
  }

  :focus {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--font-color-faded, grey);
    font-weight: bold;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--font-color-faded, grey);
    font-weight: bold;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--font-color-faded, grey);
    font-weight: bold;
  }
  ${props => {
    switch (props.error.type) {
      case 'warning':
        return 'border-bottom: 0.3px solid var(--color-orange, #e6a54b);';
      case 'error':
        return 'border-bottom: 0.3px solid var(--font-color-error,#D03D59);';
      default:
        return 'border-color: var(--font-color-faded, #333333);';
    }
  }}
`;

export default Input;
