/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import { Box, Button, CircularProgress, Typography, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useConfirmRegistration from 'utils/useConfirmRegistration';

const ConfirmRegistration = () => {
  const [{ isPending, isSuccess }, sendReminder] = useConfirmRegistration();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const userId = useSelector(state => state.userState.user.id);
  const isRouteNotFound = useSelector(state => state.navState.notFound);

  const isPathWrong =
    pathname === '/auth/login' ||
    pathname === '/auth/register' ||
    pathname === '/auth/add_classroom';

  if (isPathWrong || isRouteNotFound) {
    return <> </>;
  }

  const handleReminderClick = () => {
    sendReminder(userId);
  };

  return (
    <Box position="fixed" zIndex={20} bgcolor="warning.main" width="100%">
      <Container maxWidth="lg">
        <Box display="flex" height={50} justifyContent="space-between" alignItems="center">
          <Typography>{t('email_confirm_explain')}</Typography>
          <Button
            disabled={isPending || isSuccess}
            onClick={handleReminderClick}
            endIcon={
              isSuccess ? <CheckIcon /> : isPending ? <CircularProgress size={20} /> : <SendIcon />
            }
          >
            {t('email_confirm_question')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ConfirmRegistration;
