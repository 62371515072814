import { put, takeLatest } from 'redux-saga/effects';
import { roadmapActions, errorTypes } from '../rootTypes';
import * as api from '../api';

function* fetchContent() {
  try {
    yield put({ type: roadmapActions.fetchContentPending });
    const teaserData = yield api.userApi.get('/teachers/teasers', {
      headers: {
        Authorization: `Bearer ${api.getToken()}`,
      },
    });
    yield put({
      type: roadmapActions.fetchContentSuccess,
      payload: teaserData.data.data,
    });
  } catch (err) {
    yield put({
      type: errorTypes.api,
      payload: err.response,
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* roadmapSaga() {
  yield takeLatest(roadmapActions.fetchContentRequest, fetchContent);
}
