import styled from 'styled-components';
import * as bp from 'breakpoints';

const Link = styled.a`
  ${props =>
    props.hideMobile &&
    `
    @media (max-width: ${bp.PAD_S}) {
      display: none;
    }
    `};
  font-family: var(--font-primary);
  font-size: 1rem;
  color: ${props => (props.color ? `${props.color}` : 'var(--font-color-primary)')};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props =>
    props.primary &&
    `
    color: var(--font-color-yousty);
    font-weight: bold;
  `};
  ${props =>
    props.tertiary &&
    `
    color: var(--font-color-primary);
  `};
  ${props =>
    props.secondary &&
    `
    color: var(--font-color-secondary);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    padding: 0 1rem;
  `}
  ${props =>
    props.lineHeight &&
    `
    line-height: ${props.lineHeight};
  `}
  ${props =>
    props.withHover &&
    `
    line-height: 1.2em;
    border-radius: 0.34rem;
    :hover {
      background-color: var(--color-tertiary, grey);
  `}
`;

export default Link;
