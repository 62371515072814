/* eslint-disable camelcase */
import { put, takeLatest } from 'redux-saga/effects';
import tracker from 'tracker';
import { userActions, login } from '../rootTypes';
import * as api from '../api';

export function* fetchUser() {
  try {
    const token = api.getToken();
    const data = token.split('.')[1];
    const user = JSON.parse(api.Base64ToString(data));
    if (api.isAuthorized(user)) {
      const { user_uuid } = user.data;
      const userRequest = yield api.userApi.get(`/teachers/${user_uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      tracker.trackEvent('Clicks - Successful Logins');
      yield put({
        type: userActions.fetchSuccess,
        payload: userRequest.data.data,
      });
    } else {
      throw new Error('User unauthorized');
    }
  } catch (err) {
    yield put({ type: userActions.logout });
    yield put({
      type: login.failure,
      payload: { errors: [{ detail: 'user_invalid_scope' }] },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* userSaga() {
  yield takeLatest(userActions.fetchRequest, fetchUser);
}
