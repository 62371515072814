import React from 'react';
import styled from 'styled-components';

import { Badge, Paper } from 'atoms';
import { Heading } from 'Typography';
import * as bp from 'breakpoints';

const GridHeader = styled(Paper)`
  border-radius: 0;
  box-shadow: none;
  padding: 1rem 0;
  border-bottom: 1px solid #95989a;
  h5 {
    grid-column: span 2;
    padding: 0 1rem;
  }
`;

const YearColumn = styled.div`
  border-right: 1px solid var(--color-tertiary);
  grid-row: 2 / -1;
  &:last-child {
    border-right: none;
  }
  padding: 0.5rem;
  @media (min-width: ${bp.DESKTOP}) {
    padding: 0.5rem 1rem;
  }
`;

const TimetableGrid = ({ ...args }) => (
  <Paper {...args} grid gridRows="repeat(6, 1fr)" gridColumns="repeat(6, 1fr)">
    <GridHeader grid gridColumns="repeat(6, 1fr)" gridColumn="1 / -1">
      <Heading type="h5">7. Klasse</Heading>
      <Heading type="h5">8. Klasse</Heading>
      <Heading type="h5">9. Klasse</Heading>
    </GridHeader>
    <YearColumn>
      <Badge> 1. Semester </Badge>
    </YearColumn>
    <YearColumn>
      <Badge> 2. Semester </Badge>
    </YearColumn>
    <YearColumn>
      <Badge> 1. Semester </Badge>
    </YearColumn>
    <YearColumn>
      <Badge> 2. Semester </Badge>
    </YearColumn>
    <YearColumn>
      <Badge> 1. Semester </Badge>
    </YearColumn>
    <YearColumn>
      <Badge> 2. Semester </Badge>
    </YearColumn>
  </Paper>
);

export default TimetableGrid;
