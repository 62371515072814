import styled from 'styled-components';

import * as bp from 'breakpoints';

const HeroImage = styled.div`
  display: none;
  @media (min-width: ${bp.PAD_S}) {
    display: block;
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 35%;
    position: absolute;
    top: 0;
    left: 0;
    height: ${props => (props.height ? `${props.height}` : '40%')};
    width: 100%;
    z-index: -1;
    &::before {
      background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0.18) 77%);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
    }
  }
  @media (min-width: 1024px) {
    background-image: url(${props => props.imageBig});
  }
`;

export default HeroImage;
