import React from 'react';
import styled from 'styled-components';

import * as bp from 'breakpoints';
import { Label, ErrorIcon } from 'atoms';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  position:relative;
  transition: all 150ms ease-in;
  > * {
    transition: all 150ms ease-in;
  }
  ${props => props.area && `grid-area: ${props.area};`};
  ${props =>
    props.hideMobile &&
    `
  @media (max-width: ${bp.PAD_S}) {
      display: none;
    }
  `}
  ${props =>
    props.elevation && `box-shadow: var(--elevation-${props.elevation}, var(--elevation-default));`}
  ${props => props.grid && 'display:grid;'}
  ${props => props.gridColumns && `grid-template-columns: ${props.gridColumns};`}
  ${props => props.gridRows && `grid-template-rows: ${props.gridRows};`}
  ${props => props.gridColumn && `grid-column: ${props.gridColumn};`}
  ${props => props.gridRow && `grid-row: ${props.gridRow};`}
  ${props => props.gridGap && `grid-gap: ${props.gridGap};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props =>
    props.fullWidth &&
    `input & {
    width: 100%;
  }`}
  ${ErrorIcon} {
    position:absolute;
    bottom: 1rem;
    right: 1.134rem;
    padding-bottom: 0.34rem;
    height: 1.6rem;
    cursor: pointer;
    ${props => {
      switch (props.errortype) {
        case 'warning':
          return 'color: var(--color-orange, #e6a54b);';
        case 'error':
          return 'color: var(--font-color-error,#D03D59);';
        default:
          return 'display:none';
      }
    }}
  }
`;

const ErrorTag = styled.div`
  display: block;
  color: var(--font-color-primary, #333333);
  font-size: 0.67rem;
  ${props => {
    switch (props.error.type) {
      case 'error':
        return `
          opacity: 1;
          color: var(--font-color-error, #D03D59);
        `;
      case 'warning':
        return `
          opacity: 1;
          color: var(--color-orange, #e6a54b);
        `;
      default:
        return `
        opacity: 0;
        color: var(--font-color-primary, #333333);
        `;
    }
  }}
  height: 1rem;
`;

const ErrorDisplay = ({ error, ...args }) => {
  const { t } = useTranslation();
  const errorText = error.errors && error.errors[0] ? error.errors[0].detail : '';
  return (
    <ErrorTag error={error} {...args}>
      {t(errorText)}
    </ErrorTag>
  );
};

const childrenWithProps = (children, props) =>
  React.Children.map(children, child => React.cloneElement(child, props));

const InputField = ({
  label,
  id,
  required,
  error = { type: 'none', errors: [] },
  children,
  ...args
}) => (
  <Wrapper errortype={error.type} {...args}>
    <Label error={error.type} htmlFor={id} {...args}>
      {`${label} ${required && '*'}`}
    </Label>
    {childrenWithProps(children, { error })}
    <ErrorIcon errortype={error.type} />
    <ErrorDisplay error={error} />
  </Wrapper>
);

export default InputField;
