import styled from 'styled-components';

const Label = styled.label`
  font-size: 0.67rem;
  ${props => props.expand && 'font-size: 1rem;'}
  ${props => {
    switch (props.error) {
      case 'warning':
        return 'color: var(--color-orange, #e6a54b);';
      case 'error':
        return 'color: var(--font-color-error,#D03D59);';
      default:
        return 'color: var(--font-color-primary, #333333);';
    }
  }};
`;

export default Label;
