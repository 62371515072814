import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import tracker from 'tracker';
import { useTranslation } from 'react-i18next';
import { Heading } from 'Typography';
import { Action } from 'organisms';

import { getStudentState } from 'selectors';

import * as api from '../../../redux/api';

const StyledCard = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.4rem;
  div {
    color: var(--color-darker, #616161);
  }
`;

const StudentActions = ({ student }) => {
  const { t } = useTranslation();
  const { data } = student;

  const [sending, setSending] = useState({
    job_check: false,
    job_finder: false,
  });
  const [sent, setSent] = useState({
    job_check: false,
    job_finder: false,
  });
  const [error, setError] = useState({
    job_check: false,
    job_finder: false,
  });

  // eslint-disable-next-line consistent-return
  const invite = ({ id, to }) => {
    const formattedTo = to
      .split('_')
      .map(el => el[0].toUpperCase() + el.slice(1))
      .join('');

    if (sending[to] || sent[to]) return null;
    tracker.trackEvent('Invitation', `${formattedTo}`);
    const token = api.getToken();
    setSending({ ...sending, [to]: true });
    setError({ ...error, [to]: false });
    api.userApi
      .post(`classroom_members/${id}/invite_to_${to}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setSent({ ...sent, [to]: true });
        setError({ ...error, [to]: false });
      })
      .catch(() => {
        setSent({ ...sent, [to]: false });
        setError({ ...error, [to]: true });
      })
      .finally(() => {
        setSending({ ...sending, [to]: false });
      });
  };

  if (!data) {
    return null;
  }

  const { jobCheckCertificateUrl, email, memberId } = data;

  const handleEmailClick = () => {
    const mailtoUrl = `mailto:${email}`;
    window.location.href = mailtoUrl;
  };

  return (
    <StyledCard>
      <Heading type="h5" css="margin-bottom: 0.8rem;">
        {t('actions')}
      </Heading>
      {!jobCheckCertificateUrl && (
        <Action
          text={t('invite_to_job_check')}
          onClick={() => invite({ id: memberId, to: 'job_check' })}
          state={{
            sending: sending.job_check,
            sent: sent.job_check,
            isError: error.job_check,
          }}
        />
      )}
      <Action
        text={t('invite_to_jobfinder')}
        onClick={() => invite({ id: memberId, to: 'job_finder' })}
        state={{
          sending: sending.job_finder,
          sent: sent.job_finder,
          isError: error.job_finder,
        }}
      />
      <Action
        text={t('send_a_reminder')}
        onClick={handleEmailClick}
        state={{ sending: false, sent: false, isError: false }}
      />
    </StyledCard>
  );
};

const mapStateToProps = state => ({
  student: getStudentState(state),
});

export default connect(mapStateToProps)(StudentActions);
