import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import Media from 'react-media';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Paper } from 'atoms';
import { LinkButton } from 'molecules';

import { Heading, Paragraph } from 'Typography';
import { DEFAULT } from 'breakpoints';

const TeaserLeaflet = styled(Paper)`
  display: grid;
  grid-template-areas:
    'header button'
    'text button';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  align-items: center;
  padding: 0 0 0 1rem;
  grid-column: span 6;
  grid-gap: 1.334rem 0.67rem;
  @media (min-width: ${DEFAULT}) {
    grid-template-areas:
      'header button'
      'text text';
    grid-column: span 3;
    padding: 1.34rem;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    @media (min-width: ${DEFAULT}) {
      align-self: center;
      margin: auto 0;
      min-width: 3rem;
      min-height: 3rem;
    }
  }
`;

const TeaserParagraph = styled(Paragraph)`
  padding: 0 0 1.34rem 0;
  @media (min-width: ${DEFAULT}) {
    padding: 0;
  }
`;

const TeaserHeader = styled(Heading).attrs({ type: 'h3' })`
  margin: 1.34rem 0 0 0;
  @media (min-width: ${DEFAULT}) {
    margin: 0;
  }
`;

const ContentTeaser = ({ headerText, text, linkTo, ...args }) => (
  <TeaserLeaflet primary elevation="default" {...args}>
    <TeaserHeader color="yousty" area="header">
      {headerText}
    </TeaserHeader>
    <LinkButton.Open as="a" area="button" primary href={linkTo} target="_blank">
      <FAIcon icon={faArrowRight} />
    </LinkButton.Open>
    <TeaserParagraph area="text">{text}</TeaserParagraph>
  </TeaserLeaflet>
);

ContentTeaser.Loading = ({ ...args }) => (
  <TeaserLeaflet
    primary
    elevation="default"
    {...args}
    css={`
      min-height: 5rem;
      display: flex;
      svg {
        width: 100%;
        height: 100%;
      }
    `}
  >
    <Media query={`(max-width: ${DEFAULT})`}>
      <ContentLoader
        css={`
          height: 8rem;
          width: 100%;
        `}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="36" rx="4" ry="4" width="236" height="12" />
        <rect x="0" y="60" rx="3" ry="3" width="270" height="6" />
        <rect x="0" y="74" rx="3" ry="3" width="280" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="154" height="6" />
        <rect x="358" y="-10" rx="0" ry="0" width="43" height="360" />
      </ContentLoader>
    </Media>
    <Media query={`(min-width: ${DEFAULT})`}>
      <ContentLoader height={95} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="0" y="14" rx="4" ry="4" width="236" height="12" />
        <rect x="0" y="54" rx="3" ry="3" width="350" height="6" />
        <rect x="0" y="66" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="78" rx="3" ry="3" width="201" height="5" />
        <rect x="350" y="0" rx="4" ry="4" width="45" height="45" />
      </ContentLoader>
    </Media>
  </TeaserLeaflet>
);

export default ContentTeaser;
