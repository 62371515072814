/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ErrorPage from './ErrorPage/ErrorPage';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: false };
  }

  redirectUserOnError = () => {
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        localStorage.removeItem('youstycareerchoiceradarstate');
        localStorage.removeItem('yousty_api_token');
        window.location.replace('/klassenübersicht');
      }, 2000);
    }
  };

  componentDidCatch() {
    // this.redirectUserOnError();
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
