import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const IconWrapper = styled(FAIcon).attrs({ icon: faTimes })`
  &&& {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: var(--color-primary);
  }
`;

const CloseIcon = ({ ...args }) => <IconWrapper {...args} />;

export default CloseIcon;
