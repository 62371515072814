import styled from 'styled-components';

const Badge = styled.span`
  background-color: var(--color-lighter);
  border-radius: 0.34rem;
  font-size: 0.67rem;
  line-height: 1.4em;
  padding: 0.2rem 0.4rem;
`;

export default Badge;
