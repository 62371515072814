import React, { useEffect } from 'react';
import styled from 'styled-components';
import tracker from 'tracker';
import { Card } from 'molecules';
import { Heading } from 'Typography';
import { useTranslation } from 'react-i18next';
import Gallery from './PhotoGallery';
import FetchAllPhotosLink from './FetchAllPhotosLink';
import EventLinks from './EventLinks';

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1.1rem;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoGrid = styled(Card).attrs({ primary: true })`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1.35rem;
`;

const Photos = () => {
  const { t } = useTranslation();

  useEffect(() => {
    tracker.trackPageView('/klasse/fotos', 'Visits - P5.3 - Bewerbungsfotos');
  }, []);
  return (
    <Container>
      <EventLinks />
      <InfoGrid>
        <InfoContainer>
          <Heading type="h5">{t('photos_heading_2')}</Heading>
          <FetchAllPhotosLink />
        </InfoContainer>
        <Gallery />
      </InfoGrid>
    </Container>
  );
};

export default Photos;
